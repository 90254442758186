const pickup = {
  strict: true,
  namespaced: true,
  state: {
    address: null,
  },
  getters: {
    getPickup (state) {
      return state.address
    },
  },
  mutations: {
    setPickup (state, payload) {
      state.address = payload
    },
  },
  actions: {
  },
}

export default pickup
