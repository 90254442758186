import api from '@/services/Api'

export default {
    list (clientId) { // Liste les cartes du client
        return api().get(`/payfacto/card/list/${clientId}`)
    },
    save (card, clientId) { // Création d'une nouvelle entrée
        return api().post(`/payfacto/card/create/${clientId}`, card)
    },
    view (cardId, clientId) { // Voir une entrée spécifique
        return api().get(`/payfacto/card/${cardId}/${clientId}`)
    },
    update (profileCard, cardId, clientId) { // Mise à jour d'une entrée spécifique
        return api().put(`/payfacto/card/${cardId}/${clientId}`, profileCard)
    },
    remove (cardId, clientId) { // Suppression d'une entrée spécifique
        return api().delete(`/payfacto/card/${cardId}/${clientId}`)
    },
    getForm () {
        return api().get('/payfacto/getForm')
    },
}
