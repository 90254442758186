import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/toggles')
    },
    save (toggle) { // Création d'une nouvelle entrée
        return api().post('/toggles', toggle)
    },
    view (toggleId) { // Voir une entrée spécifique
        return api().get(`/toggles/${toggleId}`)
    },
    update (toggle, toggleId) { // Mise à jour d'une entrée spécifique
        return api().put(`/toggles/${toggleId}`, toggle)
    },
    remove (toggleId) { // Suppression d'une entrée spécifique
        return api().delete(`/toggles/${toggleId}`)
    },
}
