<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <validation-observer
          ref="obs"
          v-slot="{ handleSubmit }"
        >
          <form>
            <v-row
              dense
            >
              <v-col
                cols="1"
              />
              <v-col
                cols="10"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('Refund')"
                  :rules="{ required: true, regex: regexMontantPositif, max_value: maxefund, min_value: 1 }"
                >
                  <v-text-field
                    v-model="submitForm.amount"
                    :label="$t('Refund')"
                    prepend-icon="mdi-currency-usd"
                    type="number"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <!-- On affiche un message si le paiement à déjà eu des remboursements-->
                <template v-if="submitForm.montantTotal !== submitForm.remaining">
                  <!-- Remboursement partiel -->
                  <span
                    v-if="submitForm.remaining > 0"
                    class="ml-9"
                  >{{ $t('Payment.Partially_refunded') }} (${{ submitForm.remaining }} {{ $t('Remaining').toLowerCase() }} {{ $t('Over_sur').toLowerCase() }} ${{ submitForm.montantTotal }}).</span>

                  <!-- Remboursement totale -->
                  <span
                    v-if="submitForm.remaining === 0"
                    class="ml-9"
                  >{{ $t('Payment.Total_refunded') }} (${{ submitForm.montantTotal }})</span>
                </template>
                <!-- Reason -->
                <validation-provider
                  v-if="parameterFournisseurPaiement === 'stripe'"
                  v-slot="{ errors, valid }"
                  :name="$t('Reason')"
                  :rules="{ required:true }"
                >
                  <v-select
                    v-model="submitForm.reason"
                    :items="refundReason"
                    prepend-icon="mdi-help"
                    :label="$t('Reason')"
                    item-text="text"
                    item-value="value"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>

                <validation-provider
                  v-if="parameterFournisseurPaiement === 'payfacto'"
                  v-slot="{ errors, valid }"
                  :name="$t('Reason')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.reason"
                    :label="$t('Reason')"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <div
              class="pa-3 text-center"
            >
              <!--  Bouton annuler-->
              <v-btn
                color="error"
                @click="$emit('close-dialog')"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <!--  Bouton submit-->
              <v-btn
                color="success"
                class="ml-3"
                @click="handleSubmit(submit)"
              >
                {{ $t('Validate') }}
              </v-btn>
            </div>
          </form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Service
  import FacturesService from '@/services/01Cell/FacturesService'
  // Mixins
  import { Payment } from '@/mixins/Payment/Payment'
  // Other
  import Swal from 'sweetalert2'
  import { format } from 'date-fns'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { mapState, mapGetters } from 'vuex'

  export default {
    name: 'FactureRefundDialog',
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    mixins: [Payment],
    props: {
    },
    data () {
      return {
        submitForm: {
          paiementId: null,
          paiementToken: null,
          amount: null,
          montantTotal: null,
          remaining: null,
          reason: null,
          factureId: null,
          note: [],
        },
        refundReason: [
          { text: this.$i18n.t('Payment.Refund_reason.requested_by_customer'), value: 'requested_by_customer' },
          { text: this.$i18n.t('Payment.Refund_reason.duplicate'), value: 'duplicate' },
          { text: this.$i18n.t('Payment.Refund_reason.fraudulent'), value: 'fraudulent' },
        ],
      }
    },

    computed: {
      ...mapState(['userInfo']),
      ...mapGetters('parameters', {
        parameterFournisseurPaiement: 'getFournisseurPaiement',
      }),
      maxefund () {
        return this.submitForm.remaining
      },
    },

    methods: {
      async submit () {
        Swal.fire({
          icon: 'warning',
          title: this.$i18n.t('Refund'),
          text: this.$i18n.t('Payment.swal.text.refund'),
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('Validate'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Start Spinner
            this.$loading.show()
            try {
              const note = {
                note: this.submitForm.reason,
                user: this.userInfo.info,
                date: format(Date.now(), 'yyyy-MM-dd'),
              }
              if (this.submitForm.note) {
                this.submitForm.note.push(note)
              } else {
                this.submitForm.note = [note]
              }
              // Refund
              const refundSatus = await FacturesService.refund(this.submitForm.paiementId, { refund: this.submitForm })

              this.$emit('close-dialog', refundSatus)
            } catch (error) {
              // Stop Spinner
              this.$loading.hide()
              console.log(error)
            }
          }
        })
      },
      resetSubmitForm (submitFormRefund) {
        this.submitForm = submitFormRefund
      },
    },
  }
</script>
