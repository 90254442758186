import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/listes')
    },
    save (list) { // Création d'une nouvelle entrée
        return api().post('/listes', list)
    },
    view (listId) { // Voir une entrée spécifique
        return api().get(`/listes/${listId}`)
    },
    update (list, listId) { // Mise à jour d'une entrée spécifique
        return api().put(`/listes/${listId}`, list)
    },
    remove (listId) { // Suppression d'une entrée spécifique
        return api().delete(`/listes/${listId}`)
    },
    search (item) { // Recherche
        return api().get(`/listes/search/${item}`)
    },
}
