import store from '@/store/index'
// Utilisation
// this.$dialog.show({ message: 'Salut' })
// this.$dialog.hide()

const dialog = {
        show (data) {
            store.commit('dialog/showDialog', data)
        },
        hide (data) {
            store.commit('dialog/hideDialog')
        },
    }

export default dialog
