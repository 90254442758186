<template>
  <v-container
    id="usermanagement-roles"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-cart"
      :title="`${$t('sidebar.order').toUpperCase()}`"
      class="px-5 py-3"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <v-text-field
          v-model="submit"
          full-width
          :label="$t('search')"
          @keyup.enter="search"
        />
      </v-col>

      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-breadcrumbs
            :append="false"
            large
            divider=""
          >
            <v-breadcrumbs-item
              v-for="item in breadcrumbs"
              :key="item.text"
            >
              <a
                href="#"
                @click.prevent="handleFunctionCall(item.function)"
              >{{ item.text }}</a>
            </v-breadcrumbs-item>
          </v-breadcrumbs>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <div
            class="text-right"
          >
            <v-btn
              color="blue-grey"
              class="white--text"
              @click="dialogPickupSearch = true"
            >
              {{ getPickupName }}
              <v-icon
                right
                dark
              >
                mdi-truck-fast
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <client-commande
        ref="clientCommande"
        :table-data="tableData"
        :pickup-comptoir="pickupComptoir"
        @remove-order="removeOrder"
      />
    </base-material-card>

    <v-dialog
      v-model="dialogCalendar"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="green"
        >
          <v-btn
            icon
            dark
            @click="dialogCalendar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-calendar
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('Calendar') }}</h3>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!-- Composant Calender-->
        <calendar
          v-if="dialogCalendar"
          :table-data="tableData"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogPickupSearch"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <span class="text-h2">{{ $t('order.Conters_Pickup') }}</span>
          <v-icon
            aria-label="Close"
            @click="dialogPickupSearch = false"
          >
            mdi-close
          </v-icon>
          <div class="mt-2">
            <v-row
              justify="center"
              align="center"
            />
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              justify="center"
              align="center"
            >
              <!-- Comptoir-->
              <v-col
                cols="12"
                md="12"
              >
                <v-list
                  shaped
                >
                  <v-list-item-group
                    v-model="pickupSelected"
                    class="ml-4 mt-0"
                    required
                  >
                    <template v-for="(item, i) in pickupComptoir">
                      <v-list-item
                        v-if="!item.id"
                        :key="`divider-${i}`"
                        disabled
                      >
                        Comptoir
                      </v-list-item>

                      <v-list-item
                        v-else
                        :key="`item-${i}`"
                        :value="item.id"
                        active-class="green--text text--accent-4"
                        @change="getPickup(item.id)"
                      >
                        <template #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox
                              :input-value="active"
                              color="green"
                            />
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title v-html="item[language]" />
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // Components
  import ClientCommande from '@/components/Client/Commande/Commande'
  import Calendar from '@/components/Client/Commande/calendar/Calendar'
  // Service
  import CommandesService from '@/services/01Cell/CommandesService'
  import ListService from '@/services/01Cell/ListService'
  import ClientsService from '@/services/01Cell/ClientsService'
  // Other
  import { EventBus } from '@/utils/event-bus'
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'OperationCommande',
    components: {
      ClientCommande,
      Calendar,
    },
    data () {
      return {
        language: this.$i18n.t('Language'),
        submit: '',
        tableData: [],
        dialogCalendar: false,
        dialogPickupSearch: false,
        pickupComptoir: [],
        pickupSelected: this.$store.state.pickup.address,
        breadcrumbs: [
          {
            text: `[ ${this.$i18n.t('order.operation.Orders_in_progress')} ]`,
            href: '#',
            function: 'orderInProgress',
          },
          {
            text: `[ ${this.$i18n.t('Calendar')} ]`,
            href: '#',
            function: 'showCalendar',
          },
        ],
      }
    },

    computed: {
      ...mapState(['userInfo']),
      getPickupName () {
        const findPickup = this.pickupComptoir.find(item => item.id === this.pickupSelected)
        if (findPickup) return findPickup.item
        return null
      },
    },

    async mounted () {
      // Start Spinner
      this.$loading.show()
      try {
        // On récupère des comptoirs
        const pickupComptoirPromise = ListService.search('commandes_comptoir')
        // On récupère les commandes ouvertes
        const tableDataPromise = CommandesService.openOrder()
        const [pickupComptoir, tableData] = await Promise.all([pickupComptoirPromise, tableDataPromise])

        this.pickupComptoir = pickupComptoir

        // Comptoir
        // On ajoute comptoir si il y en a
        if (this.pickupComptoir.length) this.pickupComptoir.unshift({ id: null, en: null, fr: null, item: this.$i18n.t('order.Pickup') })
        // On ajoute les livraison
        this.pickupComptoir.unshift({ id: -2, en: 'Pickup', fr: 'Livraison', item: this.$i18n.t('order.Pickup') })
        // On ajoute tous les commandes au début
        this.pickupComptoir.unshift({ id: -1, en: 'All orders', fr: 'Toutes les commandes', item: this.$i18n.t('order.All_orders') })
        // Si la variable 'pickupSelected' est null et qu'il y a des comptoirs, on affiche le dialog pour que la personne choissise un copmtoir
        if (!this.pickupSelected && this.pickupComptoir.length) {
          // Par défaut, on mets -1 (Toutes les commandes)
          this.pickupSelected = -1
          // On mets à jour dans le vuex
          this.setPickup(-1)
          // Ouvre le dialog
          this.dialogPickupSearch = true
        }
        await this.getData(this.pickupSelected, tableData)
        // console.log(this.tableData)
        // Stop Spinner
        this.$loading.hide()

        // Validation des retraité. UQAM seulement. Pas besoin du Async
        if (this.userInfo.info.roles.some(role => role === 'admin') && this.$company === 'uqam') ClientsService.uqamRetraite()
      } catch (error) {
        console.log(error)
        // Stop Spinner
        this.$loading.hide()
      }

      EventBus.$on('update-commande', (commande) => {
        // On mets à jour la commande
        // Fonctionne pas
        // const findTableIndex = this.tableData.findIndex(item => item.id === commande.id)
        // this.tableData[findTableIndex] = commande

        Object.assign(this.tableData[this.tableData.findIndex(el => el.id === commande.id)], commande)
      })
    },

    destroyed () {
      EventBus.$off('update-commande')
    },

    methods: {
      ...mapMutations('pickup', [
        'setPickup',
      ]),
      handleFunctionCall (functionName) {
        this[functionName]()
      },
      async orderInProgress () {
        // Start Spinner
        this.$loading.show()
        try {
          const tableData = await CommandesService.openOrder()
          await this.getData(this.pickupSelected, tableData)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async showCalendar () {
        this.dialogCalendar = true
      },
      async search () {
        if (this.submit.length === 0) return
        // Refresh à la page 1
        this.$refs.clientCommande.refreshTable()

        // Start Spinner
        this.$loading.show()
        try {
          // Reset table
          this.tableData = []

          const tableData = await CommandesService.search(this.submit)
          await this.getData(this.pickupSelected, tableData)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      removeOrder (id) {
        // Viens du composant 'components/Client/Commande/demoUqam/Commande.vue'
        this.tableData = this.tableData.filter(item => item.id !== id)
      },
      async getPickup (comptoirId) {
        // Start Spinner
        this.$loading.show()
        try {
          const tableData = await CommandesService.openOrder()
          await this.getData(comptoirId, tableData)

          // Ouvre le dialog
          this.dialogPickupSearch = false
          // On mets à jour dans le vuex
          this.setPickup(comptoirId)
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async getData (comptoirId, tableData) {
        try {
          // Si le comptoirId est -1 ou null, on récupère toutes les commandes
          if (comptoirId === -1 || !comptoirId) {
            this.tableData = tableData
          } else if (comptoirId === -2) {
            // Livraison (ComptoirId null)
            this.tableData = tableData.filter(item => !item.ComptoirId)
          } else {
            // On récupère les commandes relier au comptoi
            this.tableData = tableData.filter(item => item.ComptoirId === comptoirId)
          }
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
