<template>
  <div class="VueTables__limit-field">
    <v-select
      :id="props.selectAttrs.id"
      :value="props.selectAttrs.value"
      :label="$t('vueTables.texts.Per_Page')"
      :items="props.perPageValues"
      @change="props.selectEvents.change"
    />
  </div>
</template>

<script>
  export default {
    name: 'VtPerPageSelector',
    // eslint-disable-next-line vue/require-prop-types
    props: ['props'],
  }
</script>
