<template>
  <v-container
    id="extended-tables"
    fluid
    tag="section"
  >
    <div class="py-3" />
    <base-material-card
      color="success"
      icon="mdi-cart-outline"
      inline
      :title="$t('order.commandesResponsable')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="tableHeaders"
        :items="ubrResponsableOrders"
      >
        <!-- Mode de paiement-->
        <template
          slot="item.mode_paiement"
          slot-scope="props"
        >
          <!-- Payable par code de facturation( UQAM UBR)-->
          <div v-if="props.item.mode_paiement === 1">
            <v-icon
              color="green"
              large
            >
              mdi-book-open-variant
            </v-icon>
            {{ getOrderCodeName }}
          </div>

          <!-- Carte de crédit-->
          <div v-if="props.item.mode_paiement === 2">
            <v-icon
              color="primary"
              large
            >
              mdi-credit-card
            </v-icon>
            {{ $t('order.Credit_card') }}
          </div>
        </template>

        <!-- Type (Description)-->
        <!-- Nouvelle ligne-->
        <template
          slot="item.type"
          slot-scope="props"
        >
          <div v-if="props.item.type === 1">
            <v-icon
              color="grey lighten-1"
              large
            >
              mdi-sim-outline
            </v-icon>
            {{ $t('order.type.1') }}
          </div>
          <!-- Migration-->
          <div v-if="props.item.type === 2">
            <v-icon
              color="grey lighten-1"
              large
            >
              mdi-sim
            </v-icon>
            {{ $t('order.type.2') }}
          </div>
          <!-- Achat d'équipement-->
          <div v-if="props.item.type === 3">
            <div>
              <v-icon
                color="grey lighten-1"
                large
              >
                mdi-phone
              </v-icon>
              {{ $t('order.type.3') }}
            </div>
            <!-- Description-->
            <div>
              <span>Description: {{ props.item.description_equipement }}</span>
            </div>
            <!-- Coût-->
            <div>
              <span v-if="props.item.cout_facture">{{ $t('contract.calls_detail.Cost') }}: {{ props.item.cout_facture }} $</span>
            </div>
          </div>
        </template>

        <!-- UBR-->
        <template
          slot="item.code_facturation"
          slot-scope="props"
        >
          <span>{{ props.item.code_facturation }}</span>
          <v-btn
            icon
            @click="editUbr(props.item)"
          >
            <v-icon
              class="mr-2"
              size="x-large"
              color="grey"
            >
              mdi-pencil-outline
            </v-icon>
          </v-btn>
        </template>

        <!-- Nom du Client-->
        <template
          slot="item.Client.nom"
          slot-scope="props"
        >
          <span>{{ props.item.Client.prenom }} {{ props.item.Client.nom }}</span>
        </template>

        <!-- Statut-->
        <template
          slot="item.statut"
        >
          <span>{{ $t('order.status.Awaiting_manager_approval') }}</span>
        </template>

        <!-- Intervention (Bouton spécial)-->
        <template
          slot="item.intervention"
          slot-scope="props"
        >
          <!--  Approuver-->
          <v-btn
            class="ml-2"
            elevation="2"
            small
            color="blue"
            @click="approve(props.item)"
          >
            {{ $t('order.Approve') }}
          </v-btn>

          <!--  Refuser-->
          <v-btn
            class="ml-2"
            elevation="2"
            small
            color="red"
            @click="refuse(props.item)"
          >
            {{ $t('order.Refuse') }}
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>

    <!--  Dialog modifier UBR-->
    <v-dialog
      v-model="dialogUbr"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h2">{{ $t('ubr.Edit_ubr') }}</span>
          <v-icon
            large
            aria-label="Close"
            @click="dialogUbr = false"
          >
            mdi-close
          </v-icon>
          <div class="mt-2">
            <v-row
              justify="center"
              align="center"
            />
          </div>
        </v-card-title>
        <v-card-text>
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="6"
            >
              <validation-observer
                ref="obs"
                v-slot="{ invalid, handleSubmit }"
              >
                <form>
                  <!-- UBR UQAM-->
                  <validation-provider
                    v-if="$company === 'uqam'"
                    v-slot="{ errors, valid }"
                    name="UBR"
                    rules="required"
                  >
                    <!-- UBR UQAM-->
                    <v-select
                      v-model="submitForm.newCodeFacturation"
                      :items="ubrResponsable"
                      item-text="description"
                      item-value="ubr"
                      persistent-hint
                      single-line
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>

                  <validation-provider
                    v-else
                    v-slot="{ errors, valid }"
                    name="UBR"
                    :rules="getUbrRules"
                  >
                    <v-text-field
                      v-model="submitForm.newCodeFacturation"
                      class="mt-3"
                      :maxlength="parameterLongueurCodeFacturation"
                      label="UBR"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>
                  <!-- CONFIRMATION-->
                  <v-btn
                    :disabled="invalid"
                    class="mt-3"
                    color="success"
                    @click="handleSubmit(updateUbr)"
                  >
                    {{ $t('Validate') }}
                  </v-btn>
                </form>
              </validation-observer>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Dialog refusé-->
    <v-dialog
      v-model="dialogRefuse"
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h2">{{ $t('order.swal.title.I_refuse_order') }}</span>
          <v-icon
            large
            aria-label="Close"
            @click="dialogRefuse = false"
          >
            mdi-close
          </v-icon>
          <div class="mt-2">
            <v-row
              justify="center"
              align="center"
            />
          </div>
        </v-card-title>
        <v-card-text>
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="6"
            >
              <validation-observer
                ref="obs"
                v-slot="{ invalid, handleSubmit }"
              >
                <form>
                  <span>{{ $t('ubr.Message_not_approve') }}</span>
                  <validation-provider
                    v-slot="{ errors, valid }"
                    :name="$t('Comment')"
                    rules="required"
                  >
                    <!-- Commentaire-->
                    <v-textarea
                      v-model="submitFormRefuse.newNote"
                      outlined
                      class="mt-3"
                      rows="5"
                      auto-grow
                      append-icon="mdi-comment"
                      :error-messages="errors"
                      :success="valid"
                      required
                    />
                  </validation-provider>
                  <!-- CONFIRMATION-->
                  <v-btn
                    :disabled="invalid"
                    class="mt-3"
                    color="success"
                    @click="handleSubmit(confirmRefuse)"
                  >
                    {{ $t('Validate') }}
                  </v-btn>
                </form>
              </validation-observer>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  import MessagesService from '@/services/01Cell/MessagesService'
  // Mixins
  import { MyOrders } from '@/mixins/Client/Order/MyOrders'
  import { ordersUbrRules } from '@/mixins/Client/Order/ordersUbrRules'
  // Other
  import { EventBus } from '@/utils/event-bus'
  import Swal from 'sweetalert2'
  import { mapGetters, mapState } from 'vuex'
  // Components
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'ResponsibleOrders',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [MyOrders, ordersUbrRules],
    props: {
      ubrResponsableOrders: Array,
      ubrResponsable: Array,
    },
    data () {
      return {
        language: this.$i18n.t('Language'),
        tableHeaders: [
          { text: 'No', value: 'id', sortable: true, class: 'text-h4 primary--text', width: 100 },
          { text: this.$i18n.t('order.Payment_mode'), value: 'mode_paiement', sortable: true, class: 'text-h4 primary--text' },
          { text: 'UBR', value: 'code_facturation', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Description', value: 'type', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('order.Order_by'), value: 'Client.nom', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Status'), value: 'statut', sortable: true, class: 'text-h4 primary--text' },
          { text: '', value: 'intervention', sortable: true, class: 'text-h4 primary--text' },
        ],
        dialogUbr: false,
        dialogRefuse: false,
        submitForm: {
          newCodeFacturation: null,
          oldCodeFacturation: null,
          orderId: null,
        },
        submitFormRefuse: {
          newNote: null,
          oldNote: null,
          orderId: null,
          clientLanguage: null,
        },
      }
    },

    computed: {
      ...mapState(['userInfo']),
      ...mapGetters('parameters', {
        parameterLongueurCodeFacturation: 'getLongueurCodeFacturation',
      }),
    },

    methods: {
      editUbr (order) {
        this.dialogUbr = true
        this.submitForm.newCodeFacturation = order.code_facturation
        this.submitForm.oldCodeFacturation = order.code_facturation
        this.submitForm.orderId = order.id
      },
      async updateUbr () {
        try {
          if (this.submitForm.newCodeFacturation !== this.submitForm.oldCodeFacturation) {
            // Start Spinner
            this.$loading.show()

            // On confirme la commande
            const data = { code_facturation: this.submitForm.newCodeFacturation, ubrResponsableValidation: true }
            console.log(data)
            // await CommandesService.update(data, this.submitForm.orderId)
            // // On update le client
            // EventBus.$emit('update-order')
          }
          // Close Dialog
          this.dialogUbr = false
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      approve (item) {
        Swal.fire({
          title: `${this.$i18n.t('order.swal.title.I_approve_order')}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('order.Approve'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Start Spinner
              this.$loading.show()
              // Statut et Étape
              const { statut, etape } = this.getNextStatutStep(item)
              // On confirme la commande
              const data = {
                date_approuvee_annulee: Date.now(),
                approuvee_par: this.userInfo.info.username,
                statut,
                etape,
                sendMail: false,
                ubrResponsableValidation: true,
                mail: {
                  mailBody: null,
                  mailSujet: null,
                  mailTo: null,
                  commandePdf: false,
                },
              }

              // Si transfert de ligne (type 2) et que transfert de ligne Telus à Telus, on envoie un courriel à l'utilisatur pour qu'il continue sa commande
              if (item.type === 2 && item.migration_fournisseur.toLowerCase() === this.$fournisseur.toLowerCase()) {
                // Récupération des messages
                const messages = await MessagesService.multiple({ messages: [5, 1] })
                const message = messages.find(item => item.no === 5)
                const signature = messages.find(item => item.no === 1)

                // Sujet
                const subject = message[`sujet_${item.Client.langue}`].replace(/<\/?[^>]+(>|$)/g, '')
                // Message
                const messageHtml = message[item.Client.langue].replaceAll('[|COMMANDES_CLIENT_PRENOM|]', item.Client.prenom.charAt(0).toUpperCase() + item.Client.prenom.slice(1))
                  .replaceAll('[|COMMANDES_CLIENT_NOM|]', item.Client.nom.charAt(0).toUpperCase() + item.Client.nom.slice(1))
                  .replace('[|SIGNATURE|]', signature[item.Client.langue])

                data.sendMail = true
                data.mail.mailBody = messageHtml
                data.mail.mailSujet = subject
                data.mail.mailTo = item.Client.email
              }

              await CommandesService.update(data, item.id)

              // Voir le composant 'Dashboard'
              // On enlève l'item de la liste car il y a un asynchrone pour les commande responsable ubr
              this.$emit('remove-responble-ubr-item', item.id)
              // On update le client
              EventBus.$emit('update-order')
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      refuse (item) {
        this.submitFormRefuse.newNote = null
        this.submitFormRefuse.oldNote = item.note
        this.submitFormRefuse.orderId = item.id
        this.submitFormRefuse.clientLanguage = item.Client.langue
        this.submitFormRefuse.clientEmail = item.Client.email
        // Open dialog
        this.dialogRefuse = true
      },
      async confirmRefuse () {
        try {
          // Start Spinner
          this.$loading.show()
          // Récupération des messages
          const messages = await MessagesService.multiple({ messages: [24, 1] })
          const message = messages.find(item => item.no === 24)
          const signature = messages.find(item => item.no === 1)

          // Sujet
          const subject = message[`sujet_${this.submitFormRefuse.clientLanguage}`].replace(/<\/?[^>]+(>|$)/g, '')
          // Message
          const messageHtml = message[this.submitFormRefuse.clientLanguage].replace('[|SIGNATURE|]', signature[this.submitFormRefuse.clientLanguage])

          // Note
          const note = `${this.$i18n.t('ubr.Refuse_note')} ${this.submitFormRefuse.newNote}\n\n${this.submitFormRefuse.oldNote}`

          // On change le statut de la commande
          const updateCommande = {
            // statut: -1,
            etape: -1,
            approuvee_par: this.userInfo.info.username,
            date_approuvee_annulee: Date.now(),
            sendMail: true,
            ubrResponsableValidation: true,
            note: note,
            mail: {
              mailBody: messageHtml,
              mailSujet: subject,
              mailTo: this.submitFormRefuse.clientEmail,
              commandePdf: true,
            },
          }

          await CommandesService.confirmationRequest(this.submitFormRefuse.orderId, updateCommande)

          // Voir le composant 'Dashboard'
          // On enlève l'item de la liste car il y a un asynchrone pour les commande responsable ubr
          this.$emit('remove-responble-ubr-item', this.submitFormRefuse.orderId)

          // Close Dialog
          this.dialogRefuse = false

          // On update le client
          EventBus.$emit('update-order')
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
