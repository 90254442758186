import { EventMessageUtils, EventType, InteractionStatus } from '@azure/msal-browser'
import { callMsGraphPhoto } from '@/utils/MsGraphApiCall'

function accountArraysAreEqual (arrayA, arrayB) {
    if (arrayA.length !== arrayB.length) {
        return false
    }

    const comparisonArray = [...arrayB]

    return arrayA.every((elementA) => {
        const elementB = comparisonArray.shift()
        if (!elementA || !elementB) {
            return false
        }

        return (elementA.homeAccountId === elementB.homeAccountId) &&
               (elementA.localAccountId === elementB.localAccountId) &&
               (elementA.username === elementB.username)
    })
}

const msalPlugin = {
  install (Vue, msalInstance) {
        const inProgress = InteractionStatus.Startup
        // eslint-disable-next-line no-proto
        const accounts = msalInstance.getAllAccounts()

        const state = Vue.observable({
            instance: msalInstance,
            inProgress: inProgress,
            accounts: accounts,
        })

        Vue.prototype.$msal = state

        msalInstance.addEventCallback((message) => {
            switch (message.eventType) {
                case EventType.ACCOUNT_ADDED:
                case EventType.ACCOUNT_REMOVED:
                case EventType.LOGIN_SUCCESS:
                case EventType.SSO_SILENT_SUCCESS:
                case EventType.HANDLE_REDIRECT_END:
                case EventType.LOGIN_FAILURE:
                case EventType.SSO_SILENT_FAILURE:
                case EventType.LOGOUT_END:
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                case EventType.ACQUIRE_TOKEN_FAILURE:
                    // eslint-disable-next-line no-case-declarations
                    const currentAccounts = msalInstance.getAllAccounts()
                    if (!accountArraysAreEqual(currentAccounts, state.accounts)) {
                        state.accounts = currentAccounts
                    }
                    break
            }

            const status = EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress)
            if (status !== null) {
                state.inProgress = status
            }

            // On load l'image du profil
            if (message.eventType === 'msal:loginSuccess') {
              callMsGraphPhoto()
            }
        })
  },
}

export default msalPlugin
