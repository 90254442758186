<template>
  <v-container
    id="extended-tables"
    fluid
    tag="section"
  >
    <div class="py-3" />
    <base-material-card
      color="success"
      icon="mdi-file-document-outline"
      inline
      :title="$t('invoices.Other_invoices')"
      class="px-5 py-3"
    >
      <!--  Table pour les Factures Autres (Qui ne sont pas categorie 1)-->
      <v-data-table
        :headers="tableHeaders"
        :items="factures"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        item-key="id"
        @page-count="pageCount = $event"
      >
        <!--  Type-->
        <!--  eslint-disable-next-line vue/valid-v-slot-->
        <template #item.type="{ item }">
          <!--  Type 1-->
          <v-icon
            v-if="item.type === 1"
            large
            color="green"
          >
            mdi-book-open-variant
          </v-icon>
          <!--  Type 2-->
          <v-icon
            v-else
            large
            color="primary"
          >
            mdi-credit-card-check-outline
          </v-icon>
        </template>

        <!--  DETAIL-->
        <!--  eslint-disable-next-line vue/valid-v-slot-->
        <template #item.detail="{ item }">
          <span class="primary--text">{{ item.description }}</span>
          <!--  Pour la vue Dashboard, on affiche pas le id du détail-->
          <span v-if="$route.name !== 'Dashboard'"> {{ `[ id: ${item.id} ]` }}</span>
          <!--  Bouton pour payer-->
          <!--  Pour corporatif 2 seulement-->
          <template v-if="item.type === 2">
            <!--  Si Paiement est défini, on affiche la facture-->
            <v-btn
              v-if="item.paiementId"
              color="white"
              x-small
              class="ma-2"
              @click="openDialogPaiementFacture(item.id)"
            >
              {{ $t('contract.Bill') }}
              <v-icon
                right
                dark
              >
                mdi-receipt
              </v-icon>
            </v-btn>
            <!--  Si paiementId est null, il doit payer la facture-->
            <v-btn
              v-else
              :disabled="$route.name !== 'Dashboard'"
              color="pink accent-4"
              x-small
              class="ma-2"
              @click="payerFacture(item)"
            >
              <v-icon
                left
                dark
                color="yellow accent-3"
              >
                mdi-alert
              </v-icon>
              {{ $t('invoices.To_pay') }}
            </v-btn>
          </template>
          <!--  Lot-->
          <!--  Pour corporatif 1 seulement-->
          <template v-if="item.type === 1 && item.lot">
            [ Lot: {{ item.lot }} ]
          </template>
        </template>

        <!--  Fichiers-->
        <template
          slot="item.fichiers"
          slot-scope="props"
        >
          <!--  Ajouter un fichier-->
          <v-btn
            v-if="fileManagement"
            color="white"
            x-small
            @click="addFile(props.item.id)"
          >
            <v-icon
              dark
            >
              mdi-file-cog
            </v-icon>
          </v-btn>

          <!--  Liste des fichiers-->
          <span
            v-for="(fichier, index) in props.item.fichiers"
            :key="fichier.key"
          >
            <a
              :class="fileManagement ? 'ml-3' : ''"
              href=""
              @click.prevent="getFile(fichier.Key)"
            >{{ fichier.name }}</a>

            <!--  Suppression du fichier-->
            <v-icon
              v-if="fileManagement"
              class="pointer"
              color="error"
              @click="deleteFile('cell-facturation', fichier.Key, props.item.id, index)"
            >
              mdi-delete
            </v-icon>
          </span>
        </template>

        <!--  Total avant taxe-->
        <!--  eslint-disable-next-line vue/valid-v-slot-->
        <template #item.montant_at="{ item }">
          <span v-if="item.montant_at">{{ item.montant_at }}$</span>
        </template>

        <!--  Total-->
        <!--  eslint-disable-next-line vue/valid-v-slot-->
        <template #item.montant_total="{ item }">
          {{ item.montant_total }}$
        </template>
      </v-data-table>
      <div
        class="text-center pt-2"
      >
        <v-pagination
          v-model="page"
          :length="pageCount"
        />
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="12"
            sm="4"
          >
            <!-- <v-text-field
                    :value="itemsPerPage"
                    :label="$t('table.Items_per_page')"
                    type="number"
                    min="-1"
                    max="15"
                    @input="itemsPerPage = parseInt($event, 10)"
                  /> -->
            <v-select
              v-model="itemsPerPage"
              :items="itemsPage"
              :label="$t('table.Bills_per_page')"
            />
          </v-col>
        </v-row>
      </div>
    </base-material-card>

    <!--  Dialog reçu-->
    <v-dialog
      v-model="dialogPaiement"
      max-width="1000"
    >
      <v-card>
        <!--  Print-->
        <v-icon
          class="mt-5"
          right
          dark
          large
          color="primary"
          @click="print"
        >
          mdi-printer
        </v-icon>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="dialogPaiement = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="text-body-1 text-center">
          <v-row>
            <v-col
              cols="12"
            >
              <div :id="printMe">
                <div v-html="recuFormat" />
              </div>
            </v-col>
          </v-row>

          <v-btn
            class="mt-2 ml-4"
            color="info"
            depressed
            default
            rounded
            @click="dialogPaiement = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Dialog pour dire au client qu'il y a des factures mensuelle non payées-->
    <v-dialog
      v-model="dialogInvoiceUnPaid"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="dialogInvoiceUnPaid = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="text-body-1 text-center">
          <v-row>
            <v-col
              cols="12"
            >
              <v-icon
                size="78px"
                color="warning"
              >
                mdi-alert
              </v-icon>
            </v-col>
            <v-col
              cols="12"
            >
              <div v-html="dialogInvoiceUnPaidMessage" />
            </v-col>
          </v-row>

          <v-btn
            class="mt-2 ml-4"
            color="info"
            depressed
            default
            rounded
            @click="returnToContract"
          >
            {{ $t('contract.bill.Go_to_my_contracts') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Transfert de fichiers-->
    <v-dialog
      v-model="filesTransfert"
      max-width="1000"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="filesTransfert = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="text-body-1 text-center">
          <v-row>
            <v-col
              cols="12"
            >
              <transfert-files
                v-if="filesTransfert"
                :files-transfert-info="filesTransfertInfo"
                @update-factures="updateFactures"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="filesTransfert = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--  Payer une une facture manuelle GlobalPayments-->
    <v-dialog
      v-model="paymentGlobalPaymentsDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="paymentGlobalPaymentsDialog = !paymentGlobalPaymentsDialog"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <global-payments-paiement
            :paiement-facture="paiementFacture"
            @payer-facture-success="payerFactureSuccess"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Payer une une facture manuelle Paysafe-->
    <v-dialog
      v-model="paysafePaymentsDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="paysafePaymentsDialog = !paysafePaymentsDialog"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <paysafe
            :paiement-facture="paiementFacture"
            @payer-facture-success="payerFactureSuccess"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Payer une une facture manuelle Payfacto-->
    <v-dialog
      v-model="payFactoDialog"
      max-width="1200"
    >
      <v-card>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="payFactoDialog = !payFactoDialog"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <pay-facto
            v-if="payFactoDialog"
            :paiement-facture="paiementFacture"
            :state="'paid'"
            :form="form"
            @payer-facture-success="payerFactureSuccess"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  // Components
  import TransfertFiles from '@/components/Files/TransfertFiles'
  import GlobalPaymentsPaiement from '@/components/Client/Facture/FactureManuelPaymentDialog/GlobalPayments'
  import Paysafe from '@/components/Client/Facture/FactureManuelPaymentDialog/Paysafe'
  import PayFacto from '@/components/Client/Facture/FactureManuelPaymentDialog/PayFacto'
  // Service
  import FacturesService from '@/services/01Cell/FacturesService'
  import S3 from '@/services/S3'
  // Mixins
  import { Payment } from '@/mixins/Payment/Payment'
  import { FacturePaiement } from '@/mixins/Facture/FacturePaiement'
  import { Payfacto } from '@/mixins/Payment/Payfacto'
  // Other
  import { EventBus } from '@/utils/event-bus'
  import Swal from 'sweetalert2'

  export default {
    name: 'FactureAutreComponent',
    components: {
      TransfertFiles,
      GlobalPaymentsPaiement,
      Paysafe,
      PayFacto,
    },
    mixins: [Payment, FacturePaiement, Payfacto],
    props: {
      clientId: Number,
      client: Object,
      parameterFournisseurPaiement: String,
      parameterModePaiement: String,
    },
    data () {
      return {
        // form: null, Dans le mixins Payfacto
        factures: [],
        tableHeaders: [
          { text: '', value: 'type', sortable: true, class: 'text-h4 primary--text', width: '40px' },
          {
            text: this.$i18n.t('Detail'),
            align: 'start',
            sortable: false,
            value: 'detail',
            class: 'text-h4 primary--text',
            width: '500px',
          },
          { text: this.$i18n.t('File'), align: 'start', value: 'fichiers', sortable: false, class: 'text-h4 primary--text' },
          { text: `$ ${this.$i18n.t('contract.bill.Total_before_tax')}`, align: 'end', value: 'montant_at', sortable: false, class: 'text-h4 primary--text' },
          { text: 'TPS', align: 'end', value: 'tps', sortable: false, class: 'text-h4 primary--text' },
          { text: 'TVQ', align: 'end', value: 'tvq', sortable: false, class: 'text-h4 primary--text' },
          { text: '$ Total', align: 'end', value: 'montant_total', sortable: false, class: 'text-h4 primary--text' },
        ],
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        itemsPage: [5, 10, 25, 50, 100],
        fileManagement: false,
        filesTransfert: false,
        filesTransfertInfo: {
          bucket: 'cell-facturation',
          key: '',
          fileName: null, // Si on veut donner un nom spécifique au fichier
        },
        paymentGlobalPaymentsDialog: false,
        paysafePaymentsDialog: false,
        payFactoDialog: false,
        printMe: null,
      }
    },

    computed: {
    },

    async mounted () {
      // Fichiers
      // C'est seulement dans la route 'operation-client' que l'on peut ajouter ou supprimer des fichiers
      if (this.$route.name === 'operation-client') this.fileManagement = true
      // Récupération des factures
      this.getFactures()

      // Viens du composant 'Contract.vue'. Lorsque l'un modifie un contract
      EventBus.$on('update-facture-autre', (data) => {
        if (data === this.clientId) this.getFactures()
      })
    },

    destroyed () {
      EventBus.$off('update-facture-autre')
    },

    methods: {
      async getFactures () {
        if (this.clientId) {
          try {
            this.factures = []
            // Récupération des factures autre du client
            this.factures = await FacturesService.autreList(this.clientId)
            // On mets à jour l'icône dans le bouton 'Autre factures' dans la vue 'OperationClient'
            this.updateButtonUnpaidIcon()
          } catch (error) {
            console.log(error)
          }
        }
      },
      updateButtonUnpaidIcon () {
        // On regarde si toutes les factures sont payé. Si il ne sont pas tous payés, on affiche un icône dans le bouton 'Autre factures' dans la vue 'OperationClient' ou 'Dashboard'
        this.$emit('unpaid-invoice', this.factures.find(item => item.type === 2 && item.categorie === 2 && !item.paiementId && item.statut === 1), this.clientId)
      },
      async getFile (key) {
        try {
          const params = {
            bucket: 'cell-facturation',
            key: key,
            expires: 60,
          }
          const getSignedUrl = await S3.getSignedUrl(params)
          window.open(getSignedUrl, '_blank')
        } catch (error) {
          console.log(error)
        }
      },
      addFile (factureId) {
        // On crée le chemin pour télécharger le fichier
        this.filesTransfertInfo.key = `clients/${this.clientId}/factures/${factureId}`
        // On ouvre le dialog
        this.filesTransfert = true
      },
      updateFactures () {
        this.getFactures()
      },
      // Viens du mixins 'FacturePaiement'
      payerFactureUpdate () {
        this.updateButtonUnpaidIcon()
      },
      async deleteFile (bucket, key, factureId, index) {
        Swal.fire({
          title: `${this.$i18n.t('Are_you_sure')}?`,
          text: `${this.$i18n.t('Delete_the_file_permanently')}.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('Yes_delete_it'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await S3.deleteObject(bucket, key)
              // Suppression du fichier dans la table
              // On cherche le fichier dans le array 'fichiers'
              // Il faut trouver la facture avec son id
              const factureIndex = this.factures.findIndex(item => item.id === factureId)
              // On supprime le fichiers avec l'index
              this.factures[factureIndex].fichiers.splice(index, 1)
              Swal.fire(
                `${this.$i18n.t('Deleted')}!`,
                `${this.$i18n.t('Your_file_has_been_deleted')}.`,
                'success',
              )
            } catch (error) {
              console.log(error)
            }
          }
        })
      },
    },
  }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>
