<template>
  <validation-observer
    ref="obs"
    v-slot="{ invalid, handleSubmit }"
  >
    <form>
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors, valid }"
            :name="$t('order.operation.Order_number')"
            rules="required"
          >
            <!-- Numéro de la commande chez le fournisseur-->
            <v-text-field
              v-model="submitForm.fournisseur_no_commande"
              :label="$t('order.operation.Order_number')"
              :error-messages="errors"
              :success="valid"
              required
            />
          </validation-provider>
        </v-col>
      </v-row>
    </form>
    <!-- Submit-->
    <v-btn
      :disabled="invalid"
      class="mt-3"
      color="success"
      @click="handleSubmit(submit)"
    >
      {{ $t('Validate') }}
    </v-btn>

    <v-btn
      class="mt-3"
      color="red"
      @click="close"
    >
      {{ $t('Cancel') }}
    </v-btn>
  </validation-observer>
</template>
<script>
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  import MessagesService from '@/services/01Cell/MessagesService'
  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { EventBus } from '@/utils/event-bus'

  export default {
    name: 'ClientCommandeDemoUqamDialogMissingNoOrder',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        submitForm: {},
        language: this.$i18n.t('Language'),
      }
    },

    computed: {
    },

    methods: {
      async getData (data) {
        this.submitForm = data
      },
      close () {
        this.$emit('close-dialog')
      },
      async submit () {
        // Close Dialog
        this.$emit('close-dialog')

        // Start Spinner
        this.$loading.show()
        try {
          // On affiche le courriel que l'on va envoyer au client dans le dialog
          // Récupération des messages
          const message = await MessagesService.replace(21, this.submitForm.Client.langue)

          // On change le statut de la commande
          const updateCommande = {
            statut: 7,
            etape: 1,
            fournisseur_no_commande: this.submitForm.fournisseur_no_commande,
            sendMail: true,
            mail: {
              mailBody: message.body,
              mailSujet: message.subject,
              mailTo: this.submitForm.Client.email,
              commandePdf: true,
            },
          }

          const response = await CommandesService.confirmationRequest(this.submitForm.id, updateCommande)
          // On update la liste dans le composant 'OperationCommande.vue'
          EventBus.$emit('update-commande', response)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
