<template>
  <v-card-text class="text-center">
    <pages-btn
      large
      color=""
      depressed
      class="v-btn--text success--text"
      @click="authenticateOidc"
    >
      {{ $t('Connexion') }}
    </pages-btn>
  </v-card-text>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'LoginUQAM',

    components: {
      PagesBtn: () => import('@/views/pages/components/Btn.vue'),
    },

    methods: {
      ...mapActions('oidcStore', ['authenticateOidc']),
    },
  }
</script>
