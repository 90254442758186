<template>
  <v-container
    id="usermanagement-roles"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-account-multiple"
      :title="`${$t('Contract').toUpperCase()}S`"
      class="px-5 py-3"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <v-text-field
          v-model="submit"
          full-width
          :label="$t('search')"
          @keyup.enter="search"
        />
      </v-col>

      <v-breadcrumbs
        :append="false"
        large
        divider=""
      >
        <v-breadcrumbs-item
          v-for="item in breadcrumbs"
          :key="item.text"
        >
          <a
            href="#"
            @click.prevent="handleFunctionCall(item.function)"
          >{{ item.text }}</a>
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <template v-if="tableData.length">
        <v-data-table
          :headers="headers"
          :items="tableData"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          class="elevation-1"
          @page-count="pageCount = $event"
        >
          <!--  eslint-disable-next-line vue/valid-v-slot-->
          <template #item.contrat="props">
            <!--  Contrat-->
            <contract
              :client-contrats="getContracts(props.item)"
            />
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          />
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <!-- <v-text-field
                :value="itemsPerPage"
                :label="$t('table.Items_per_page')"
                type="number"
                min="-1"
                max="15"
                @input="itemsPerPage = parseInt($event, 10)"
              /> -->
              <v-select
                v-model="itemsPerPage"
                :items="itemsPage"
                :label="$t('table.Items_per_page')"
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </base-material-card>
  </v-container>
</template>

<script>
  // Components
  import Contract from '@/components/Client/Contract'
  // Service
  import ContratsService from '@/services/01Cell/ContratsService'

  export default {
    name: 'OperationContrat',
    components: {
      Contract,
    },
    data () {
      return {
        submit: '',
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        itemsPage: [10, 25, 50, 100],
        tableData: [],
        createEditInfo: {
          icon: 'mdi-pencil',
          title: `${this.$i18n.t('Edit')}`,
        },
        headers: [
          { text: '', value: 'contrat' },
        ],
        breadcrumbs: [
          {
            text: `[ ${this.$i18n.t('contract.Temporary_contract')} ]`,
            href: '#',
            function: 'contratTemporaire',
          },
        ],
      }
    },

    methods: {
      handleFunctionCall (functionName) {
        this[functionName]()
      },
      getContracts (contract) {
        return [contract]
      },
      async contratTemporaire () {
        this.submit = ''
        // Start Spinner
        this.$loading.show()
        try {
          // Reset table
          this.tableData = []

          const tableData = await ContratsService.search('Numéro temporaire de la commande')
          this.tableData = tableData
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async search () {
        if (this.submit.length === 0) return
        // Start Spinner
        this.$loading.show()
        try {
          // Reset table
          this.tableData = []

          const tableData = await ContratsService.search(this.submit)
          this.tableData = tableData
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
