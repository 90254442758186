<template>
  <div
    v-if="oidcIsAuthenticated"
    class="about"
  >
    <h1>This route requires authentication</h1>
    <signed-in-user />
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import SignedInUser from '@/components/OIDC/SignedInUser'

  export default {
    name: 'Protected',
    components: { SignedInUser },
    computed: {
      ...mapGetters('oidcStore', [
        'oidcIsAuthenticated',
        'oidcUser',
      ]),
      userDisplay: function () {
        return this.oidcUser
      },
    },
  }
</script>

<style>
.json-markup {
  color: transparent;
}
.json-markup span {
  color: black;
  float: left;
}
.json-markup .json-markup-key {
  clear: left;
}
</style>
