<script>
  import { Bar } from 'vue-chartjs'

  export default {
    extends: Bar,
    props: {
      data: Object,
    },
    mounted () {
      this.renderChart(
        {
          labels: this.data.labels,
          datasets: this.data.datasets,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            // xAxes: [
            //   {
            //     display: true,
            //   },
            // ],
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: false, // Set to true to start at zero
                  suggestedMin: -0, // Set your custom minimum value
                  stepSize: 2,
                },
              },
            ],
          },
        },
      )
    },
  }
</script>
