import Vue from 'vue'
import VueI18n from 'vue-i18n'

import fr from 'vuetify/lib/locale/fr'
import en from 'vuetify/lib/locale/en'

// Si localStorage.getItem('APP_LANG_KEY') est undefined
const language = localStorage.getItem('APP_LANG_KEY') ? localStorage.getItem('APP_LANG_KEY') : process.env.VUE_APP_I18N_LOCALE
localStorage.setItem('APP_LANG_KEY', language)

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
  fr: {
    ...require('@/locales/fr.json'),
    $vuetify: fr,
  },
}

export default new VueI18n({
  locale: localStorage.getItem('APP_LANG_KEY') || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: localStorage.getItem('APP_LANG_KEY') || process.env.VUE_APP_I18N_LOCALE,
  messages,
})
