import { graphConfig, msalInstance, loginRequest } from '@/config/msal'
import axios from 'axios'
import store from '@/store/index'

export async function callMsGraph () {
    const accessToken = await getAccessToken()
    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`

    headers.append('Authorization', bearer)

    const options = {
        method: 'GET',
        headers: headers,
    }

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => {
            console.log(error)
            throw error
        })
}

export async function callMsGraphPhoto () {
    const accessToken = await getAccessToken()
    // On regarde si l'utilisateur à des photos
    let photos = 0
    await axios.get('https://graph.microsoft.com/v1.0/me/photos', {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    }).then(response => {
      photos = response.data.value.length
    }).catch(() => {
      console.log('no profile photo')
    })

    // Si l'utilisateur à des photos, on récupère la photo
    if (photos) {
      axios.get(`${graphConfig.graphMeEndpoint}/photos/48x48/$value`, {
          headers: {
              Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'blob',
      }).then(response => {
        const url = window.URL || window.webkitURL
        const blobUrl = url.createObjectURL(response.data)
        store.commit('updateImageProfil', blobUrl)
      }).catch((error) => {
        console.error(`onRejected function called: ${error.message}`)
        store.commit('updateImageProfil', null)
      })
    } else {
      store.commit('updateImageProfil', null)
    }

    // const response = await axios.get('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', {
    //     headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //     },
    //     responseType: 'blob',
    // })

    // const url = window.URL || window.webkitURL
    // const blobUrl = url.createObjectURL(response.data)
    // return blobUrl
}

async function getAccessToken () {
      // Récupération sur le client
    const accounts = msalInstance.getAllAccounts()
    // Récupération du token
    const response = await msalInstance.acquireTokenSilent({
      scopes: loginRequest.scopes,
      accounts,
    })
    return response.accessToken
}
