export const ContractEdit = {
    data () {
        return {
          type: [
            {
              item: 'UBR',
              valeur: 1,
              disabled: false,
            },
            {
              item: this.$i18n.t('order.Credit_card'),
              valeur: 2,
              disabled: false,
            },
          ],
          provider: [
            {
              item: 'Bell',
              valeur: 'bell',
            },
            {
              item: 'Telus',
              valeur: 'telus',
            },
          ],
          status: [
            {
              item: 'Actif',
              valeur: 1,
              disabled: false,
            },
            // {
            //   item: this.$i18n.t('Inactive'),
            //   valeur: 2,
            //   disabled: false,
            // },
            {
              item: this.$i18n.t('Inactive'),
              valeur: 4,
              disabled: false,
            },
            {
              item: this.$i18n.t('contract.Integrity'),
              valeur: 6,
              disabled: false,
            },
            {
              item: this.$i18n.t('contract.Suspended'),
              valeur: 7,
              disabled: false,
            },
          ],
          flatRate: [
            {
              item: this.$i18n.t('contract.Voice'),
              valeur: 1,
            },
            {
              item: this.$i18n.t('contract.Data'),
              valeur: 2,
            },
            {
              item: `${this.$i18n.t('contract.Voice')}/${this.$i18n.t('contract.Data')}`,
              valeur: 3,
            },
          ],
        }
    },

    computed: {
      getStatus () {
        // Pour un type corporatif (1) on ne peut pas mettre l'état suspendu
        const status = this.status.map(item => {
            if (this.submitForm.type === 1 && item.valeur === 7) {
              item.disabled = true
            }
            return item
        })
        return status
      },
    },

    methods: {
    },
}
