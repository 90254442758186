import notifier from './notifier'
import loading from './loading'
import dialog from './dialog'

export default {
    install (Vue) {
        Vue.prototype.$notifier = notifier
        Vue.prototype.$loading = loading
        Vue.prototype.$dialog = dialog
    },
}
