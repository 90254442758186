
  // Service
  import PayFactoService from '@/services/PAYFACTO/PayFactoService'

export const Payfacto = {
    data () {
      return {
        form: null,
      }
    },

    methods: {
      async getForm () {
        if (!this.form) {
          // Start Spinner
          this.$loading.show()
          try {
            this.form = await PayFactoService.getForm()
          } catch (error) {
            console.log(error)
          }
        }
      },
    },
}
