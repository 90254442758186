// Services
import CommandesService from '@/services/01Cell/CommandesService'
// Other
import { mapGetters } from 'vuex'
import { getHours, getMinutes, parseISO, format } from 'date-fns'

export const ordersPickupDate = {
    data () {
        return {
          timeLoading: false,
          hoursPickUp: [],
        }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterStepHeure: 'getStepHeure',
        parameterAllowHours: 'getAllowHours',
        parameterAllowDays: 'getAllowDays',
      }),
    },

    methods: {
      allowedDates (val) {
        return this.parameterAllowDays.some(item => item.toString() === format(parseISO(val), 'i'))
      },
      getDatePickup (isAdministrator) {
        // Si c'est le gestionnaire (gestion) la personne à accès à toute les dates futur
        if (isAdministrator) return new Date().toISOString().slice(0, 10)

        // Si ce n'est pas le gestionnaire, et qui il y a une date de rdv, on ne veut pas que l'utilisateur choissise une date précédente
        if (this.submitForm.rdv_date) return new Date(this.submitForm.rdv_date_init).toISOString().slice(0, 10)
        return new Date().toISOString().slice(0, 10)
      },
      async getPickupHours (comptoirId, resetTime) {
        // Il faut connaître les heures des commandes ouvertes avec le comptoir pour désactivé les heures déjà pris dans les commandes
        try {
          // On reset l'heure
          if (resetTime) this.submitForm.time = null
          // On récupère les dates si il y a une date
          if (this.submitForm.date) {
            // Show loading
            this.timeLoading = true
            // Création des heures disponible d'après les heures et l'intervalle
            this.hoursPickUp = this.createTime(false)
            // Récupération des commandes ouvertes avec la date choisi et le comptoir
            const commandes = await CommandesService.timeUsed(comptoirId, this.submitForm.date)
            // On regarde les heures de disponible
            commandes.forEach(currentItem => {
              // Ajout d'un 0 si l'heure et les minutes ont seulement 1 chiffre (padStart)
              const time = `${getHours(parseISO(currentItem.rdv_date)).toString().padStart(2, '0')}:${getMinutes(parseISO(currentItem.rdv_date)).toString().padStart(2, '0')}`
              const findIndex = this.hoursPickUp.findIndex(item => item.time === time)
              // Si on trouve la même heure, on désactive l'heure
              if (findIndex !== -1) {
                // Si la commande est différente de la commande en édition on désactive
                if (this.submitForm.id !== currentItem.id) this.hoursPickUp[findIndex].disabled = true
              }
            })
            // Hide loading
            this.timeLoading = false
          } else {
            // Création des heures disponible d'après les heures et l'intervalle
            this.hoursPickUp = this.createTime(true)
          }
        } catch (error) {
          console.log(error)
          // Hide loading
          this.timeLoading = false
        }
      },
      createTime (disabled) {
        const step = 60 / this.parameterStepHeure

        const hoursTotal = []
        for (let i = 0; i < this.parameterAllowHours.length; i += 1) {
            for (let j = 0; j < step; j += 1) {
                let mins = (60 - (j * this.parameterStepHeure))
                if (mins === 60) mins = '00'
                // Ajout d'un 0 si l'heure à seuelement 1 chiffre
                hoursTotal.push({
                    sort: parseInt(`${this.parameterAllowHours[i].toString().padStart(2, '0')}${mins.toString().padStart(2, '0')}`, 10),
                    disabled: disabled,
                    time: `${this.parameterAllowHours[i].toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`, // Ajout d'un 0 si l'heure à seulement 1 chiffre (padStart)
                })
            }
        }

        const hoursTotalSort = hoursTotal.sort((a, b) => a.sort - b.sort).map((item, index) => {
            item.id = index
            return item
        })

        return hoursTotalSort
      },
    },
}
