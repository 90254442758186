<template>
  <v-app-bar
    absolute
    color="transparent"
    flat
    height="85"
  >
    <v-container>
      <v-row align="center">
        <v-toolbar-title
          class="font-weight-light"
        >
          01Cell/Gestion de cellulaires en entreprise
        </v-toolbar-title>

        <v-spacer />

        <v-btn-toggle
          v-model="language"
          tile
          mandatory
          group
          class="hidden-sm-and-down"
          min-height="48"
        >
          <v-btn
            value="fr"
            @click="changeLanguage('fr')"
          >
            <v-icon
              left
              size="20"
              v-text="icon"
            />

            <span>{{ $t('French') }}</span>
          </v-btn>
          <v-btn
            value="en"
            @click="changeLanguage('en')"
          >
            <v-icon
              left
              size="20"
              v-text="icon"
            />

            <span>{{ $t('English') }}</span>
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
  import { localize } from 'vee-validate'
  export default {
    name: 'PagesCoreAppBar',

    data: () => ({
      icon: 'mdi-web',
      language: '',
    }),

    mounted () {
      this.language = this.$i18n.t('Language')
    },
    methods: {
      changeLanguage (lang) {
        // On change le language de Vuetify
        this.$vuetify.lang.current = lang
        // Vee-validate
        localize(lang)
        // RTL
        this.$vuetify.rtl = false
        // I18N
        this.$i18n.locale = lang
        // Local Storage
        localStorage.setItem('APP_LANG_KEY', lang)
        this.language = lang
      },
    },
  }
</script>
