<template>
  <div>
    <!-- On affiche pas la bar en production-->
    <v-btn
      v-if="env !== 'production'"
      :class="`disabledCursor ${env === 'development' ? 'envDev' : 'envStaging'}`"
      block
      :color="env === 'development' ? 'red' : 'yellow'"
    >
      {{ $t(`env.${env}`) }} {{ company }}
    </v-btn>

    <v-app-bar
      id="app-bar"
      :class="getClassAppBar"
      absolute
      app
      color="transparent"
      flat
      height="75"
    >
      <!-- <div>
        <span class="red--text">wwww {{ value }}    </span>
        <span class="red--text">Mobile {{ $vuetify.breakpoint.smAndDown }}</span>
      </div> -->
      <v-btn
        class="mr-3"
        elevation="1"
        fab
        small
        @click="$vuetify.breakpoint.smAndDown ? setDrawer(!theme.drawer) : displayDrawer(value)"
      >
        <v-icon v-if="value">
          mdi-view-quilt
        </v-icon>

        <v-icon v-else>
          mdi-dots-vertical
        </v-icon>
      </v-btn>

      <v-toolbar-title
        class="hidden-sm-and-down font-weight-light"
        v-text="tr"
      />

      <v-spacer />

      <div class="mx-3" />

      <!-- Jira-->
      <v-menu
        v-if="ifRoleAdminNiveau0"
        bottom
        left
        min-width="200"
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template #activator="{ on }">
          <span
            class="pointer"
            v-on="on"
          >
            <v-icon
              color="red"
              size="32"
            >
              mdi-face-agent
            </v-icon></span>
        </template>

        <!-- Support-->
        <v-list
          :tile="false"
          flat
          nav
        >
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <h3>Support</h3>
              </v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item
              class="pointer"
              @click="listTickets()"
            >
              <v-list-item-icon class="mr-3">
                <v-icon color="green">
                  mdi-format-list-bulleted
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ $t('jira.List') }}
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item
              class="pointer"
              @click="createTickets()"
            >
              <v-list-item-icon class="mr-3">
                <v-icon color="red">
                  mdi-format-list-group-plus
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ $t('jira.Create') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list>
      </v-menu>

      <div class="mx-3" />

      <component
        :is="currentCompany"
        ref="logout"
      />
    </v-app-bar>
  </div>
</template>

<script>
  // Components
  import LogoutMsal from '@/components/Logout/LogoutMsal'
  import LogoutOidc from '@/components/Logout/LogoutOidc'

  // Utilities
  import { mapState, mapMutations } from 'vuex'
  import { EventBus } from '@/utils/event-bus'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      LogoutMsal,
      LogoutOidc,
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      env: process.env.NODE_ENV,
      company: process.env.VUE_APP_COMPANY,
      jiraUrlListTickets: 'https://01solutions-team.atlassian.net/servicedesk/customer/user/requests?page=1&reporter=all', // List tout les projets
      jiraUrlCreateTicket: 'https://01solutions-team.atlassian.net/servicedesk/customer/portal/36/group/38/create/129', // Prod par défaut (Jira projet CEL)
    }),

    computed: {
      ...mapState(['theme', 'userInfo']),
      tr () {
        if (this.$route.meta.translate) {
          return this.$t(`${this.$route.meta.translate}`)
        } else {
          return this.$t(`${this.$route.name}`)
        }
      },
      currentCompany () {
        if (process.env.VUE_APP_AUTHENTIFICATION === 'msal') return LogoutMsal
        return LogoutOidc
      },
      getClassAppBar () {
        if (this.env === 'production') return ''
        return 'mt-7'
      },
      ifRoleAdminNiveau0 () {
        // Si demo on affiche
        if (this.$company === 'demo') {
          return true
        } else {
          // Si prod, on affiche pour les rôles 'admin' et 'niveau0'
          if (process.env.NODE_ENV === 'production') {
            // Rôles 'admin' et 'niveau0'
            return this.userInfo.info.roles.some(role => role === 'admin' || role === 'niveau0')
          } else {
            return false
          }
        }
      },
    },

    async mounted () {
      if (this.env === 'development') {
        this.jiraUrlCreateTicket = 'https://01solutions-team.atlassian.net/servicedesk/customer/portal/72/group/82/create/162' // Jira projet CELDEV
      } else if (this.env === 'staging') {
        this.jiraUrlCreateTicket = 'https://01solutions-team.atlassian.net/servicedesk/customer/portal/73/group/83/create/163' // Jira projet CELDEV
      }
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      displayDrawer (value) {
        // expandOnHover
        this.$emit('input', !value)
        // Display bar
        EventBus.$emit('display-sidebar')
      },
      listTickets () {
        if (this.env === 'development') {}
        window.open(this.jiraUrlListTickets, '_blank')
      },
      createTickets () {
        window.open(this.jiraUrlCreateTicket, '_blank')
      },
    },
  }
</script>

<style scoped>
  .disabledCursor {
    cursor: not-allowed !important;
  }

  .envStaging {
    color: rgb(0, 0, 0) !important;
    background-color: #fbff00 !important;
  }

  .envDev {
    color: rgb(255, 255, 255) !important;
    background-color: #ff0404 !important;
  }

  .pointer {
    cursor: pointer;
  }
</style>
