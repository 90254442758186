<script>
  import { Line } from 'vue-chartjs'

  export default {
    extends: Line,
    props: {
      data: Object,
    },
    mounted () {
      this.renderChart(
        {
          labels: this.data.labels,
          datasets: this.data.datasets,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          // title: {
          //   display: true,
          //   text: 'My Data',
          // },
        },
      )
    },
  }
</script>
