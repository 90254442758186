export const MyOrders = {
    data () {
        return {
          status: [
              {
                item: this.$i18n.t('order.status.Open'),
                valeur: 1,
              },
              {
                item: this.$i18n.t('order.status.Confirmation_request'),
                valeur: 2,
              },
              {
                item: this.$i18n.t('order.status.Confirmed'),
                valeur: 3,
              },
              {
                item: this.$i18n.t('order.status.Migrated_Activated'),
                valeur: 4,
              },
              {
                item: this.$i18n.t('order.status.Delivered_Completed'),
                valeur: 5,
              },
          ],
        }
    },

    computed: {
      getOrderCodeName () {
        if (this.$company === 'uqam' || this.$company === 'demo') return 'UBR'
        else return this.$i18n.t('order.Payable_billing_code')
      },
    },

    methods: {
      getNextStatutStep (item) {
        if (item.type === 1) {
          if (!item.livraison) return { statut: 3, etape: 1 }
          if (item.livraison) return { statut: 3, etape: 5 }
        }
        if (item.type === 2) {
          if (item.migration_type === 1) return { statut: 2, etape: 2 }
          if (item.migration_type === 2) return { statut: 3, etape: item.livraison ? 6 : 2 }
          if (item.migration_type === 3) return { statut: 3, etape: item.livraison ? 6 : 2 }
        }
        if (item.type === 3) {
          if (item.type_equipement === 1) return { statut: 3, etape: 1 }
          if (item.type_equipement === 2) {
            // Si livraison
            if (item.livraison) return { statut: 3, etape: 1 }
            // Pas de livraison
            if (!item.livraison) return { statut: 3, etape: 3 }
          }
        }
      },
    },
}
