<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="billingError"
    >
      <!--  Action-->
      <template
        slot="item.action"
        slot-scope="props"
      >
        <!--  Si la variable 'action' est défini, on affiche le bouton-->
        <v-btn
          v-if="showPaybill(props.item)"
          small
          color="green"
          depressed
          @click="payBill(props.item.id)"
        >
          {{ $t('tools.billing.Pay_bill') }}
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>

  // Services
  import FacturationsService from '@/services/01Cell/FacturationsService'
  // Other
  import { mapGetters } from 'vuex'

  export default {
    name: 'ToolsBillingManagementErrorComponent',
    props: {
      billingError: Array,
    },
    data () {
      return {
        tableHeaders: [
          { text: this.$i18n.t('tools.billing.Period'), value: 'periode', sortable: true, class: 'text-h4 primary--text' },
          { text: 'ID', value: 'id', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Client', value: 'clientDisplayName', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Cellular'), value: 'Contrat.no', sortable: true, class: 'text-h4 primary--text' },
          { text: '$ Total', value: 'montant_total', sortable: false, class: 'text-h4 primary--text' },
          { text: 'Message', value: 'paiement_message', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Action', value: 'action', sortable: false, align: 'center', class: 'text-h4 primary--text' },
        ],
      }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterModePaiement: 'getModePaiement',
      }),
    },

    methods: {
      showPaybill (facture) {
        if (this.parameterModePaiement === '1') return false
        if (this.parameterModePaiement === '2') return true

        if (this.parameterModePaiement === '3') {
          if (facture.InscriptionPaiementId) return true
        }

        return false
      },
      async payBill (id) {
        // Start Spinner
        this.$loading.show()
        try {
          await FacturationsService.payerFactureManuelleToken(id)
          // On update le erreur de facturation
          this.$emit('update-billing-error')
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
