<template>
  <router-view />
</template>

<script>
  export default {
    name: 'PagesHome',
    created () {
      this.$vuetify.theme.dark = true
    },

    beforeDestroy () {
      this.$vuetify.theme.dark = false
    },
  }
</script>
