// export default ({ app, store }, inject) => {
//   inject('notifier', {
//     showMessage ({ content = '', color = '' }) {
//       store.commit('snackbar/showMessage', { content, color })
//     },
//   })
// }
import store from '@/store/index'

// const snackbar = {
//      install (Vue, options) { // now your plugin depend on store
//     var notifier = {
//         show (data) {
//             store.commit('snackbar/showMessage', data)
//         },
//         hide (data) {
//             store.commit('snackbar/hideMessage', data)
//         },
//     }

//     Vue.prototype.$notifier = notifier
//         // Vue.prototype.$notifier = (data) => {
//         //   store.commit('snackbar/showMessage', data)
//         // }
//      },
// }

const snackbar = {
        show (data) {
            store.commit('snackbar/showMessage', data)
        },
        hide (data) {
            store.commit('snackbar/hideMessage')
        },
    }

export default snackbar
