import { mapState } from 'vuex'
export const roles = {
    computed: {
        ...mapState({
            userRoles: state => state.userInfo.info.roles,
        }),
        isRoleAdmin () {
            // Check if role is 'admin'
            return this.userRoles.some(role => role === 'admin')
        },
        isRoleAdminNiveau0 () {
            // Check if role is 'admin'
            return this.userRoles.some(role => role === 'admin' || role === 'niveau0')
        },
    },
}
