import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/inscription_paiements')
    },
    save (paiement) { // Création d'une nouvelle entrée
        return api().post('/inscription_paiements', paiement)
    },
    view (paiementId) { // Voir une entrée spécifique
        return api().get(`/inscription_paiements/${paiementId}`)
    },
    update (paiement, paiementId) { // Mise à jour d'une entrée spécifique
        return api().put(`/inscription_paiements/${paiementId}`, paiement)
    },
    remove (paiementId) { // Suppression d'une entrée spécifique
        return api().delete(`/inscription_paiements/${paiementId}`)
    },
    query (item, filter) { // Recherche spécifique
        return api().get(`/inscription_paiements/query/${encodeURIComponent(item)}?champ=${filter}`)
    },
    queryAll (item, filter) { // Recherche spécifique
        return api().get(`/inscription_paiements/queryAll/${encodeURIComponent(item)}?champ=${filter}`)
    },
}
