import axios from 'axios'
import { msalInstance, loginRequest } from '@/config/msal'
import store from '@/store/index'

export const files = {
    methods: {
        async downloadFileFromS3 (key, bucket, fileName) {
            try {
                const params = {
                  bucket: bucket,
                  key: key,
                  expires: 60,
                }

                let token
                if (process.env.VUE_APP_AUTHENTIFICATION === 'msal') {
                  // Récupération sur le client
                  const accounts = msalInstance.getAllAccounts()
                  // Récupération du token
                  const responseToken = await msalInstance.acquireTokenSilent({
                    scopes: loginRequest.scopes,
                    accounts,
                  })
                  token = responseToken.accessToken
                } else if (process.env.VUE_APP_AUTHENTIFICATION === 'oidc') {
                  token = store.state.oidcStore.access_token
                }

                // On regarde si l'utilisateur à des photos
                const response = await axios.post(`${process.env.VUE_APP_SERVER_ADDRESS}${process.env.VUE_APP_SERVER_ADDRESS_PATH}/aws-s3/getSignedUrl`, params, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })

                const awsS3Response = await axios.get(response.data, { responseType: 'blob' })
                const blob = new Blob([awsS3Response.data])
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = fileName
                link.click()
                URL.revokeObjectURL(link.href)
            } catch (error) {
                console.log(error)
            }
        },
    },
}
