<template>
  <v-container
    id="operation-tools"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-hammer-screwdriver"
      :title="`${$t('Tool').toUpperCase()}S`"
      class="px-5 py-3"
    >
      <!-- Bouton refresh-->
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-icon
            class="icon mt-3 ml-2 pointer"
            color="grey lighten-1"
            x-large
            v-bind="attrs"
            v-on="on"
            @click="refresh"
          >
            mdi-reload
          </v-icon>
        </template>
        <span>{{ $t('Reload') }}</span>
      </v-tooltip>

      <v-col
        cols="12"
        sm="12"
      >
        <v-expansion-panels
          v-model="openedPanel"
          multiple
          accordion
        >
          <!--  Transférer les nouveaux fichiers et factures sur le serveur 01cell-->
          <v-expansion-panel
            value="0"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="info"
                  x-large
                >
                  mdi-upload
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.transfert_files').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tools-transfert-files @update-billing-management="updateBillingManagement" />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!--  Gestion de la facturation-->
          <v-expansion-panel
            value="1"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="info"
                  x-large
                >
                  mdi-logout
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Billing_management').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tools-billing-management
                v-if="ifBillingManagementIsOpen"
                ref="billingManagement"
                @update-billing-error="updateBillingError"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!--  Paiements en erreur-->
          <v-expansion-panel
            value="2"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="error"
                  x-large
                >
                  mdi-alert-octagon
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Payment_in_error').toUpperCase() }}
                <!-- Îcone pour facture en erreur-->
                <v-badge
                  v-if="billingError.length"
                  color="red"
                  :content="billingError.length"
                  offset-x="25"
                  offset-y="15"
                >
                  <v-icon
                    class="ml-1"
                    left
                    dark
                    large
                    color="yellow accent-3"
                  >
                    mdi-alert
                  </v-icon>
                </v-badge>
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tools-billing-management-error
                ref="billingManagementError"
                :billing-error="billingError"
                @update-billing-error="updateBillingError"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!--  Informer les clients mauvais payeurs (Si il y a mode de paiement manuel)-->
          <v-expansion-panel
            v-if="parameterModePaiement === '1' || parameterModePaiement === '3'"
            value="3"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="warning"
                  x-large
                >
                  mdi-email
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Inform_bad_paying_customers').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tools-mauvais-payeur
                v-if="ifBillingMauvaisPayeurIsOpen"
                ref="mauvaisPayeur"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!--  Télécharger dans un fichier les données d'un table-->
          <!-- <v-expansion-panel
            value="4"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="info"
                  x-large
                >
                  mdi-cloud-download
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Download_the_data').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel> -->

          <!--  Corriger un paiement-->
          <!-- <v-expansion-panel
            value="5"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="info"
                  x-large
                >
                  mdi-cog-refresh-outline
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Correct_payment').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </v-expansion-panel-content>
          </v-expansion-panel> -->

          <!--  Envoyé le message-25 à la clientèle-->
          <v-expansion-panel
            value="4"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="secondary"
                  x-large
                >
                  mdi-email
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.Sent_message_customers').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tools-send-email
                v-if="ifBillingSendEmailIsOpen"
                ref="sendEmail"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!--  Info sur un employé-->
          <v-expansion-panel
            v-if="$company === 'uqam'"
            value="6"
          >
            <v-expansion-panel-header>
              <template #actions>
                <v-icon
                  class="icon"
                  color="info"
                  x-large
                >
                  mdi-account-search
                </v-icon>
              </template>
              <h3 class="header ml-5 font-weight-light">
                {{ $t('tools.User_info').toUpperCase() }}
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <tools-info-utilisateur
                ref="infoUtilisateur"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </base-material-card>
  </v-container>
</template>

<script>
  // Components
  import ToolsTransfertFiles from '@/components/Tools/ToolsTransfertFiles'
  import ToolsBillingManagement from '@/components/Tools/ToolsBillingManagement'
  import ToolsBillingManagementError from '@/components/Tools/ToolsBillingManagementError'
  import ToolsMauvaisPayeur from '@/components/Tools/ToolsMauvaisPayeur'
  import ToolsInfoUtilisateur from '@/components/Tools/ToolsInfoUtilisateur'
  import ToolsSendEmail from '@/components/Tools/ToolsSendEmail'
  // Services
  import FacturesService from '@/services/01Cell/FacturesService'
  // Other
  import { mapGetters } from 'vuex'

  export default {
    name: 'OperationTools',
    components: {
      ToolsTransfertFiles,
      ToolsBillingManagement,
      ToolsBillingManagementError,
      ToolsMauvaisPayeur,
      ToolsInfoUtilisateur,
      ToolsSendEmail,
    },
    data () {
      return {
        expanded: [],
        openedPanel: [],
        billingError: [],
      }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterModePaiement: 'getModePaiement',
      }),
      ifBillingManagementIsOpen () {
        return this.openedPanel.find(item => item === 1)
      },
      ifBillingMauvaisPayeurIsOpen () {
        return this.openedPanel.find(item => item === 3)
      },
      ifBillingSendEmailIsOpen () {
        return this.openedPanel.find(item => item === 4)
      },

    },

    async mounted () {
      // Start Spinner
      this.$loading.show()
      this.getBillingError()
    },

    methods: {
      updateBillingManagement () {
        // Viens du composant 'ToolsTransfertFiles'
        // Aprés le tranfert si la fenêtre pour la 'Gestion de la facturation' est ouverte, on va chercher les informations pour la facturation
        if (this.$refs.billingManagement) this.$refs.billingManagement.getSommaireFacturation()
      },
      async getBillingError () {
        try {
          const billingError = await FacturesService.paiementErreur()
          this.billingError = billingError.map(item => {
            item.clientDisplayName = `${item.Client.nom}, ${item.Client.prenom}`
            return item
          })
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      updateBillingError () {
        this.getBillingError()
      },
      refresh () {
        // Start Spinner
        this.$loading.show()
        this.updateBillingManagement()
        this.getBillingError()
        if (this.$refs.mauvaisPayeur) this.$refs.mauvaisPayeur.getData()
        if (this.$refs.sendEmail) this.$refs.sendEmail.getData()
      },
    },
  }
</script>

<style scoped>
  .icon {
    order: 0;
  }

  .header {
    order: 1;
  }

  .pointer {
        cursor: pointer;
    }
</style>
