<template>
  <validation-observer
    ref="obs"
    v-slot="{ invalid, handleSubmit }"
  >
    <form>
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-if="validationButon"
            v-slot="{ errors, valid }"
            :name="$t('order.operation.Bill_number')"
            rules="required"
          >
            <!-- Numéro de la commande chez le fournisseur-->
            <v-text-field
              v-model="submitForm.fournisseur_no_facture"
              :label="$t('order.operation.Bill_number')"
              :error-messages="errors"
              :success="valid"
              required
            />
          </validation-provider>

          <!-- Tranfert du fichier-->
          <div
            v-if="filesTransfert"
            class="text-center"
          >
            <span>{{ $t('order.swal.text.You_have_received_the_order') }}</span>
            <transfert-files
              v-if="filesTransfert"
              :files-transfert-info="filesTransfertInfo"
              @update-factures="updateFactures"
            />
          </div>
        </v-col>
      </v-row>
    </form>
    <!-- Submit-->
    <template v-if="validationButon">
      <v-btn
        :disabled="invalid"
        class="mt-3"
        color="success"
        @click="handleSubmit(submit)"
      >
        {{ $t('Validate') }}
      </v-btn>
    </template>
  </validation-observer>
</template>
<script>
// Components
  import TransfertFiles from '@/components/Files/TransfertFiles'
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  import FacturesService from '@/services/01Cell/FacturesService'
  // Other
  import { mapGetters } from 'vuex'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { EventBus } from '@/utils/event-bus'

  export default {
    name: 'ClientCommandeDemoUqamDialogMissingNoOrder',
    components: {
      ValidationProvider,
      ValidationObserver,
      TransfertFiles,
    },
    data () {
      return {
        submitForm: {},
        validationButon: true,
        filesTransfert: false,
        filesTransfertInfo: {
          bucket: 'cell-facturation', // pas besoin du nom de la compagnie ni de l'environnement. Fait dans le Backend. EX: cell-facturation-demo-dev
          key: '',
          fileName: null, // Si on veut donner un nom spécifique au fichier
        },
      }
    },

    computed: {
      ...mapGetters('parameters', {
        nomClient: 'getNomClient',
      }),
    },

    methods: {
      getData (data) {
        this.submitForm = data
        // On regarde si il y a déjà un numéro de facture du fournisseur
        if (this.submitForm.FactureId) {
          // Si il y a un facture, on affiche pas le bouton pour ajouter le numéro de facture car il est déjà là
          this.validationButon = false
          // On crée le chemin pour télécharger le fichier
          this.filesTransfertInfo.key = `clients/${this.submitForm.ClientId}/factures/${this.submitForm.FactureId}`
          // On mets un nom spécifique au fichier. Dans ce cas c'est le numéro de facture
          this.filesTransfertInfo.fileName = `${this.submitForm.fournisseur_no_facture}.pdf`
          // On affiche le transfert de fichier
          this.filesTransfert = true
        } else {
          this.validationButon = true
          this.filesTransfert = false
        }
      },
      async updateFactures () {
        // Close Dialog
        this.$emit('close-dialog')
        // Start Spinner
        this.$loading.show()
        try {
          let statut
          let etape
          if (this.submitForm.type === 1) {
            // Pas de no facture
          } else if (this.submitForm.type === 2) {
            // Pas de no facture
          } else if (this.submitForm.type === 3) {
            statut = 8
            etape = 1
            // statut = this.submitForm.livraison ? 8 : 3
            // etape = this.submitForm.livraison ? 1 : 3
          }
          const commande = {
            statut,
            etape,
          }

          // Changement de statut

          const response = await CommandesService.update(commande, this.submitForm.id)
          // On update la liste dans le composant 'OperationCommande.vue'
          EventBus.$emit('update-commande', response)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async submit () {
        // Start Spinner
        this.$loading.show()
        try {
          // ===============================
          // Facturation
          // ===============================
          // Il faut créer une facture autre
          const facture = {
            ClientId: this.submitForm.ClientId,
            statut: 1,
            type: this.submitForm.mode_paiement,
            categorie: 3,
            fournisseur: this.nomClient,
            montant_total: this.submitForm.cout_facture,
            no_reference: this.submitForm.fournisseur_no_facture,
            montant_fournisseur_at: this.submitForm.cout_facture,
            montant_at: this.submitForm.cout_facture,
            code_facturation: this.submitForm.code_facturation,
            description: this.submitForm.description_equipement,
            actif: true,
          }

          // Upload du pdf de la facture
          const nouvelleFacture = await FacturesService.save(facture)

          // ===============================
          // Facturation
          // ===============================
          // On mets le numéro de facture dans la commande
          const commande = {
            FactureId: nouvelleFacture.id,
            fournisseur_no_facture: this.submitForm.fournisseur_no_facture,
          }

          const response = await CommandesService.update(commande, this.submitForm.id)
          // On update la liste dans le composant 'OperationCommande.vue'
          EventBus.$emit('update-commande', response)

          // On crée le chemin pour télécharger le fichier
          this.filesTransfertInfo.key = `clients/${this.submitForm.ClientId}/factures/${nouvelleFacture.id}`
          // On mets un nom spécifique au fichier. Dans ce cas c'est le numéro de facture
          this.filesTransfertInfo.fileName = `${this.submitForm.fournisseur_no_facture}.pdf`

          // On fait disparaître le bouton pour ajouter le numéro de facture dans la commande
          this.validationButon = false
          // On ouvre le dialog pour transféré le pdf
          this.filesTransfert = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
