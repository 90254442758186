<script>
  import { Doughnut } from 'vue-chartjs'

  export default {
    extends: Doughnut,
    props: {
      data: Object,
    },
    mounted () {
      const plugin = (chart) => {
        if (this.data.textCenter.title) {
          const width = chart.chart.width
          const height = chart.chart.height
          const ctx = chart.chart.ctx

          ctx.textBaseline = 'middle'
          ctx.font = '30px em sans-serif'
          ctx.fillStyle = '#000000'
          ctx.fillText(this.data.textCenter.title, Math.round((width - ctx.measureText(this.data.textCenter.title).width) / 2), height / 2)
          ctx.font = '15px Fira San'
          ctx.fillStyle = '#000000'
          ctx.fillText(this.data.textCenter.text, Math.round((width - ctx.measureText(this.data.textCenter.text).width) / 2), (height / 2) + 30)
          // ctx.font = '12px Fira San'
          // ctx.fillStyle = '#88b358'
          // ctx.fillText('dasd', 180, 105)
          ctx.save()
        }
      }

      this.addPlugin({
        id: 'my-plugin',
        beforeDraw: plugin,
      })

      this.renderChart(
        {
          labels: this.data.labels,
          datasets: this.data.datasets,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: true },
          title: {
            display: this.data.title.display,
            text: this.data.title.text,
          },
        },
      )

      // Exemple https://codesandbox.io/s/93m1lpjrvr?file=/src/App.vue:32-44
      // this.gradient = this.$refs.canvas
      //   .getContext('2d')
      //   .createLinearGradient(0, 0, 0, 450)
      // this.gradient2 = this.$refs.canvas
      //   .getContext('2d')
      //   .createLinearGradient(0, 0, 0, 450)

      // this.gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)')
      // this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)')
      // this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0)')

      // this.gradient2.addColorStop(0, 'rgba(0, 231, 255, 0.9)')
      // this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)')
      // this.gradient2.addColorStop(1, 'rgba(0, 231, 255, 0)')
      // this.renderChart(
      //   {
      //     labels: ['Books', 'Magazines', 'Newspapers'],
      //     datasets: [
      //       {
      //         backgroundColor: [this.gradient, this.gradient2, '#00D8FF'],
      //         data: [40, 20, 10],
      //       },
      //     ],
      //   },
      //   { responsive: true, maintainAspectRatio: false },
      // )
    },
  }
</script>
