import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/parametres')
    },
    save (parameter) { // Création d'une nouvelle entrée
        return api().post('/parametres', parameter)
    },
    view (parameterId) { // Voir une entrée spécifique
        return api().get(`/parametres/${parameterId}`)
    },
    update (parameter, parameterId) { // Mise à jour d'une entrée spécifique
        return api().put(`/parametres/${parameterId}`, parameter)
    },
    remove (parameterId) { // Suppression d'une entrée spécifique
        return api().delete(`/parametres/${parameterId}`)
    },
    multiple (parameters) { // Liste plusieurs paramètres
        return api().post('/parametres/multiple', parameters)
    },
    listAll () { // Liste tout mais sans tous les attribut
        return api().get('/parametres/listAll')
    },
}
