import api from '@/services/Api'

export default {
    view () { // Voir une entrée spécifique
        return api().get('/report')
    },
    viewResponsable () {
        return api().get('/report/responsable')
    },
    clientFacture (annee, contratId) {
        return api().get(`/report/clientFacture/${annee}/${contratId}`)
    },
    responsableUbrFacture (periode, value, ubrOrgroup) {
        return api().get(`/report/responsableUbrFacture/${periode}/${value}/${ubrOrgroup}`)
    },
}
