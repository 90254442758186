<template>
  <v-row>
    <v-col cols="12">
      <v-client-table
        ref="table"
        class="pointer"
        :data="dataTransactions.data"
        :columns="table.columns"
        :options="table.options"
        @row-click="onRowClick"
      >
        <!--  Action-->
        <template
          slot="action"
          slot-scope="props"
        >
          <!--  Refund-->
          <v-btn
            :disabled="disabledPaymentStatus(props.row.paiement_statut)"
            class="ml-2 non-clickable"
            fab
            light
            x-small
            :title="$t('Refund')"
            color="green"
          >
            <v-icon>mdi-credit-card-refund</v-icon>
          </v-btn>
        </template>

        <!--  Amount-->
        <template
          slot="amountToFixed"
          slot-scope="props"
        >
          {{ props.row.amountToFixed }} {{ props.row.currency.toUpperCase() }}
        </template>

        <!--  Paiement status-->
        <template
          slot="paiement_statut"
          slot-scope="props"
        >
          <v-btn
            :color="getTransactionColor(props.row.paiement_statut)"
            class="ma-2 white--text"
            small
          >
            {{ props.row.paiement_statut ? $t(`Payment.transaction_status.${props.row.paiement_statut}`) : $t('Payment.No_transaction') }}
            <v-icon
              right
              dark
            >
              {{ getTransactionIcon(props.row.paiement_statut) }}
            </v-icon>
          </v-btn>
        </template>

        <!-- Mode de paiement -->
        <template
          slot="payment_method_details.type"
          slot-scope="props"
        >
          <!-- Type carte -->
          <template v-if="props.row.payment_method_details.type === 'card'">
            <v-row align="center">
              <v-col
                class="d-flex align-center"
                cols="auto"
              >
                <v-img
                  :src="require(`@/assets/card/${props.row.payment_method_details.card.brand}.png`)"
                  :aspect-ratio="16/9"
                  width="45"
                />
              </v-col>
              <v-col
                class="d-flex align-center"
                cols="auto"
              >
                <span>.... {{ props.row.payment_method_details.card.last4 }}</span>
              </v-col>
            </v-row>
          </template>
        </template>

        <!-- No de facture -->
        <template
          slot="metadata.factureId"
          slot-scope="props"
        >
          <a
            href="#"
            @click.prevent="openDialogPaiementFacture(props.row.metadata.factureId)"
          >{{ props.row.metadata.factureId }}</a>
        </template>

        <!-- Created -->
        <template
          slot="created"
          slot-scope="props"
        >
          {{ getDateWithHour(props.row.created) }}
        </template>
      </v-client-table>
    </v-col>

    <!-- Load more -->
    <v-col
      v-if="dataTransactions.data.length"
      cols="12"
      class="text-center"
    >
      <div>
        <v-btn
          :disabled="!dataTransactions.has_more"
          color="primary"
          @click="loadMore"
        >
          {{ $t('Load_more') }}
        </v-btn>
      </div>
    </v-col>

    <!-- Remboursement -->
    <v-dialog
      v-model="dialogRefund"
      max-width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogRefund = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-credit-card-refund
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ $t('Refund') }}</h3>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <facture-refund-dialog
          ref="factureRefundDialog"
          @close-dialog="closeDialog"
        />
      </v-card>
    </v-dialog>

    <!--  Dialog reçu-->
    <v-dialog
      v-model="dialogPaiement"
      max-width="1000"
    >
      <v-card>
        <!--  Print-->
        <v-icon
          class="mt-5"
          right
          dark
          large
          color="primary"
          @click="print"
        >
          mdi-printer
        </v-icon>
        <v-card-title>
          <v-icon
            aria-label="Close"
            @click="dialogPaiement = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text class="text-body-1 text-center">
          <v-row>
            <v-col
              cols="12"
            >
              <div :id="printMe">
                <div v-html="recuFormat" />
              </div>
            </v-col>
          </v-row>

          <v-btn
            class="mt-2 ml-4"
            color="info"
            depressed
            default
            rounded
            @click="dialogPaiement = false"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  Dialog Paiement Intent-->
    <v-dialog
      v-model="dialogPaiementIntent"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          height="100"
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogPaiementIntent = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-credit-card
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h2>{{ $t('Payment.Name') }}</h2>
                </v-list-item-title>

                <v-list-item-title v-if="paiementInfo.lastCharge">
                  <h2>
                    {{ paiementInfo.lastCharge.amountToFixed }}

                    <v-btn
                      :color="getTransactionColor(paiementInfo.lastCharge.paiement_statut)"
                      class="ma-2 white--text"
                      small
                    >
                      {{ paiementInfo.lastCharge.paiement_statut ? $t(`Payment.transaction_status.${paiementInfo.lastCharge.paiement_statut}`) : $t('Payment.No_transaction') }}
                      <v-icon
                        right
                        dark
                      >
                        {{ getTransactionIcon(paiementInfo.lastCharge.paiement_statut) }}
                      </v-icon>
                    </v-btn>
                  </h2>
                </v-list-item-title>

                <v-list-item-title v-if="paiementInfo.customer">
                  <h3>
                    {{ $t('Payment.Charged_to') }} {{ paiementInfo.customer.name }}
                  </h3>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-container
          id="payments-list"
          fluid
          tag="section"
        >
          <v-row>
            <v-col cols="9">
              <!-- TIMELINE -->
              <h1>{{ $t('Timeline') }}</h1>
              <hr>
              <v-col cols="9">
                <v-timeline
                  align-top
                  dense
                >
                  <v-timeline-item
                    v-for="(timeline, i) in timelineTransactions"
                    :key="i"
                    :color="timeline.timeline.color"
                    :icon="timeline.timeline.icon"
                    fill-dot
                  >
                    <v-card class="pa-1">
                      <h3
                        class="text-subtitle-1 ml-2"
                        v-html="timeline.timeline.text"
                      />

                      <p
                        class="font-weight-thin ml-2"
                        v-text="timeline.timeline.subtext"
                      />
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-col>

              <!-- RÉSUMÉ DU PAIEMENT -->
              <template v-if="paiementInfo.lastCharge">
                <h1>{{ $t('Payment.Checkout_summary') }}</h1>
                <hr>
                <v-col cols="10">
                  <v-expansion-panels>
                    <!-- Paiement -->
                    <v-expansion-panel
                      disabled
                    >
                      <v-expansion-panel-header>
                        <span class="black--text text-h4">
                          {{ $t('Payment.Payment_amount') }}
                        </span>
                        <span
                          v-if="paiementInfo.lastCharge.status === 'succeeded'"
                          class="black--text text-h4 text-right mr-5"
                        >${{ (paiementInfo.lastCharge.amount / 100).toFixed(2) }}</span>

                        <span
                          v-else
                          class="black--text text-h4 text-right mr-5"
                        ><del>${{ (paiementInfo.lastCharge.amount / 100).toFixed(2) }}</del></span>
                      </v-expansion-panel-header>
                    </v-expansion-panel>

                    <!-- Fees -->
                    <!-- 'isRouteProfile' on montre pas le frais dans le profile-->
                    <template v-if="isRouteProfile">
                      <!-- Si transaction balance -->
                      <template v-if="paiementInfo.balanceTransaction">
                        <v-expansion-panel
                          v-for="(item,i) in paiementInfo.balanceTransaction.fee_details"
                          :key="i"
                          disabled
                        >
                          <v-expansion-panel-header>
                            <span class="black--text text-h4">
                              {{ item.type === 'stripe_fee' ? $t('Payment.Stripe_fees') : `${item.description}` }}
                            </span>
                            <span
                              class="black--text text-h4 text-right mr-5"
                            >$-{{ (item.amount / 100).toFixed(2) }}</span>
                          </v-expansion-panel-header>
                        </v-expansion-panel>
                      </template>

                      <!-- Si pas transaction balance -->
                      <template v-else>
                        <v-expansion-panel
                          disabled
                        >
                          <v-expansion-panel-header>
                            <span class="black--text text-h4">
                              {{ $t('Payment.Fees') }}
                            </span>
                            <span
                              class="black--text text-h4 text-right mr-5"
                            >$0.00</span>
                          </v-expansion-panel-header>
                        </v-expansion-panel>
                      </template>
                    </template>

                    <!-- Refund -->
                    <v-expansion-panel v-if="paiementInfo.refunds.length">
                      <v-expansion-panel-header>
                        <span class="black--text text-h4">
                          {{ $t('Payment.Refunded_amount') }}
                        </span>
                        <span class="black--text text-h4 text-right">$-{{ (paiementInfo.lastCharge.amount_refunded / 100).toFixed(2) }}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <!-- Refund -->
                        <v-expansion-panel
                          v-for="(item, i) in paiementInfo.refunds"
                          :key="i"
                          disabled
                        >
                          <v-expansion-panel-header class="ml-1">
                            <span class="black--text text-h4">
                              {{ $t('Refund') }}
                            </span>
                            <span class="black--text text-h4 text-right">$-{{ (item.amount / 100).toFixed(2) }}</span>
                          </v-expansion-panel-header>
                        </v-expansion-panel>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- Total Amount -->
                    <v-expansion-panel
                      disabled
                    >
                      <v-expansion-panel-header>
                        <span class="black--text text-h4">
                          {{ $t('Payment.Total_amount') }}
                        </span>
                        <!-- Si transaction balance -->
                        <template v-if="paiementInfo.balanceTransaction">
                          <!-- Si on est dans le profile, on affiche pas le montant total avec les frais (Stripe) -->
                          <span
                            v-if="isRouteProfile"
                            class="black--text text-h4 text-right mr-5"
                          >${{ ((paiementInfo.balanceTransaction.net - paiementInfo.lastCharge.amount_refunded) / 100).toFixed(2) }}
                          </span>

                          <!-- Si on est pas dans le profile, on affiche le montant total avec les frais (Stripe) -->
                          <span
                            v-else
                            class="black--text text-h4 text-right mr-5"
                          >${{ ((paiementInfo.lastCharge.amount - paiementInfo.lastCharge.amount_refunded) / 100).toFixed(2) }}
                          </span>
                        </template>

                        <!-- Si pas detransaction balance -->
                        <template v-else>
                          <span
                            class="black--text text-h4 text-right mr-5"
                          >$0.00</span>
                        </template>
                      </v-expansion-panel-header>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </template>

              <!-- MÉTHODE DE PAIMENT-->
              <template v-if="paiementInfo.paymentMethod">
                <h1>{{ $t('Payment.Payment_method') }}</h1>
                <hr>
                <v-row>
                  <!-- Type carte -->
                  <template v-if="paiementInfo.paymentMethod.type === 'card'">
                    <v-col
                      cols="2"
                      class="d-flex flex-column"
                    >
                      <span>Type</span>
                      <span class="mt-2">ID</span>
                      <span class="mt-2">{{ $t('Number') }}</span>
                      <span class="mt-2">{{ $t('Payment.Expires') }}</span>
                      <span class="mt-2">{{ $t('Payment.Brand') }}</span>
                      <span class="mt-2">{{ $t('Payment.CVC_check') }}</span>
                      <span class="mt-2">{{ $t('Payment.Street_check') }}</span>
                      <span class="mt-2">{{ $t('Payment.Zip_check') }}</span>
                    </v-col>
                    <v-col
                      cols="4"
                      class="d-flex flex-column"
                    >
                      <!-- Type -->
                      <span>{{ $t('Payment.method_type.card') }}</span>
                      <!-- ID -->
                      <span class="mt-2">{{ paiementInfo.paymentMethod.id }}</span>
                      <!-- Numéro -->
                      <span class="mt-2">....{{ paiementInfo.paymentMethod.card.last4 }}</span>
                      <!-- Expiration -->
                      <span class="mt-2">{{ paiementInfo.paymentMethod.card.exp_month.toString().padStart(2, '0') }}/{{ paiementInfo.paymentMethod.card.exp_year }}</span>
                      <!-- Marque -->
                      <span class="mt-2">{{ paiementInfo.paymentMethod.card.brand }}</span>
                      <!-- Vérification CVV -->
                      <span class="text--left mt-2">{{ $t(`Payment.Card_check.${paiementInfo.paymentMethod.card.checks.cvc_check}`) }}<v-icon
                        class="ml-1"
                        :color="paiementInfo.paymentMethod.card.checks.cvc_check === 'pass' ? 'success' : 'red'"
                      >
                        mdi-{{ paiementInfo.paymentMethod.card.checks.cvc_check === 'pass' ? 'check-circle-outline' : 'alpha-x-circle-outline' }}
                      </v-icon></span>
                      <!-- Vérification adress -->
                      <span class="text--left mt-2">{{ $t(`Payment.Card_check.${paiementInfo.paymentMethod.card.checks.address_line1_check}`) }}<v-icon
                        class="ml-1"
                        :color="paiementInfo.paymentMethod.card.checks.address_line1_check === 'pass' ? 'success' : 'red'"
                      >
                        mdi-{{ paiementInfo.paymentMethod.card.checks.address_line1_check === 'pass' ? 'check-circle-outline' : 'alpha-x-circle-outline' }}
                      </v-icon></span>
                      <!-- Vérification Code postal -->
                      <span class="text--left mt-2">{{ $t(`Payment.Card_check.${paiementInfo.paymentMethod.card.checks.address_postal_code_check}`) }}<v-icon
                        class="ml-1"
                        :color="paiementInfo.paymentMethod.card.checks.address_postal_code_check === 'pass' ? 'success' : 'red'"
                      >
                        mdi-{{ paiementInfo.paymentMethod.card.checks.address_postal_code_check === 'pass' ? 'check-circle-outline' : 'alpha-x-circle-outline' }}
                      </v-icon></span>
                    </v-col>
                  </template>

                  <!-- ID -->
                  <v-col
                    cols="2"
                    class="d-flex flex-column"
                  >
                    <span class="mt-2">{{ $t('Payment.Owner') }}</span>
                    <span class="mt-2">{{ $t('Payment.Owner') }} {{ $t('client.Email') }}</span>
                    <span class="mt-2">{{ $t('client.Address') }}</span>
                  </v-col>
                  <v-col
                    cols="4"
                    class="d-flex flex-column"
                  >
                    <!-- Owner -->
                    <span class="mt-2">{{ paiementInfo.paymentMethod.billing_details.name }}</span>
                    <!-- Owner -->
                    <span class="mt-2">{{ paiementInfo.paymentMethod.billing_details.email }}</span>
                    <!-- Addresse -->
                    <div
                      class="mt-2"
                      v-html="getAdress"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-col>

            <!-- INFORMATION -->
            <v-col
              cols="3"
            >
              <!-- DETAILS-->
              <template v-if="paiementInfo.paymentIntent">
                <h1>{{ $t('Details') }}</h1>
                <hr>
                <!-- Customer ID -->
                <div class="card-info mt-2">
                  <h3 class="font-weight m-0">
                    {{ $t('Payment.Payment_ID') }}
                  </h3>
                  <p class="font-weight-light my-1">
                    {{ paiementInfo.paymentIntent.id }}
                  </p>
                </div>

                <!-- Méthode de paiement -->
                <div class="card-info mt-2">
                  <h3 class="font-weight m-0">
                    {{ $t('Payment.Payment_method') }}
                  </h3>
                  <p class="font-weight-light my-1">
                    <v-row
                      v-if="paiementInfo.paymentMethod"
                      align="center"
                    >
                      <v-col
                        class="d-flex align-center"
                        cols="auto"
                      >
                        <v-img
                          :src="require(`@/assets/card/${paiementInfo.paymentMethod.card.brand}.png`)"
                          :aspect-ratio="16/9"
                          width="45"
                        />
                      </v-col>
                      <v-col
                        class="d-flex align-center"
                        cols="auto"
                      >
                        <span>.... {{ paiementInfo.paymentMethod.card.last4 }}</span>
                      </v-col>
                    </v-row>
                  </p>
                </div>
              </template>

              <!-- CLIENT INFORMATION -->
              <template v-if="paiementInfo.customer">
                <h1 class="mt-5">
                  {{ $t('Customer') }}
                </h1>
                <hr>
                <!-- Customer ID -->
                <div class="card-info mt-2">
                  <h3 class="font-weight m-0">
                    {{ $t('Payment.Customer_ID') }}
                  </h3>
                  <p class="font-weight-light my-1">
                    {{ paiementInfo.customer.id }}
                  </p>
                </div>

                <!-- Customer since -->
                <div class="card-info mt-2">
                  <h3 class="font-weight m-0">
                    {{ $t('Payment.Customer_since') }}
                  </h3>
                  <p class="font-weight-light my-1">
                    {{ getDateWithoutHour(paiementInfo.customer.created) }}
                  </p>
                </div>

                <!-- Billing emails -->
                <div class="card-info mt-2">
                  <h3 class="font-weight m-0">
                    {{ $t('client.Email') }}
                  </h3>
                  <p class="font-weight-light my-1">
                    {{ paiementInfo.customer.email }}
                  </p>
                </div>
              </template>

              <!-- METADATA -->
              <template v-if="paiementInfo.paymentIntent">
                <h1 class="mt-5">
                  {{ $t('Payment.Metadata') }}
                </h1>
                <hr>
                <!-- Customer ID -->
                <div class="card-info mt-2">
                  <h3 class="font-weight m-0">
                    {{ $t('Payment.Customer_ID') }} 01CELL
                  </h3>
                  <p class="font-weight-light my-1">
                    <a
                      href="#"
                      @click.prevent="getClientId(paiementInfo.paymentIntent.metadata.clientId)"
                    >{{ paiementInfo.paymentIntent.metadata.clientId }}</a>
                  </p>
                </div>

                <!-- Customer Username -->
                <div class="card-info mt-2">
                  <h3 class="font-weight m-0">
                    {{ $t('Username') }} 01CELL
                  </h3>
                  <p class="font-weight-light my-1">
                    {{ paiementInfo.paymentIntent.metadata.username }}
                  </p>
                </div>

                <!-- Periode de facturation -->
                <div class="card-info mt-2">
                  <h3 class="font-weight m-0">
                    {{ $t('tools.billing.Period') }} 01CELL
                  </h3>
                  <p class="font-weight-light my-1">
                    {{ paiementInfo.paymentIntent.metadata.periode }}
                  </p>
                </div>

                <!-- Total-->
                <div class="card-info mt-2">
                  <h3 class="font-weight m-0">
                    Total 01CELL
                  </h3>
                  <p class="font-weight-light my-1">
                    {{ paiementInfo.paymentIntent.metadata.total }}
                  </p>
                </div>

                <!-- Facture id-->
                <div class="card-info mt-2">
                  <h3 class="font-weight m-0">
                    {{ $t('contract.Bill') }} 01CELL
                  </h3>
                  <p class="font-weight-light my-1">
                    <a
                      href="#"
                      @click.prevent="openDialogPaiementFacture(paiementInfo.paymentIntent.metadata.factureId)"
                    >{{ paiementInfo.paymentIntent.metadata.factureId }}</a>
                  </p>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Edition du client -->
    <v-dialog
      v-model="dialogClient"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogClient = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-account
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <h3>Client</h3>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!--  Edition d'un client-->
        <div class="mt-5">
          <client-create-edit
            :create-edit-info="createEditInfo"
            :client-id="clientId"
            @create-update-client="createUpdateClient"
            @cancel-edit-client="cancelEditClient"
          />
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  // Component
  import FactureRefundDialog from '@/components/Client/Facture/FactureRefundDialog'
  import ClientCreateEdit from '@/components/Client/ClientCreateEdit'
  // Service
  import StripeChargeService from '@/services/STRIPE/StripeChargeService'
  import StripePaiementService from '@/services/STRIPE/StripePaiementService'
  import StripeRefundService from '@/services/STRIPE/StripeRefundService'
  import StripeCustomerService from '@/services/STRIPE/StripeCustomerService'
  import StripeBalanceTransactionsService from '@/services/STRIPE/StripeBalanceTransactionsService'
  import StripePaiementMethodeService from '@/services/STRIPE/StripePaiementMethodeService'
  import ClientsService from '@/services/01Cell/ClientsService'
  // Mixins
  import { Payment } from '@/mixins/Payment/Payment'
  import { FacturePaiement } from '@/mixins/Facture/FacturePaiement'

  export default {
    name: 'PaymentsListStripeComponent',
    components: {
      ClientCreateEdit,
      FactureRefundDialog,
    },
    mixins: [Payment, FacturePaiement],
    props: {
      dataTransactions: Object,
      perPage: Number,
    },
    data () {
      return {
        language: this.$i18n.t('Language'),
        dialogRefund: false,
        dialogPaiement: false,
        dialogPaiementIntent: false,
        dialogClient: false,
        clientId: null,
        createEditInfo: {
          icon: 'mdi-pencil',
          title: `${this.$i18n.t('Edit')}`,
        },
        paiementInfo: {
          paymentIntent: null,
          charges: null,
          lastCharge: null,
          refunds: null,
          paymentMethod: null,
          customer: null,
          balanceTransaction: null,
        },
        printMe: null,
        table: {
          columns: ['amountToFixed', 'paiement_statut', 'payment_method_details.type', 'customersFromDbDisplayName', 'metadata.factureId', 'metadata.periode', 'created', 'failure_message_translate'],
          options: {
            sortable: ['amountToFixed', 'paiement_statut', 'payment_method_details.type', 'customersFromDbDisplayName', 'metadata.factureId', 'metadata.periode', 'created', 'failure_message_translate'],
            filterByColumn: true,
            filterable: ['amountToFixed', 'payment_method_details.type', 'customersFromDbDisplayName', 'metadata.factureId', 'metadata.periode', 'created', 'failure_message_translate'],
            pagination: { chunk: 5 },
            perPage: this.perPage,
            perPageValues: [10, 25, 50, 100],
            showChildRowToggler: false, // Si il y a des child row, sert pour cacher la colonne + pour afficher le child row. On utilise la fonction 'tableRowClick' plus bas
            texts: {
              count: this.$i18n.t('vueTables.texts.Showing') + ' {from} ' + this.$i18n.t('vueTables.texts.to') + ' {to} ' + this.$i18n.t('vueTables.texts.of') + ' {count} ' + this.$i18n.t('vueTables.texts.Records').toLowerCase() + '|{count} ' + this.$i18n.t('vueTables.texts.Records').toLowerCase() + '|' + this.$i18n.t('vueTables.texts.One_record'),
              first: this.$i18n.t('vueTables.texts.First'),
              last: this.$i18n.t('vueTables.texts.Last'),
              filter: this.$i18n.t('vueTables.texts.Filter') + ':',
              filterPlaceholder: this.$i18n.t('vueTables.texts.Search_query'),
              limit: this.$i18n.t('vueTables.texts.Records'),
              page: 'Page:',
              noResults: this.$i18n.t('vueTables.texts.No_matching_records'),
              filterBy: this.$i18n.t('vueTables.texts.Filter_by') + ' {column}',
              loading: this.$i18n.t('vueTables.texts.Loading'),
              defaultOption: this.$i18n.t('vueTables.texts.Select') + ' {column}',
              columns: this.$i18n.t('vueTables.texts.Columns'),
            },
            headings: {
              amountToFixed: this.$i18n.t('Amount'),
              paiement_statut: 'Transaction',
              'payment_method_details.type': this.$i18n.t('order.Payment_mode'),
              customersFromDbDisplayName: this.$i18n.t('Customer'),
              failure_message_translate: this.$i18n.t('Payment.Decline'),
              'metadata.factureId': this.$i18n.t('contract.Bill'),
              'metadata.periode': this.$i18n.t('tools.billing.Period'),
              created: 'Date',
            },
            columnsClasses: {
              action: 'VueTables-width-action-column text-left', // Voir dans le fichier 'style.scss'
            },
            sortIcon: {
              is: 'fa-sort',
              base: 'fas',
              up: 'fa-sort-up',
              down: 'fa-sort-down',
            },
          },
        },
        timelineTransactions: [],
      }
    },

    computed: {
      isRouteProfile () {
        if (this.$route.name === 'payments-profile') return false
        return true
      },
      getAdress () {
        return `${this.paiementInfo.paymentMethod.billing_details.address.line1}<br>
        ${this.paiementInfo.paymentMethod.billing_details.address.city}, ${this.paiementInfo.paymentMethod.billing_details.address.state}, ${this.paiementInfo.paymentMethod.billing_details.address.postal_code}, ${this.paiementInfo.paymentMethod.billing_details.address.country}`
      },
    },

    created () {
      // Si la route est différente de 'payments-profile', on ajoute le bouton 'action' pour le remboursement
      if (this.$route.name !== 'payments-profile') {
        this.table.columns.unshift('action')
      }
    },

    methods: {
      loadMore (event) {
        this.$emit('load-more')
      },
      async getRefund (chargeId) {
        // Start Spinner
        this.$loading.show()
        try {
          const charge = await StripeChargeService.view(chargeId)
          const remaining = (charge.amount - charge.amount_refunded) / 100
          const submitRefund = { paiementId: charge.payment_intent, amount: remaining, montantTotal: charge.amount / 100, remaining: remaining, reason: null, factureId: charge.metadata.factureId }
          this.chargeId = charge.id
          this.dialogRefund = true

          this.$nextTick(() => {
            this.$refs.factureRefundDialog.resetSubmitForm(submitRefund)
          })
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      closeDialog () {
        this.dialogRefund = false
        this.$emit('update-list-transactions', this.chargeId)
      },
      onRowClick (item) {
        //  Pour le bouton action                Pour le facture (href)
        if (item.event.target.cellIndex !== 0 && item.event.target.nodeName !== 'A') {
          this.getPaymentInfo(item.row.payment_intent)
        }

        // Refund
        if (item.event.target.cellIndex === 0) {
          this.getRefund(item.row.id)
        }
      },
      async getPaymentInfo (paymentIntentId) {
        // Reset info
        this.paiementInfo.paymentIntent = null
        this.paiementInfo.charges = null
        this.paiementInfo.lastCharge = null
        this.paiementInfo.refunds = null
        this.paiementInfo.paymentMethod = null
        this.paiementInfo.customer = null
        this.paiementInfo.balanceTransaction = null

        // Start Spinner
        this.$loading.show()
        try {
          // Intention de paiement
          const paymentIntentPromise = StripePaiementService.view(paymentIntentId)
          // Remboursement
          const refundsPromise = StripeRefundService.queryByPaymentIntent(paymentIntentId)
          // Transactions
          const chargesPromise = StripeChargeService.queryByPaymentIntent(paymentIntentId)
          const [paymentIntent, refunds, charges] = await Promise.all([paymentIntentPromise, refundsPromise, chargesPromise])
          this.paiementInfo.paymentIntent = paymentIntent
          this.paiementInfo.refunds = refunds
          this.paiementInfo.charges = charges
          // La dernière transactions
          this.paiementInfo.lastCharge = charges.find(item => item.id === paymentIntent.latest_charge)
          // Balance transactions (frais Stripe)
          // Si il y a une balnace de transaction et que la route est différent de 'payments-profile'
          if (this.paiementInfo.lastCharge.balance_transaction) {
            StripeBalanceTransactionsService.view(this.paiementInfo.lastCharge.balance_transaction).then(response => {
              this.paiementInfo.balanceTransaction = response
            })
          }

          // Méthode de paiement
          const paymentMethodPromise = StripePaiementMethodeService.view(this.paiementInfo.lastCharge.payment_method)
          // Info sur le client
          const customerPromise = StripeCustomerService.view(paymentIntent.customer)
          const [paymentMethod, customer] = await Promise.all([paymentMethodPromise, customerPromise])
          this.paiementInfo.paymentMethod = paymentMethod
          this.paiementInfo.customer = customer
          this.dialogPaiementIntent = true
          this.getTimeLine()
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      getTimeLine () {
        // Remboursement
        this.paiementInfo.refunds = this.paiementInfo.refunds.map(item => {
          let text = 'A finir si autre type de paiement'
          if (item.status === 'succeeded') {
            text = `${this.$i18n.t('Payment.Timeline.Successfully_refunded')} $${(item.amount / 100).toFixed(2)} (${this.$i18n.t(`Payment.Refund_reason.${item.reason}`)})`
          } else {
            text = this.$i18n.t('Payment.Timeline.Failed_refund')
          }

          item.timeline = {
            icon: 'mdi-arrow-u-left-top',
            color: item.status === 'succeeded' ? 'success' : 'red',
            text,
            subtext: this.getDateWithHour(item.created),
          }
          return item
        })

        // Transactions
        this.paiementInfo.charges = this.paiementInfo.charges.map(item => {
          // Text
          let text = 'A finir'
          // Si paiement par carte de crédit
          if (item.payment_method_details.type === 'card') {
            text = item.status === 'succeeded' ? this.$i18n.t('Payment.Timeline.Payment_authorised') : `${this.$i18n.t('Payment.Timeline.Failed_payment_with')} ${item.payment_method_details.card.brand} ...${item.payment_method_details.card.last4} <br>${item.failure_message_translate}`
          }

          item.timeline = {
            icon: item.status === 'succeeded' ? 'mdi-bank-check' : 'mdi-bank-off',
            color: item.status === 'succeeded' ? 'success' : 'red',
            text,
            subtext: this.getDateWithHour(item.created),
          }
          return item
        })

        // On mets les remboursements et les transactions ensemble
        this.timelineTransactions = [...this.paiementInfo.refunds, ...this.paiementInfo.charges]

        this.timelineTransactions = this.timelineTransactions.sort((a, b) => a.created - b.created)

        // On mets l'intention de paiement
        const timelineLastPaymentIntent = {
          icon: 'mdi-clock-start',
          color: 'success',
          text: this.$i18n.t('Payment.Timeline.Payment_started'),
          subtext: this.getDateWithHour(this.paiementInfo.paymentIntent.created),
        }
        this.paiementInfo.paymentIntent.timeline = timelineLastPaymentIntent
        // En premier dans le array
        this.timelineTransactions.unshift(this.paiementInfo.paymentIntent)

        // On renverse l'ordre du array
        this.timelineTransactions.reverse()
      },
      getClientId (clientId) {
        this.clientId = parseInt(clientId, 10)
        this.dialogClient = true
      },
      async createUpdateClient (event) {
        // Start Spinner
        this.$loading.show()
        try {
          await ClientsService.update(event, event.id)

          // Montre la confirmation
          this.$notifier.show({ message: this.$i18n.t('client.snackBar.client_updated_successfully'), color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })

          // Close dialog
          this.dialogClient = false
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      cancelEditClient () {
        // Close dialog
        this.dialogClient = false
      },
    },
  }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
    .non-clickable {
      pointer-events: none; /* Désactive les clics */
      opacity: 1;          /* Conserve la couleur */
      cursor: not-allowed; /* Affiche un curseur désactivé */
    }
</style>
