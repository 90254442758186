var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{staticClass:"mb-5",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.table,"expanded":_vm.expanded,"item-key":"id","search":_vm.search,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50]
    },"items-per-page":50},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","dark":"","x-small":"","color":"white","title":_vm.$t('View')},on:{"click":function($event){return _vm.viewItem(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.getButtonIcon(item.id)))])],1)]}},{key:"item.categorie",fn:function(props){return [(props.item.categorie === 1)?_c('span',[_vm._v(_vm._s(_vm.$t('invoices.Monthly_invoices')))]):_vm._e(),(props.item.categorie === 2)?_c('span',[_vm._v(_vm._s(_vm.$t('invoices.Other_invoices')))]):_vm._e()]}},{key:"item.montant_at",fn:function(props){return [(props.item.montant_at !== null)?_c('span',[_vm._v(" "+_vm._s(props.item.montant_at.toFixed(2))+"$ ")]):_vm._e()]}},{key:"item.credit_applique",fn:function(props){return [(props.item.credit_applique !== null)?_c('span',[_vm._v(" "+_vm._s(props.item.credit_applique.toFixed(2))+"$ ")]):_vm._e()]}},{key:"item.tps",fn:function(props){return [(props.item.tps !== null)?_c('span',[_vm._v(" "+_vm._s(props.item.tps.toFixed(2))+"$ ")]):_vm._e()]}},{key:"item.tvq",fn:function(props){return [(props.item.tvq !== null)?_c('span',[_vm._v(" "+_vm._s(props.item.tvq.toFixed(2))+"$ ")]):_vm._e()]}},{key:"item.frais_retard",fn:function(props){return [(props.item.frais_retard !== null)?_c('span',[_vm._v(" "+_vm._s(props.item.frais_retard.toFixed(2))+"$ ")]):_vm._e()]}},{key:"item.montant_total",fn:function(props){return [(props.item.montant_total !== null)?_c('span',[_vm._v(" "+_vm._s(props.item.montant_total.toFixed(2))+"$ ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('facture-mensuelle-details',{attrs:{"headers":headers,"item":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }