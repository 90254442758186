<template>
  <v-container
    id="usermanagement-roles"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-account-multiple"
      :title="$t('sidebar.users')"
      class="px-5 py-3"
    >
      <v-col cols="12">
        <v-autocomplete
          v-model="select"
          :loading="loading"
          :items="items"
          :search-input.sync="search"
          class="mx-4"
          flat
          no-filter
          hide-no-data
          hide-details
          :label="$t('usersManagement.Add_user_modal_title')"
          solo-inverted
          item-text="displayName"
          return-object
          @change="selectUser"
        />
      </v-col>
      <template v-if="roles.length">
        <v-col cols="12">
          <div class="text-right">
            <v-btn
              color="error"
              @click="removeUser"
            >
              {{ $t('usersManagement.Delete_user') }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12">
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="primary--text">
                    <h3>{{ $t('Name') }}</h3>
                  </th>
                  <th class="primary--text">
                    <h3>Description</h3>
                  </th>
                  <th class="primary--text text-right">
                    <h3>ACTIONS</h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in roles"
                  :key="item.idRole"
                >
                  <td>{{ item.idRole }}</td>
                  <td>{{ item.description }}</td>
                  <td class="text-right">
                    <v-btn
                      class="px-1 ml-1"
                      color="error"
                      min-width="0"
                      small
                      @click="remove(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </template>
    </base-material-card>
  </v-container>
</template>

<script>
  // Service
  import UsersManagementService from '@/services/UQAM/UsersManagementService.js'
  // Other
  import Swal from 'sweetalert2'

  export default {
    name: 'UsersManagementUsers',
    data () {
      return {
        roles: [],
        loading: false,
        items: [],
        search: null,
        select: null,
      }
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
    },
    methods: {
      async selectUser () {
        // Start Spinner
        this.$loading.show()
        try {
          // search name
          const users = await UsersManagementService.viewUser(this.select.uid)
          // Roles
          this.roles = users.roles
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async querySelections (event) {
        // On regarde pour le prénom et le nom
        const text = event.split(' ')
        this.loading = true

        // Le client doit entrer le Prénom et le Nom ou le Nom et le Prénom
        if (text.length >= 2) {
          if (text[1] !== '') {
            try {
              this.items = await UsersManagementService.listUser(event)
              this.loading = false
            } catch (error) {
              console.log(error)
              this.loading = false
            }
          } else {
            this.items = []
            this.loading = false
          }
        } else {
          this.items = []
          // Stop Spinner
          this.loading = false
        }
      },
      remove (role) {
        // SweetAlert
        Swal.fire({
          icon: 'warning',
          title: this.$i18n.t('Are_you_sure'),
          text: `${this.$i18n.t('usersManagement.Delete_user')} "${this.select.displayName}" ${this.$i18n.t('usersManagement.of_the_role')} "${role.idRole}" ?`,
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: this.$i18n.t('Delete'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.value) {
            // Start Spinner
            this.$loading.show()
            try {
              await UsersManagementService.deleteRoleUsers(role.idRole, this.select.uid)
              // Stop Spinner
              this.$loading.hide()
              // SweetAlert
              Swal.fire({
                icon: 'success',
                title: `${this.$i18n.t('Deleted')} !`,
                text: `${this.$i18n.t('usersManagement.The_role')} "${role.idRole}" ${this.$i18n.t('usersManagement.has_been_deleted_for_the_user')} "${this.select.displayName}"`,
              })
              // Delete item in array
              this.roles = this.roles.filter(item => (role.idRole !== item.idRole))
            } catch (err) {
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      removeUser () {
        // SweetAlert
        Swal.fire({
          icon: 'warning',
          title: this.$i18n.t('Are_you_sure'),
          text: `${this.$i18n.t('usersManagement.Delete_all_user_roles')} "${this.select.displayName}"`,
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: this.$i18n.t('Delete'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.value) {
            // Start Spinner
            this.loading = true
            try {
              await UsersManagementService.deleteAllUserRoles(this.select.uid)
              // Stop Spinner
              this.loading = false
              // SweetAlert
              Swal.fire({
                icon: 'success',
                title: `${this.$i18n.t('Deleted')} !`,
                text: `${this.$i18n.t('usersManagement.All_roles_have_been_removed_for_the_user')} "${this.select.displayName}"`,
                heightAuto: false, // Put this each time. Prevents the html footer from being higher
              })
              // Delete item in array
              this.roles = []
            } catch (err) {
              // Stop Spinner
              this.loading = false
            }
          }
        })
      },
    },
  }
</script>
