<template>
  <div>
    <vue2Dropzone
      id="vueDropzone"
      ref="vueDropzone"
      :options="dropzoneOptions"
      :destroy-dropzone="false"
      :awss3="awss3"
      @vdropzone-sending="sending"
      @vdropzone-error="error"
      @vdropzone-removed-file="remove"
      @vdropzone-queue-complete="queueCompleted"
      @vdropzone-s3-upload-error="s3UploadError"
      @vdropzone-s3-upload-success="s3UploadSuccess"
    />
    <v-btn
      class="mt-2"
      color="green"
      @click="startUpload"
    >
      {{ $t('Upload') }}
    </v-btn>
    <v-btn
      class="mt-2 ml-2"
      color="error"
      @click="removeAllFiles"
    >
      {{ $t('Remove') }}
    </v-btn>
  </div>
</template>
<script>
  // Other
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import Swal from 'sweetalert2'
  import store from '@/store/index'
  // MSAL
  import { loginRequest } from '@/config/msal'
  import { useMsal } from '@/msal/useMsal'
  const { instance } = useMsal()

  export default {
    name: 'ToolsTransfertFiles',
    components: {
      vue2Dropzone,
    },
    data () {
      return {
        folderDate: null,
        dropzoneOptions: {
          url: `${process.env.VUE_APP_SERVER_ADDRESS}/01cell/tools/transfertfiles`,
          method: 'PUT',
          thumbnailWidth: 150,
          // maxFilesize: 100,
          maxFiles: 2,
          acceptedFiles: '.txt',
          addRemoveLinks: true, // Permet de supprimer un fichier
          autoProcessQueue: false, // Si true, upload le fichier immédiatement. Si false, doit appuyer sur un bouton pour uploader tous les fichiers
          dictDefaultMessage: `<i class="fas fa-file-import mr-2"></i>${this.$i18n.t('vueDropZone.dictDefaultMessage')}`,
          dictFallbackMessage: this.$i18n.t('vueDropZone.dictFallbackMessage'),
          dictFallbackText: this.$i18n.t('vueDropZone.dictFallbackText'),
          dictFileTooBig: `${this.$i18n.t('vueDropZone.dictFileTooBig')} ({{filesize}}MiB). ${this.$i18n.t('vueDropZone.dictFileTooBig1')} {{maxFilesize}}${this.$i18n.t('vueDropZone.dictFileTooBig2')}`,
          dictInvalidFileType: this.$i18n.t('vueDropZone.dictInvalidFileType'),
          dictResponseError: `${this.$i18n.t('vueDropZone.dictResponseError')} {{statusCode}}.`,
          dictCancelUpload: this.$i18n.t('vueDropZone.dictCancelUpload'),
          dictCancelUploadConfirmation: this.$i18n.t('vueDropZone.dictCancelUploadConfirmation'),
          dictRemoveFile: this.$i18n.t('Delete'),
          dictMaxFilesExceeded: this.$i18n.t('vueDropZone.dictMaxFilesExceeded'),
        },
        awss3: {
          signingURL: `${process.env.VUE_APP_SERVER_ADDRESS}/01cell/tools/transfertfiles`,
          headers: {
            Authorization: null,
            'Content-Language': localStorage.getItem('APP_LANG_KEY'),
          },
          paramName: 'files', // Même nom que dans Multer
          sendFileToServer: false,
          withCredentials: false,
        },
      }
    },

    computed: {
    },

    async created () {
      if (process.env.VUE_APP_AUTHENTIFICATION === 'msal') {
        try {
          const accounts = instance.getAllAccounts()
          const token = await instance.acquireTokenSilent({
            scopes: loginRequest.scopes,
            accounts,
          })
          this.awss3.headers.Authorization = `Bearer ${token.accessToken}`
        } catch (error) {
          console.log(error)
        }
      } else if (process.env.VUE_APP_AUTHENTIFICATION === 'oidc') {
        this.awss3.headers.Authorization = `Bearer ${store.state.oidcStore.access_token}`
      }
    },

    methods: {
      async startUpload () {
        if (this.$refs.vueDropzone.getQueuedFiles().length !== 2) {
          this.$notifier.show({ message: this.$i18n.t('tools.snackBar.Files_are_missing'), color: 'error', icon: 'mdi-close-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })
          return
        }

        // On s'assure que les fichiers son dans le upload
        let missingFiles = ['individualdetail', 'airtimedetail']
        // On s'assure que les deux fichiers, on la même année et le même mois
        const folderDate = []
        const files = this.$refs.vueDropzone.getQueuedFiles()

        files.forEach(currentItem => {
          // console.log(currentItem.name)
          const regex = new RegExp(/^([0-9]{4})([0-9]{2}).*(individualdetail|airtimedetail)/gmi)
          const fileFound = regex.exec(currentItem.name)
          // console.log(fileFound.length)
          if (fileFound) {
            // Si il son là on enlève le nom 'individualdetail' ou 'airtimedetail'
            missingFiles = missingFiles.filter(item => item !== fileFound[3].toLowerCase())
            folderDate.push(`${fileFound[1]}_${fileFound[2]}`)
          }
        })

        // Error les dates son différentes (2020_08)
        if (!folderDate.every((val, ind, arr) => val === arr[0])) {
          this.$notifier.show({ message: this.$i18n.t('tools.snackBar.dates_do_not_match'), color: 'error', icon: 'mdi-close-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })
          return
        }

        // Si il manque un fichier il est dans le array. On l'affiche avec un Swal et on arrête la fonction
        if (missingFiles.length) {
          Swal.fire({
            icon: 'error',
            title: this.$i18n.t('tools.swal.title.Some_files_missing'),
            html: missingFiles.join(', '),
          })
          return
        }

        // Upload des fichiers
        // On mets le nom du folder dans la variable 'folderDate' pour les étapes suivante
        this.folderDate = folderDate[0]
        try {
          // On commence le upload
          this.$refs.vueDropzone.processQueue()
        } catch (error) {
          console.log(error)
        }
      },
      // Important car il faut envoyer les fichiers vers S3 en 'binary' par en 'form-data'
      sending (file, xhr, formData) {
        setTimeout(() => {
          // Aprés le transfert si la fenêtre pour la 'Gestion de la facturation' est ouverte, on va chercher les informations pour la facturation
          this.$emit('update-billing-management')
        }, 4000)
        const _send = xhr.send
        xhr.send = function () {
          _send.call(xhr, file)
        }
      },
      queueCompleted () {
      },
      removeAllFiles () {
        this.$refs.vueDropzone.removeAllFiles()
      },
      error (file, error, xhr) {
        console.log(file)
        console.log(error)
        console.log(xhr)
        Swal.fire(
          this.$i18n.t('error'),
          this.$i18n.t('tools.swal.text.The_file_cannot_be_uploaded'),
          'error',
        )
      },
      async remove (file) {
        if (file.id) {
          // Start Spinner
          this.$loading.show()
          try {
            // On peux supprimer la pièce jointe
            // Important. Il y a un hook dans le model pour supprimer le fichier
            // await SigitCablageCodeAttachmentService.remove(file.id)
            // Stop Spinner
            this.$loading.hide()
          } catch (error) {
            console.log(error)
            // Stop Spinner
            this.$loading.hide()
          }
        }
      },
      s3UploadError (errorMessage) {
        console.log(errorMessage)
      },
      s3UploadSuccess (s3ObjectLocation) {
        // Voir le composant 'OperationTools'
        // Aprés le transfert si la fenêtre pour la 'Gestion de la facturation' est ouverte, on va chercher les informations pour la facturation
        this.$emit('update-billing-management')
      },
    },
  }
</script>
