<template>
  <!--  eslint-disable vue/no-mutating-props-->
  <v-pagination
    v-if="props.totalPages>1"
    v-model="props.page"
    :length="props.totalPages"
    total-visible="10"
    color="primary"
    @input="page => props.setPage(page)"
  />
</template>

<script>
  export default {
    name: 'VtPagination',
    // eslint-disable-next-line vue/require-prop-types
    props: ['props'],
  }
</script>
