import api from '@/services/Api'

export default {
    list (startingAfter) {
        return api().get(`/stripe/charges/list/${startingAfter}`)
    },
    view (chargeId) {
        return api().get(`/stripe/charges/view/${chargeId}`)
    },
    queryByFactureId (factureId) {
        return api().get(`/stripe/charges/queryByFactureId/${factureId}`)
    },
    queryByCustomerId (customerId, nextPage) {
        return api().get(`/stripe/charges/queryByCustomerId/${customerId}/${nextPage}`)
    },
    queryAllByCustomerId (customerId) {
        return api().get(`/stripe/charges/queryAllByCustomerId/${customerId}`)
    },
    queryCustomerId (nextPage) {
        return api().get(`/stripe/charges/queryCustomerId/${nextPage}`)
    },
    queryByPeriod (periode, nextPage) {
        return api().get(`/stripe/charges/queryByPeriod/${periode}/${nextPage}`)
    },
    queryByPaymentIntent (paimentIntentId) {
        return api().get(`/stripe/charges/queryByPaymentIntent/${paimentIntentId}`)
    },
}
