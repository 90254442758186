import api from '@/services/Api'

export default {
    sendMail (options) {
        return api().post('/mailer', options)
    },
    sendEmailToCustomerWithMessageNo (messageNo) {
        return api().get(`/mailer/sendEmailToCustomerWithMessageNo/${messageNo}`)
    },
}
