<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >
    <v-row
      v-if="problemDeRefresh"
      class="fill-height"
    >
      <v-col>
        <v-sheet height="64">
          <v-toolbar
            flat
          >
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              {{ $t('calendar.Today') }}
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon small>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ title }}
            </v-toolbar-title>
            <v-spacer />
            <v-menu
              bottom
              right
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span>{{ $t(`calendar.type.${type}`) }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>{{ $t('calendar.type.day') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>{{ $t('calendar.type.week') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>{{ $t('calendar.type.month') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>{{ $t('calendar.type.4day') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="1000">
          <v-calendar
            ref="calendar"
            v-model="focus"
            :event-more="true"
            color="primary"
            :events="getEvents"
            :event-color="getEventColor"
            :type="type"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:event="showEvent"
          />
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card
              color="grey lighten-4"
              min-width="350px"
              flat
            >
              <v-toolbar
                :color="selectedEvent.color"
                dark
              >
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="selectedEvent.name" />
                <v-spacer />
                <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span v-html="selectedEvent.item" />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="secondary"
                  @click="selectedOpen = false"
                >
                  {{ $t('Close') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // Avec icon https://codepen.io/trongtn178/pen/PMwNXO
  // Other
  import { parseISO } from 'date-fns'

  export default {
    name: 'Calendar',
    props: {
      tableData: Array,
    },
    data () {
      return {
        problemDeRefresh: false,
        title: null,
        focus: '',
        type: 'month',
        typeToLabel: {
          month: 'Month',
          week: 'Week',
          day: 'Day',
          '4day': '4 Days',
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
      }
    },

    computed: {
      getEvents () {
        const events = []
        for (const item of this.tableData) {
          if (item.rdv_date && item.statut !== 8) {
            const color = item.mode_paiement === 1 ? 'green' : 'primary'

            const data = {
              name: `[CMD ${item.id}] ${this.$i18n.t(`order.type.${item.type}`)}`,
              color: item.rdv_confirme ? color : 'grey',
              start: parseISO(item.rdv_date),
              end: parseISO(item.rdv_date),
              timed: true,
              item: item,
            }
            events.push(data)
          }
        }
        return events
      },
    },

    mounted () {
      this.$nextTick(() => {
        // On affiche le calendrier
        this.problemDeRefresh = true
        setTimeout(() => {
          this.$refs.calendar.checkChange()
          this.title = this.$refs.calendar.title
        }, 100)
      })
    },

    methods: {
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
        this.title = this.$refs.calendar.title
      },
      next () {
        this.$refs.calendar.next()
        this.title = this.$refs.calendar.title
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          // eslint-disable-next-line no-return-assign
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
    },
  }
</script>
