<template>
  <v-container
    id="usermanagement-roles"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-card-account-details"
      :title="$t('sidebar.roles')"
      class="px-5 py-3"
    >
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th class="primary--text">
                <h3>{{ $t('Name') }}</h3>
              </th>
              <th class="primary--text">
                <h3>Description</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in roles.roles"
              :key="item.idRole"
              class="pointer"
              @click="selectRole(item)"
            >
              <td>{{ item.idRole }}</td>
              <td>{{ item.description }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </base-material-card>
  </v-container>
</template>

<script>
  // Service
  import UsersManagementService from '@/services/UQAM/UsersManagementService.js'

  export default {
    name: 'UsersManagementRoles',
    data () {
      return {
        roles: [],
      }
    },
    async created () {
      try {
        // Start Spinner
        this.$loading.show()
        this.roles = await UsersManagementService.listRoles()
        // Stop Spinner
        this.$loading.hide()
      } catch (error) {
        console.log(error)
        // Stop Spinner
        this.$loading.hide()
      }
    },
    methods: {
      selectRole (role) {
        this.$router.push({ name: 'usersmanagement-roles-users', params: { roleId: role.idRole } })
      },
    },
  }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>
