/**
 * Theme
 */
const theme = {
  strict: true,
  state: {
    barColor: 'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
    barImage: 'login.jpg',
    barImageToggle: false,
    drawer: null,
    darkMode: false,
    color: '#E91E63', // Couleur du thème (Primary)
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    SET_DARK_MODE (state, payload) {
      state.darkMode = payload
    },
    SET_THEME_COLOR (state, payload) {
      state.color = payload
    },
    SET_BAR_IMAGE_TOGGLE (state, payload) {
      state.barImageToggle = payload
    },
  },
  actions: {

  },
}

export default theme
