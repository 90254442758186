import api from '@/services/Api'

export default {
    // Roles
    listRoles () {
        return api().get('/uqam/usersmanagenent/roles') // Get all roles
    },
    addRoleUsers (user) {
        return api().post('/uqam/usersmanagenent/roles/', user) // Add user from role
    },
    viewRoleUsers (roleId) {
        return api().get(`/uqam/usersmanagenent/roles/users/${roleId}`) // Get users of a role
    },
    deleteRoleUsers (roleId, userId) {
        return api().delete(`/uqam/usersmanagenent/roles/delete/users/${roleId}/${userId}`) // Delete user from role
    },
    // Users
    listUser (user) {
        return api().get(`/uqam/usersmanagenent/users/?data=${user}`) // Get user
    },
    viewUser (userId) {
        return api().get(`/uqam/usersmanagenent/users/${userId}`) // Get user
    },
    deleteAllUserRoles (userId) {
        return api().delete(`/uqam/usersmanagenent/users/${userId}`) // Delete all user roles
    },
}
