<template>
  <div id="settings-wrapper">
    <v-card
      id="settings"
      class="py-2 px-4"
      color="rgba(0, 0, 0, .3)"
      dark
      flat
      link
      min-width="100"
      style="position: fixed; top: 115px; right: -35px; border-radius: 8px;"
    >
      <v-icon large>
        mdi-cog-outline
      </v-icon>
    </v-card>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
    >
      <v-card
        class="text-center mb-0"
        width="300"
      >
        <v-card-text>
          <strong class="mb-3 d-inline-block">{{ $t('settings.theme_color') }}</strong>

          <v-item-group v-model="color">
            <v-item
              v-for="item in colors"
              :key="item"
              :value="item"
            >
              <template #default="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="item"
                  class="v-settings__item"
                  size="25"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 gray" />

          <strong class="mb-3 d-inline-block">{{ $t('settings.sidebar_background') }}</strong>

          <!-- <v-item-group v-model="scrim">
            <v-item
              v-for="item in scrims"
              :key="item"
              :value="item"
              class="mx-1"
            >
              <template #default="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="item"
                  class="v-settings__item"
                  size="24"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 gray" /> -->

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              {{ $t('settings.dark_mode') }}
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="$vuetify.theme.dark"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
                @change="changeMode"
              />
            </v-col>
          </v-row>

          <!-- <v-divider class="my-4 gray" /> -->

          <!-- <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Sidebar Mini
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="internalValue"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row> -->

          <!-- <v-divider class="my-4 gray" />

          <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              {{ $t('settings.sidebar_image') }}
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="showImg"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
                @change="changeSideBarImage"
              />
            </v-col>
          </v-row> -->

          <!-- <v-divider class="my-4 gray" /> -->

          <!-- <strong class="mb-3 d-inline-block">IMAGES</strong>

          <v-item-group
            v-model="image"
            class="d-flex justify-space-between mb-3"
          >
            <v-item
              v-for="item in images"
              :key="item"
              :value="item"
              class="mx-1"
            >
              <template #default="{ active, toggle }">
                <v-sheet
                  :class="active && 'v-settings__item--active'"
                  class="d-inline-block v-settings__item"
                  @click="toggle"
                >
                  <v-img
                    :src="`img/${item}`"
                    height="100"
                    width="50"
                  />
                </v-sheet>
              </template>
            </v-item>
          </v-item-group> -->
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { mapMutations, mapState } from 'vuex'

  export default {
    name: 'DashboardCoreSettings',

    mixins: [Proxyable],

    data: () => ({
      color: null,
      colors: [
        '#9C27b0',
        '#00CAE3',
        // '#4CAF50', vert
        '#ff9800',
        '#E91E63',
        '#FF5252',
      ],
      image: null,
      images: [
        'login.jpg',
        'unauthorized.jpg',
        // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-2.jpg',
        // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-3.jpg',
        // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-4.jpg',
      ],
      menu: false,
      scrim: 'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
      scrims: [
        'rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)',
        'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',
        'rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)',
      ],
      showImg: true,
    }),

    computed: {
      ...mapState(['theme']),
    },

    watch: {
      color (val) {
        this.$vuetify.theme.themes.dark.primary = val
        this.$vuetify.theme.themes.light.primary = val
        this.$store.commit('SET_THEME_COLOR', val)
      },
      showImg (val) {
        this.setBarImageToggle(val)
      },
      image (val) {
        this.setBarImage(val)
      },
      scrim (val) {
        this.$store.commit('SET_SCRIM', val)
      },
    },

    created () {
      this.scrim = this.theme.barColor
      this.$vuetify.theme.dark = this.theme.darkMode
      this.color = this.theme.color
      this.image = this.theme.barImage
      this.showImg = this.theme.barImageToggle
      this.$vuetify.theme.themes.dark.primary = this.theme.color
      this.$vuetify.theme.themes.light.primary = this.theme.color
    },

    methods: {
      ...mapMutations({
        setBarImage: 'SET_BAR_IMAGE',
        setBarImageToggle: 'SET_BAR_IMAGE_TOGGLE',
      }),
      changeMode (event) {
        this.$store.commit('SET_DARK_MODE', event)
      },
      changeSideBarImage () {
        this.$router.go(0)
      },
    },
  }
</script>

<style lang="sass">
  .v-settings
    .v-item-group > *
      cursor: pointer

    &__item
      border-width: 3px
      border-style: solid
      border-color: transparent !important

      &--active
        border-color: #00cae3 !important
</style>
