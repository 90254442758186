<template>
  <v-card-text class="text-center">
    <pages-btn
      large
      color=""
      depressed
      class="v-btn--text success--text"
      @click="login"
    >
      {{ $t('Connexion') }}
    </pages-btn>
  </v-card-text>
</template>

<script>
  // Other
  import { loginRequest } from '@/config/msal'
  import { useMsal } from '@/msal/useMsal'
  const { instance } = useMsal()

  export default {
    name: 'LoginMsal',

    components: {
      PagesBtn: () => import('@/views/pages/components/Btn.vue'),
    },

    data: () => ({
      show: false,
      username: '',
      password: '',
    }),
    methods: {
      login () {
        const request = {
          ...loginRequest,
          redirectStartPage: '/my_portal',
        }
        instance.loginRedirect(request)
      },
    },
  }
</script>
