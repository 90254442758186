<template>
  <div class="VueTables__search-field">
    <!-- <input
      :id="`VueTables__search_${props.id}`"
      class="VueTables__search__input"
      type="text"
      placeholder="Search..."
      autocomplete="off"
      @keyup="e=>props.search(props.debounce)(e)"
    > -->
    <v-text-field
      :id="`VueTables__search_${props.id}`"
      class="VueTables__search__input"
      type="text"
      :placeholder="`${$t('search')}...`"
      autocomplete="off"
      @keyup="e=>props.search(props.debounce)(e)"
    />
  </div>
</template>

<script>
  export default {
    name: 'VtGenericFilter',
    // eslint-disable-next-line vue/require-prop-types
    props: ['props'],
  }
</script>
