<template>
  <div>
    <div>
      <span class="text-h4">{{ $t('tools.Bad_payer') }}(s): {{ mauvaisPayeur.length }}</span>
    </div>

    <template v-if="mauvaisPayeur.length">
      <v-row
        dense
      >
        <v-col
          cols="12"
          class="px-0"
        >
          <div>
            <v-btn
              class="mt-5"
              color="blue"
              @click="sendEmail"
            >
              {{ $t('order.operation.Send_email') }}
              <v-icon
                right
                dark
              >
                mdi-email-arrow-right-outline
              </v-icon>
            </v-btn>
          </div>

          <v-card>
            <v-card-text>
              <div v-html="email" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="mauvaisPayeur"
      >
        <!--  Numéro de contrat-->
        <template
          slot="item.no"
          slot-scope="props"
        >
          <span>
            {{ props.item.no | phoneNumberFormat }}
          </span>
        </template>
      </v-data-table>
    </template>

    <!--  Loading-->
    <v-overlay
      absolute
      :value="overlay"
    >
      <v-progress-circular
        :width="5"
        color="purple"
        indeterminate
      />
    </v-overlay>
  </div>
</template>
<script>
  // Service
  import FacturationsService from '@/services/01Cell/FacturationsService'
  import MessagesService from '@/services/01Cell/MessagesService'

  export default {
    name: 'ToolsMauvaisPayeur',
    data () {
      return {
        overlay: false,
        mauvaisPayeur: [],
        email: null,
        language: this.$i18n.t('Language'),
        tableHeaders: [
          { text: this.$i18n.t('Contract'), value: 'no', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('client.Email'), value: 'email', sortable: true, class: 'text-h4 primary--text' },
        ],
      }
    },

    computed: {
    },

    async mounted () {
      this.getData()
    },

    methods: {
      async getData () {
        try {
          this.overlay = true
          const mauvaisPayeurPromise = FacturationsService.mauvaisPayeur()
          const messagePromise = MessagesService.replace(32, this.language)
          const [mauvaisPayeur, message] = await Promise.all([mauvaisPayeurPromise, messagePromise])
          this.mauvaisPayeur = mauvaisPayeur
          this.email = message.body
          this.overlay = false
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      async sendEmail () {
        try {
          this.overlay = true
          await FacturationsService.sendEmailmauvaisPayeur()
          this.overlay = false
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
    },
  }
</script>
