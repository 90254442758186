import Vue from 'vue'
import { InteractionStatus } from '@azure/msal-browser'

export function useMsal () {
      const { instance, accounts, inProgress } = Vue.prototype.$msal
      if (!instance || !accounts || !inProgress) {
          // eslint-disable-next-line no-throw-literal
          throw 'Please install the msalPlugin'
      }

      if (inProgress === InteractionStatus.Startup) {
          instance.handleRedirectPromise().catch(() => {
              // Errors should be handled by listening to the LOGIN_FAILURE event
              // return
          })
      }
      return {
        instance: instance,
        accounts,
        inProgress,
    }
}
