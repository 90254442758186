<template>
  <v-snackbar
    v-model="show"
    :color="getSnackBarInfo.color"
    shaped
    vertical
    :timeout="getSnackBarInfo.timeout"
    :top="getSnackBarInfo.position.top"
    :center="getSnackBarInfo.position.center"
    :left="getSnackBarInfo.position.left"
    :right="getSnackBarInfo.position.right"
  >
    <v-icon>{{ getSnackBarInfo.icon }}</v-icon>
    <span class="snackbar_font_size ml-3">{{ getSnackBarInfo.message }}</span>
    <!-- <v-btn text @click="show = false">Close</v-btn> -->
  </v-snackbar>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
        show: false,
      }
    },

    computed: {
      ...mapGetters('snackbar', [
        'getSnackBarInfo',
      ]),
    },

    watch: {
      getSnackBarInfo: function (val) {
        this.show = val.show
      },
    },
  }
</script>
