var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"inline":"","icon":_vm.createEditInfo.icon,"title":_vm.createEditInfo.title,"color":_vm.color}},[_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" Client* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"items":_vm.client.entries,"loading":_vm.client.isLoading,"search-input":_vm.client.search,"hide-no-data":"","hide-selected":"","item-text":"firstNameName","item-value":"id","placeholder":_vm.$t('Start_typing_Search'),"append-icon":"mdi-account","return-object":"","error-messages":errors,"success":valid,"required":""},on:{"update:searchInput":function($event){return _vm.$set(_vm.client, "search", $event)},"update:search-input":function($event){return _vm.$set(_vm.client, "search", $event)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $event.preventDefault()}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.firstNameName)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.firstNameName)+" ")]}}],null,true),model:{value:(_vm.submitForm.Client),callback:function ($$v) {_vm.$set(_vm.submitForm, "Client", $$v)},expression:"submitForm.Client"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('contract.no'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contract.no'),"rules":{ required:true, min:10, max:10, numeric:true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.no),callback:function ($$v) {_vm.$set(_vm.submitForm, "no", $$v)},expression:"submitForm.no"}})]}}],null,true)})],1)],1),(_vm.submitForm.Client)?_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('order.Payment_mode'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('order.Payment_mode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.getType,"item-text":"item","item-value":"valeur","persistent-hint":"","single-line":"","error-messages":errors,"success":valid,"required":""},on:{"change":_vm.resetBillingcode},model:{value:(_vm.submitForm.type),callback:function ($$v) {_vm.$set(_vm.submitForm, "type", $$v)},expression:"submitForm.type"}})]}}],null,true)}),(_vm.getTypeMessage)?_c('small',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.getTypeMessage))]):_vm._e()],1)],1):_vm._e(),(_vm.submitForm.type === 1)?[(_vm.$company === 'mcgill')?_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('contract.Billing_code'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('mc-gill-foapal',{ref:"mcGillFoapal",attrs:{"state":"edit","code-facturation":_vm.submitForm.code_facturation},on:{"change-foapal":_vm.changeFoapal}})],1)],1):_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('contract.Billing_code'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"UBR","rules":_vm.getUbrValidationRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mt-3",attrs:{"maxlength":_vm.parameterLongueurCodeFacturation,"label":_vm.$t('order.ubr_description'),"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.code_facturation),callback:function ($$v) {_vm.$set(_vm.submitForm, "code_facturation", $$v)},expression:"submitForm.code_facturation"}})]}}],null,true)})],1)],1)]:_vm._e(),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" Description* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","append-icon":"mdi-comment","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.description),callback:function ($$v) {_vm.$set(_vm.submitForm, "description", $$v)},expression:"submitForm.description"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('Status'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"disabled":_vm.checkDisabledStatus,"items":_vm.getStatus,"item-text":"item","item-value":"valeur","persistent-hint":"","single-line":"","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.statut),callback:function ($$v) {_vm.$set(_vm.submitForm, "statut", $$v)},expression:"submitForm.statut"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('contract.Flat_rate'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contract.Flat_rate'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.flatRate,"item-text":"item","item-value":"valeur","persistent-hint":"","single-line":"","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.forfait_id),callback:function ($$v) {_vm.$set(_vm.submitForm, "forfait_id", $$v)},expression:"submitForm.forfait_id"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('contract.Provider'))+"* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('contract.Provider'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.provider,"item-text":"item","item-value":"valeur","persistent-hint":"","single-line":"","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.fournisseur),callback:function ($$v) {_vm.$set(_vm.submitForm, "fournisseur", $$v)},expression:"submitForm.fournisseur"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('contract.Active_credit'))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"Note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"disabled":"","type":"number","background-color":_vm.getCreditActifBackground,"error-messages":errors,"success":valid,"required":""},model:{value:(_vm.submitForm.credit_actif),callback:function ($$v) {_vm.$set(_vm.submitForm, "credit_actif", $$v)},expression:"submitForm.credit_actif"}})]}}],null,true)})],1)],1),(_vm.parameterGestionGroup !== '0')?_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$t('Group'))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"items":_vm.groupes,"item-text":"description","item-value":"id","persistent-hint":"","single-line":""},model:{value:(_vm.submitForm.GroupeId),callback:function ($$v) {_vm.$set(_vm.submitForm, "GroupeId", $$v)},expression:"submitForm.GroupeId"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"align":"center","dense":""}},[_c('v-col',{staticClass:"text-right text-body-1 grey--text mr-4",attrs:{"cols":"2"}},[_vm._v(" Note* ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-textarea',{attrs:{"append-icon":"mdi-comment"},model:{value:(_vm.submitForm.note),callback:function ($$v) {_vm.$set(_vm.submitForm, "note", $$v)},expression:"submitForm.note"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"text-body-2 font-weight-light"},[_vm._v(" *"+_vm._s(_vm.$t('Required_Fields'))+" ")])]),_c('div',{staticClass:"pa-3 text-center"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t('Validate'))+" ")])],1)],2)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }