import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/appareils')
    },
    save (appareil) { // Création d'une nouvelle entrée
        return api().post('/appareils', appareil)
    },
    view (appareilId) { // Voir une entrée spécifique
        return api().get(`/appareils/${appareilId}`)
    },
    update (appareil, appareilId) { // Mise à jour d'une entrée spécifique
        return api().put(`/appareils/${appareilId}`, appareil)
    },
    remove (appareilId) { // Suppression d'une entrée spécifique
        return api().delete(`/appareils/${appareilId}`)
    },
    listByGroup (activeInactive) { // Liste tout
        return api().get(`/appareils/listByGroup/${activeInactive}`)
    },
    listByCompanie () { // Liste tout
        return api().get('/appareils/listByCompanie')
    },
    listByModele (compagnie, actif) { // Liste tout
        return api().get(`/appareils/listByModele/${compagnie}`)
    },
    listByStockage (modele, actif) { // Liste tout
        return api().get(`/appareils/listByStockage/${modele}`)
    },
    listByCouleur (stockage, modele, actif) { // Liste tout
        return api().get(`/appareils/listByCouleur/${stockage}/${modele}`)
    },
    bulkUpdate (appareils) {
        return api().put('/appareils/bulkUpdate', appareils)
    },
    bulkCreate (appareils) {
        return api().post('/appareils/bulkCreate', appareils)
    },
}
