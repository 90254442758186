<template>
  <v-main>
    <router-view />
    <!--  J'ai ajouté les br pour mettre des espaces entre la page principal et le footer-->
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <dashboard-core-footer />
    <loading />
    <snackbar />
    <dialogs />
  </v-main>
</template>

<script>
  import ParametersService from '@/services/01Cell/ParametersService'
  import { mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
      Snackbar: () => import('@/components/SnackBar/Snackbar'),
      Loading: () => import('@/components/Loading/Loading'),
      Dialogs: () => import('@/components/Dialog/Dialog'),
    },

    async mounted () {
      // Liste des paramètres que l'on mets dans le vuex pour récupéré partout dans le code
      ParametersService.listAll().then(parameters => {
        this.setParameters(parameters)
      })
    },

    methods: {
      ...mapMutations('parameters', [
        'setParameters',
      ]),
    },
  }
</script>
