<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-card-title>
      <v-text-field
        v-model="search"
        class="mb-5"
        append-icon="mdi-magnify"
        :label="$t('Search')"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      :headers="tableHeaders"
      :items="table"
      :expanded.sync="expanded"
      item-key="id"
      :search="search"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
      :items-per-page="50"
    >
      <!--  eslint-disable-next-line vue/valid-v-slot-->
      <template #item.edit="{ item }">
        <!--  View-->
        <v-btn
          class="ml-2"
          fab
          dark
          x-small
          color="white"
          :title="$t('View')"
          @click="viewItem(item)"
        >
          <v-icon>{{ getButtonIcon(item.id) }}</v-icon>
        </v-btn>
      </template>

      <!-- Catégorie-->
      <template
        slot="item.categorie"
        slot-scope="props"
      >
        <span v-if="props.item.categorie === 1">{{ $t('invoices.Monthly_invoices') }}</span>
        <span v-if="props.item.categorie === 2">{{ $t('invoices.Other_invoices') }}</span>
      </template>

      <!-- Sous-total-->
      <template
        slot="item.montant_at"
        slot-scope="props"
      >
        <span v-if="props.item.montant_at !== null">
          {{ props.item.montant_at.toFixed(2) }}$
        </span>
      </template>

      <!-- Crédit appliqué-->
      <template
        slot="item.credit_applique"
        slot-scope="props"
      >
        <span v-if="props.item.credit_applique !== null">
          {{ props.item.credit_applique.toFixed(2) }}$
        </span>
      </template>

      <!-- TPS-->
      <template
        slot="item.tps"
        slot-scope="props"
      >
        <span v-if="props.item.tps !== null">
          {{ props.item.tps.toFixed(2) }}$
        </span>
      </template>

      <!-- TVQ-->
      <template
        slot="item.tvq"
        slot-scope="props"
      >
        <span v-if="props.item.tvq !== null">
          {{ props.item.tvq.toFixed(2) }}$
        </span>
      </template>

      <!-- Frais de retard-->
      <template
        slot="item.frais_retard"
        slot-scope="props"
      >
        <span v-if="props.item.frais_retard !== null">
          {{ props.item.frais_retard.toFixed(2) }}$
        </span>
      </template>

      <!-- Total-->
      <template
        slot="item.montant_total"
        slot-scope="props"
      >
        <span v-if="props.item.montant_total !== null">
          {{ props.item.montant_total.toFixed(2) }}$
        </span>
      </template>

      <!--  Détail de la facture-->
      <template #expanded-item="{ headers, item }">
        <facture-mensuelle-details
          :headers="headers"
          :item="item"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  // Component
  import FactureMensuelleDetails from '@/components/Client/Facture/FactureMensuelleDetails'

  export default {
    name: 'RapportTableComponent',
    components: {
      FactureMensuelleDetails,
    },
    props: {
      table: Array,
      tableHeaders: Array,
    },
    data () {
      return {
        expanded: [],
        search: '',
      }
    },

    computed: {
    },

    methods: {
      getButtonIcon (id) {
        if (this.expanded.some(item => item.id === id)) {
          return 'mdi-eye-off-outline'
        }
        return 'mdi-eye-outline'
      },
      viewItem (value) {
        const index = this.expanded.indexOf(value)
        if (index === -1) {
          this.expanded.push(value)
        } else {
          this.expanded.splice(index, 1)
        }
      },
    },
  }
</script>
