<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="sommaireFacturation"
      item-key="periode"
    >
      <!--  Lot-->
      <template
        slot="item.lot"
        slot-scope="props"
      >
        <!-- Lot-->
        <div v-if="props.item.lot">
          <v-menu>
            <template #activator="{ on, attrs }">
              <v-btn
                color="blue"
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('File') }}s
                <v-icon
                  right
                  dark
                >
                  mdi-file-excel
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in props.item.lot"
                :key="index"
                class="pointer"
                @click="getFile(item.Key, item.fileName)"
              >
                <v-list-item-title>{{ item.fileName }}</v-list-item-title>
              </v-list-item>
              <v-list-item class="pointer">
                <v-list-item-title @click="recreerLot(props.item.periode)">
                  [ {{ $t(`tools.billing.action_button.${props.item.action}`) }} ]
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <a
            href=""
            @click.prevent="getLotFile(props.item.lot, props.item.fournisseur, props.item.periode)"
          >{{ props.item.lot }}</a>
          <v-icon
            class="ml-1"
            dark
            color="primary"
            @click="creeLeLot(props.item.periode)"
          >
            mdi-reload
          </v-icon> -->
        </div>

        <!-- Erreur lot-->
        <div v-if="props.item.lot_erreur">
          <a
            href=""
            @click.prevent="getLotFile(props.item.lot_erreur, props.item.fournisseur, props.item.periode)"
          >{{ props.item.lot_erreur }}</a>
          <v-icon
            class="ml-1"
            dark
            color="orange"
          >
            mdi-alert
          </v-icon>
        </div>
      </template>

      <!--  Action-->
      <template
        slot="item.action"
        slot-scope="props"
      >
        <!--  Si la variable 'action' est défini, on affiche le bouton-->
        <template v-if="props.item.action">
          <!--  Si c'est un problème d'intégrité-->
          <v-badge
            v-if="props.item.action === 'contratsIntegrite'"
            bordered
            color="blue"
            :content="props.item.integrite"
            overlap
          >
            <v-btn
              small
              color="green"
              depressed
              @click="getIntegriteErreur(props.item)"
            >
              {{ $t(`tools.billing.action_button.${props.item.action}`) }}
            </v-btn>
          </v-badge>

          <!--  Si l'action est 'creerFactures'-->
          <v-btn
            v-if="props.item.action === 'creerFactures'"
            small
            color="green"
            depressed
            @click="createBills(props.item.periode)"
          >
            {{ $t(`tools.billing.action_button.${props.item.action}`) }}
          </v-btn>

          <!--  Si l'action est 'ActiverFactures'-->
          <v-btn
            v-if="props.item.action.startsWith('ActiverFactures')"
            small
            color="green"
            depressed
            @click="paybills"
          >
            {{ $t(`tools.billing.action_button.${props.item.action}`) }}
          </v-btn>

          <!--  Si l'action est 'paiementEnTraitement'-->
          <span
            v-if="props.item.action === 'paiementEnTraitement'"
            class=" blue--text font-weight-bold"
          >
            {{ $t(`tools.billing.action_button.${props.item.action}`) }}
          </span>

          <!--  Si l'action est 'courrielEntraitement'-->
          <span
            v-if="props.item.action === 'courrielEntraitement'"
            class=" blue--text font-weight-bold"
          >
            {{ $t(`tools.billing.action_button.${props.item.action}`) }}
          </span>

          <!--  Si l'action est 'Créer le lot'-->
          <v-btn
            v-if="props.item.action === 'creerLot'"
            small
            color="green"
            depressed
            @click="creeLeLot(props.item.periode)"
          >
            {{ $t(`tools.billing.action_button.${props.item.action}`) }}
          </v-btn>

          <template v-if="props.item.nbEnTraitement">
            <br>
            <span>{{ `${$t('contract.Bill')}(s): ${props.item.nbEnTraitement}` }}</span>
          </template>
        </template>
        <v-icon
          v-else
          color="success"
        >
          mdi-check-circle
        </v-icon>
      </template>
    </v-data-table>

    <!-- Erreur de lot pour l'UQAM-->
    <!-- XLS-->
    <template v-if="lotErrorUqam.length">
      <download-excel
        class="button"
        :data="lotErrorUqam"
        :fields="tableExport.json_fields"
        type="xls"
        :title="`01Cell UBR erreur ${tableExport.date}`"
        :footer="`@${new Date().getFullYear()} - ${$company}`"
        name="01Cell UBR erreur.xls"
      >
        xls
      </download-excel>
      <v-data-table
        :headers="tableHeadersLotErrorUqam"
        :items="lotErrorUqam"
        class="elevation-1"
      >
        <template #top>
          <v-toolbar
            color="red"
            flat
          >
            <v-toolbar-title>
              {{ $t('tools.UBR_error') }}
            </v-toolbar-title>

            <v-spacer />

            <v-dialog
              v-model="dialogLotErreurUqam"
              max-width="1000px"
            >
              <v-card>
                <validation-observer
                  ref="obs"
                  v-slot="{ invalid, handleSubmit }"
                >
                  <form>
                    <v-card-title>
                      <span class="text-h3">{{ $t('tools.UBR_Change') }}</span>
                      <br>
                      <span class="text-body-1">{{ $t('tools.UBR_Change_message') }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.oldUbr"
                              disabled
                              label="UBR"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <v-text-field
                              v-model="editedItem.compte"
                              disabled
                              :label="$t('tools.Account')"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors, valid }"
                              :name="$t('tools.New_ubr')"
                              :rules="{ required: true, numeric: true, min: 6, max: 6, codeFacturationValidationUQAMDemo: [editedItem.ubr, editedItem.compte] }"
                            >
                              <v-text-field
                                v-model="editedItem.ubr"
                                :label="$t('tools.New_ubr')"
                                maxlength="6"
                                :error-messages="errors"
                                :success="valid"
                                required
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                      >
                        {{ $t('Cancel') }}
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="invalid"
                        @click="handleSubmit(submit)"
                      >
                        {{ $t('Save') }}
                      </v-btn>
                    </v-card-actions>
                  </form>
                </validation-observer>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          slot="item.action"
          slot-scope="props"
        >
          <v-btn
            small
            color="green"
            depressed
            @click="editItem(props.item)"
          >
            {{ $t('tools.Change_UBR') }}
          </v-btn>
        </template>
      </v-data-table>
    </template>
    <!--  Contrats avec les problémes d'intégrité-->
    <template v-if="integriteErreur">
      <h2>{{ $t('tools.billing.Please_complete_the_following_contracts') }}</h2>
      <contract
        :client-contrats="integriteErreurContrats"
        @update-contrats-integrite="updateContratsIntegrite"
      />
    </template>

    <!--  Loading-->
    <v-overlay
      :absolute="absolute"
      :value="overlay"
    >
      <v-progress-circular
        :width="5"
        color="purple"
        indeterminate
      />
    </v-overlay>
  </div>
</template>
<script>
  // Components
  import Contract from '@/components/Client/Contract'
  // Services
  import FacturationsService from '@/services/01Cell/FacturationsService'
  // import S3 from '@/services/S3'
  // Other
  import Swal from 'sweetalert2'
  // Mixins
  import { files } from '@/mixins/files'
  // Components
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  import JsonExcel from 'vue-json-excel'

  export default {
    name: 'ToolsBillingManagementComponent',
    components: {
      Contract,
      ValidationObserver,
      ValidationProvider,
      downloadExcel: JsonExcel,
    },
    mixins: [files],
    data () {
      return {
        sommaireFacturation: [],
        overlay: false,
        absolute: true,
        integriteErreur: false,
        integriteErreurContrats: [],
        dialogLotErreurUqam: false,
        editedItem: {},
        defaultItem: {
          oldUbr: null,
          ubr: null,
          id: null,
        },
        tableHeaders: [
          { text: this.$i18n.t('tools.billing.Period'), value: 'periode', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.Provider'), value: 'fournisseur', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Quantity'), value: 'qt', sortable: true, class: 'text-h4 primary--text' },
          { text: `$ ${this.$i18n.t('contract.bill.Total_before_tax')}`, value: 'montant_at', sortable: true, class: 'text-h4 primary--text' },
          { text: '$ TPS', value: 'tps', sortable: true, class: 'text-h4 primary--text' },
          { text: '$ TVQ', value: 'tvq', sortable: true, class: 'text-h4 primary--text' },
          { text: '$ Total', value: 'montant_total', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Lot', value: 'lot', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Action', value: 'action', sortable: false, align: 'center', class: 'text-h4 primary--text' },
        ],
        lotErrorUqam: [],
        tableHeadersLotErrorUqam: [
          { text: this.$i18n.t('tools.billing.Period'), value: 'periode', class: 'text-h4 primary--text' },
          { text: this.$i18n.t('order.operation.Bill_number'), value: 'id', class: 'text-h4 primary--text' },
          { text: 'Client', value: 'Client.username', class: 'text-h4 primary--text' },
          {
            text: 'UBR',
            align: 'start',
            value: 'code_facturation',
            class: 'text-h4 primary--text',
          },
          { text: this.$i18n.t('tools.Account'), value: 'compte', class: 'text-h4 primary--text' },
          { text: 'Total', value: 'montant_total', class: 'text-h4 primary--text' },
          { text: this.$i18n.t('order.Name_account'), value: 'responsableUbrErreur', class: 'text-h4 primary--text' },
          { text: 'Action', value: 'action', align: 'right', sortable: false, class: 'text-h4 primary--text' },
        ],
        tableExport: {
          json_fields: {
            Période: 'periode',
            'Numéro de facture': 'id',
            UBR: 'code_facturation',
            Compte: 'compte',
            Montant: 'montant_total',
            Responsable: 'responsableUbrErreur',

          },
        },
      }
    },

    computed: {
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    mounted () {
      this.getSommaireFacturation()
    },

    methods: {
      async getFile (key, fileName) {
        this.downloadFileFromS3(key, 'cell-facturation', fileName)
      },
      async getSommaireFacturation () {
        // Start Spinner
        this.overlay = true
        try {
          const sommaireFacturationPromise = FacturationsService.sommaire()
          const ubrErreurPromise = FacturationsService.getErreurUbr()
          const [sommaireFacturation, ubrErreur] = await Promise.all([sommaireFacturationPromise, ubrErreurPromise])

          this.sommaireFacturation = sommaireFacturation
          this.getDataMapLotErreur(ubrErreur)
          // console.log(this.sommaireFacturation)
          // Stop Spinner
          this.overlay = false
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      async getIntegriteErreur (value) {
        try {
          this.integriteErreurContrats = await FacturationsService.integrite()
          // On ouvre les contrats qui ont un problème d'intégrité
          this.integriteErreur = true
        } catch (error) {
          console.log(error)
        }
      },
      async createBills (periode) {
        // Start Spinner
        this.overlay = true
        try {
          await FacturationsService.creerfactures(periode)
          this.getSommaireFacturation()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      async paybills () {
        // Start Spinner
        this.overlay = true
        try {
          await FacturationsService.facturationMensuelle()
          this.getSommaireFacturation()
          // On update le erreur de facturation
          this.$emit('update-billing-error')
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      async creeLeLot (periode) {
        // On regarde si il y a encore des erreurs dans les ubr de cette période
        const findPeriod = this.lotErrorUqam.some(x => x.periode === periode)
        if (findPeriod) {
          Swal.fire({
            icon: 'error',
            title: 'Attention',
            text: this.$i18n.t('tools.UBR_error'),
            heightAuto: false, // Put this each time. Prevents the html footer from being higher
          })
        } else {
          // Start Spinner
          this.overlay = true
          try {
            const response = await FacturationsService.creerlot(periode)
            this.checkLotErreur(response)
          } catch (error) {
            console.log(error)
            // Stop Spinner
            this.overlay = false
          }
        }
      },
      recreerLot (periode) {
        Swal.fire({
          title: this.$i18n.t('Are_you_sure'),
          text: `${this.$i18n.t('tools.billing.action_button.recreerLot')} ?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('Confirm'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Start Spinner
            this.overlay = true
            try {
              const response = await FacturationsService.creerlot(periode)
              this.checkLotErreur(response)
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.overlay = false
            }
          }
        })
      },
      checkLotErreur (response) {
        if (response.success) {
          // Si le lot a passé, on enlève les erreurs
          this.lotErrorUqam = []
          // Swal et download du lot
          // this.downloadLot(response.message)
        } else {
          if (response.message === 'erreur ubr') {
            Swal.fire({
              icon: 'error',
              title: 'Attention',
              text: this.$i18n.t('tools.swal.text.Batch_error'),
              heightAuto: false, // Put this each time. Prevents the html footer from being higher
            })
          }
          // On mets les erreurs dans le array 'this.lotErrorUqam'
          this.getDataMapLotErreur(response.data)
        }
        this.getSommaireFacturation()
      },
      getLotFile (key, provider, period) {
        this.downloadFileFromS3(`fournisseur/${provider}/${period}/${key}`, 'cell-facturation', key)
        // try {
        //   const params = {
        //     bucket: 'cell-facturation',
        //     key: `fournisseur/${provider}/${period}/${key}`,
        //     expires: 60,
        //   }

        //   const getSignedUrl = await S3.getSignedUrl(params)
        //   window.open(getSignedUrl, '_blank')
        // } catch (error) {
        //   console.log(error)
        // }
      },
      async updateContratsIntegrite () {
        // Start Spinner
        this.overlay = true
        // On ferme le composant 'Contract' pour faire un rafraichissement pour les contrats
        this.integriteErreur = false
        try {
          const sommaireFacturationPromise = FacturationsService.sommaire()
          const integriteErreurContratsPromise = FacturationsService.integrite()
          const [sommaireFacturation, integriteErreurContrats] = await Promise.all([sommaireFacturationPromise, integriteErreurContratsPromise])
          this.sommaireFacturation = sommaireFacturation

          if (integriteErreurContrats.length) {
            // On remets les problèmes d'intégrité
            this.integriteErreurContrats = integriteErreurContrats

            // On ouvre le composant 'Contrat'
            this.integriteErreur = true
          }

          // Stop Spinner
          this.overlay = false
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      getDataMapLotErreur (lotErrorUqam) {
        this.lotErrorUqam = lotErrorUqam.map(item => {
          item.compte = item.categorie === 1 ? '57012' : '54914'
          // Pour l'édition du UBR
          item.editState = false
          // On garde l'ancien UBR si l'utilisateur veut le changer pour le nouveau UBR
          item.oldUbr = item.code_facturation
          return item
        })
      },
      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.editedItem.ubr = null
        this.dialogLotErreurUqam = true
      },

      close () {
        this.dialogLotErreurUqam = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },

      async submit () {
        // Start Spinner
        this.$loading.show()
        try {
          await FacturationsService.updateLotErrorUbr(this.editedItem.oldUbr, this.editedItem.ubr)
          const response = await FacturationsService.getErreurUbr()
          this.getDataMapLotErreur(response)
          this.close()
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
        }
      },
    },
  }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }

/* CSS */
.button {
  background-image: linear-gradient(#f7f8fa ,#e7e9ec);
  border-color: #adb1b8 #a2a6ac #8d9096;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  box-shadow: rgba(255,255,255,.6) 0 1px 0 inset;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember",Arial,sans-serif;
  font-size: 14px;
  height: 29px;
  font-size: 16px;
  outline: 0;
  overflow: hidden;
  padding: 0 14px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button:active {
  border-bottom-color: #a2a6ac;
}

.button:active:hover {
  border-bottom-color: #a2a6ac;
}

.button:hover {
  border-color: #a2a6ac #979aa1 #82858a;
}

.button:focus {
  border-color: #e77600;
  box-shadow: rgba(228, 121, 17, .5) 0 0 3px 2px;
  outline: 0;
}
</style>
