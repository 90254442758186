  // Service
  import FacturationsService from '@/services/01Cell/FacturationsService'
  import FacturesService from '@/services/01Cell/FacturesService'
  import StripePaiementService from '@/services/STRIPE/StripePaiementService'
  import StripeCardService from '@/services/STRIPE/StripeCardService'
  import StripeCustomerService from '@/services/STRIPE/StripeCustomerService'
  import ClientsService from '@/services/01Cell/ClientsService'
  // Other
  import Vue from 'vue'
  import VueHtmlToPaper from 'vue-html-to-paper'
  const options = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes',
    ],
    styles: [
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      'https://unpkg.com/kidlat-css/css/kidlat.css',
    ],
  }

  Vue.use(VueHtmlToPaper, options)

export const FacturePaiement = {
    data () {
      return {
        paimentUni: process.env.VUE_APP_PAIEMENT_UNI,
        dialogPaiement: false,
        dialogInvoiceUnPaid: false,
        recuFormat: '',
        dialogInvoiceUnPaidMessage: '',
        paiementFacture: null,
      }
    },

    computed: {
    },

    methods: {
      async openDialogPaiementFacture (factureId) {
        this.printMe = `printMe_${factureId}`
        // Start Spinner
        this.$loading.show()
        try {
          const html = await FacturationsService.getFactureHtml(factureId)
          // // Traduction du paiement
          this.recuFormat = html
          this.dialogPaiement = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          // Stop Spinner
          this.$loading.hide()
          console.log(error)
        }
      },
      print () {
        // Pass the element id here
        this.$htmlToPaper(this.printMe)
      },
      async payerFacture (facture) {
          try {
            // Mode automatique seulement. On paye par token
            if (this.parameterModePaiement === '2') {
              this.payerFactureAutomatique(facture)
            } else if (this.parameterModePaiement === '1') {
              // Mode manuel seulement
              this.payerFactureManuelle(facture)
            } else if (this.parameterModePaiement === '3') {
              // Mode manuel et automatique
              // Si inscription paiement par token
              if (facture.InscriptionPaiementId) {
                this.payerFactureAutomatique(facture)
              } else {
                this.payerFactureManuelle(facture)
              }
            }
          } catch (error) {
            // Stop Spinner
            this.$loading.hide()
            console.log(error)
          }
      },
      async payerFactureAutomatique (facture) {
        // Start Spinner
        this.$loading.show()
        try {
          const response = await FacturationsService.payerFactureManuelleToken(facture.id)
          this.payerFactureSuccess(response)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
            this.payerFactureError(facture.id)
            console.log(error)
            // Stop Spinner
            this.$loading.hide()
        }
      },
      async payerFactureManuelle (facture) {
          // On mets les détails de la facture pour le dialog de paiement
          this.paiementFacture = facture
          // Si c'est une facture de catégorie 2 (frais de retard), on s'assure que toutes les factures de catégorie 1 soit fini de payer pour ce contrat
          if (facture.categorie === 2) {
              // On trouve le contrat de la facture
              const findContrat = this.client.Contrats.find(item => item.id === facture.ContratId)
              // Dans les factures mensuelles, on s'assure que le client est payé toutes ses factures mensuelle
              const findInvoiceUnPaid = findContrat.Factures.some(item => item.categorie === 1 && !item.paiementId && item.statut === 1)
              if (findInvoiceUnPaid) {
                  this.dialogInvoiceUnPaid = true
                  this.dialogInvoiceUnPaidMessage = `${this.$i18n.t('contract.bill.warning.You_must_first_pay_all_your_invoices_for_the_contract')} <b>${`(${findContrat.no.slice(0, 3)}) ${findContrat.no.slice(3, 6)}-${findContrat.no.slice(6)}`}</b> ${this.$i18n.t('contract.bill.warning.before_you_can_pay_this_invoice')}.`
                  return
              }
          }

          if (this.parameterFournisseurPaiement === 'paiementuni') {
            // Pour anglais, il faut mettre 'an'
            const language = this.$i18n.t('Language') === 'fr' ? 'fr' : 'an'
            const http = `https://www.paiementuni.com/uqam/cc/cc-form.cfm?tps=${facture.tps}&tvp=${facture.tvq}&tvh=0.00&MontantSansTaxe=${facture.montant_at}&Montant=${facture.montant_total}&SiteID=${this.paimentUni}&NoTransaction=${facture.id}&MarchandID=30&Langue=_${language}`
            // Ex: https://www.paiementuni.com/uqam/cc/cc-form.cfm?tps=1&tvp=1&tvh=0.00&MontantSansTaxe=5&Montant=7&SiteID=667&NoTransaction=122222&MarchandID=30&Langue=_fr
            window.location.href = http
          }
          if (this.parameterFournisseurPaiement === 'paysafe') {
            this.paysafePaymentsDialog = true
          }
          if (this.parameterFournisseurPaiement === 'globalpayments') {
            this.paymentGlobalPaymentsDialog = true
          }
          if (this.parameterFournisseurPaiement === 'payfacto') {
            // Get form
            await this.getForm() // Fonction dans le mixins Payfacto
            this.form.useRecaptcha = false
            this.payFactoDialog = true
          }
          if (this.parameterFournisseurPaiement === 'stripe') {
            // Start Spinner
            this.$loading.show()
            try {
              // On recherche chez Stripe si la facture a pas déjà été payer
              const response = await StripePaiementService.searchByFactureId(facture.id)
              // On mets à jour la facture
              if (response.payer) {
                this.payerFactureSuccess(response.facture)
              } else {
                // Récupération du client
                const client = await ClientsService.view(facture.ClientId)
                // url de 01cell
                const baseUrl = window.location.origin

                // Création de la session
                // Si le client n'a pas de customer id chez Stripe, on en crée un
                if (!client.paiement_id) {
                  const language = client.langue === 'fr' ? 'fr-CA' : 'en'
                  const customer = await StripeCustomerService.save({
                    email: client.email,
                    name: `${client.prenom} ${client.nom}`,
                    preferred_locales: [language],
                    // address: {
                    //   line1: this.submitForm.adresse,
                    //   postal_code: this.submitForm.code_postal,
                    //   city: this.submitForm.ville,
                    //   state: this.submitForm.province,
                    //   country: 'CA',
                    // },
                    metadata: {
                      username: null, // Sera updater dans le backend. Important, on recherche un customer par ce champs
                      clientId: null, // Sera updater dans le backend. Important, on recherche un customer par ce champs
                    },
                  })
                  // On mets le id dans le champs 'paiement_id'
                  client.paiement_id = customer.id
                }

                const response = await StripeCardService.createSession({
                  session: {
                      customer: client.paiement_id,
                      billing_address_collection: 'Sera défini dans le backend avec le paremètre 31', // AVS
                      locale: client.langue === 'fr' ? 'fr-CA' : 'en',
                      mode: 'payment',
                      ui_mode: 'hosted',
                      line_items: [
                        {
                          price_data: {
                            currency: 'cad', // Remplace par la devise de ton choix
                            product_data: {
                              name: 'Total',
                            },
                            unit_amount: facture.montant_total * 100, // Montant en centimes (5000 = 50.00 USD)
                          },
                          quantity: 1, // Quantité achetée
                        },
                        // {
                        //   price_data: {
                        //     currency: 'cad', // Remplace par la devise de ton choix
                        //     product_data: {
                        //       name: this.$i18n.t('contract.bill.Total_before_tax'),
                        //     },
                        //     unit_amount: facture.montant_at * 100, // Montant en centimes (5000 = 50.00 USD)
                        //   },
                        //   quantity: 1, // Quantité achetée
                        // },
                        // {
                        //   price_data: {
                        //     currency: 'cad', // Remplace par la devise de ton choix
                        //     product_data: {
                        //       name: 'TPS',
                        //     },
                        //     unit_amount: facture.tps * 100, // Montant en centimes (5000 = 50.00 USD)
                        //   },
                        //   quantity: 1, // Quantité achetée
                        // },
                        // {
                        //   price_data: {
                        //     currency: 'cad', // Remplace par la devise de ton choix
                        //     product_data: {
                        //       name: 'TVQ',
                        //     },
                        //     unit_amount: facture.tvq * 100, // Montant en centimes (5000 = 50.00 USD)
                        //   },
                        //   quantity: 1, // Quantité achetée
                        // },
                      ],
                      // payment_method_types: ['card'], // Inclut Apple Pay
                      metadata: {
                        clientId: client.id,
                        state: 'pay',
                        factureId: facture.id,
                      },
                      payment_intent_data: { // Important. Metadata qui seront dans le paiement intent (pi)
                        metadata: {
                          total: facture.montant_total,
                          clientId: client.id,
                          username: client.username,
                          factureId: facture.id,
                          periode: facture.periode,
                          categorie: facture.categorie, // 1= facture mensuel 2= facture unique
                        },
                      },
                      success_url: `${baseUrl}?stripeSessionId={CHECKOUT_SESSION_ID}`,
                      cancel_url: `${baseUrl}?canceled=true`,
                  },
                  idempotencyKey: `payerFacture${facture.id}`,
                })
                // Redirection vers Stripe. Si succès voir le mounted dans le dashboard
                console.log(response)
                window.location.href = response.url
              }
              // Stop Spinner
              this.$loading.hide()
            } catch (error) {
              console.log(error)
              // Stop Spinner
              this.$loading.hide()
            }
          }
      },
      payerFactureSuccess (facture) {
        // On cherche le id de la facture pour mettre le paiementId
        const findFactureIndex = this.factures.findIndex(x => x.id === facture.id)
        // Mise à jour de la facture
        this.factures[findFactureIndex].paiement_message = null
        this.factures[findFactureIndex].paiementId = facture.paiementId
        // On ferme les dialogs de paiement
        this.paymentGlobalPaymentsDialog = false
        this.paysafePaymentsDialog = false
        this.payFactoDialog = false

        // Pour les factures autre, on mets à jour le bouton Autres factures dans le Dashboard
        if (facture.categorie !== 1) this.payerFactureUpdate()
      },
      async payerFactureError (factureId) {
        try {
          const facture = await FacturesService.view(factureId)
          // On cherche le id de la facture pour mettre le paiementId
          const findFactureIndex = this.factures.findIndex(x => x.id === factureId)
          // Mise à jour de la facture
          this.factures[findFactureIndex].paiement_message = facture.paiement_message
          this.factures[findFactureIndex].paiementId = facture.paiementId
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      returnToContract () {
        this.dialogInvoiceUnPaid = false
        this.$emit('return-to-contract')
      },
    },
}
