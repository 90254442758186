<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-sticker-text-outline"
      :title="$t('sidebar.report')"
      class="px-5 py-3"
    >
      <v-row v-if="selectResponsableUbr.ubr.length || selectResponsableUbr.group.length">
        <!-- Bouton-->
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn-toggle
            v-model="toggleButton"
            mandatory
          >
            <!--Rapport pour le client -->
            <v-btn
              value="rapport"
              text
              color="blue"
              @click="toggleButton = 'rapport'"
            >
              <v-icon
                center
                class="mr-1"
                color="blue"
              >
                mdi-phone
              </v-icon>

              {{ $t('sidebar.my_contracts') }}
            </v-btn>

            <!-- UBR Responsable-->
            <v-btn
              value="responsableUBR"
              text
              color="blue"
              @click="toggleButton = 'responsableUBR'"
            >
              <v-icon
                center
                class="mr-1"
                color="blue"
              >
                mdi-file-document-outline
              </v-icon>
              {{ $t('Administrator') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col
          v-if="(selectResponsableUbr.ubr.length && selectResponsableUbr.group.length) && toggleButton === 'responsableUBR'"
          cols="12"
          sm="12"
        >
          <v-radio-group
            v-model="radioUBROrGroup"
            row
          >
            <v-radio
              label="UBR"
              value="ubr"
            />
            <v-radio
              :label="$t('Group')"
              value="group"
            />
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <!-- Factures du client-->
        <template v-if="toggleButton === 'rapport'">
          <!-- Contrat-->
          <v-col
            cols="3"
          >
            <v-select
              v-model="selectedClientFacture.contrat"
              :items="selectClientFacture.contrats"
              item-text="text"
              item-value="id"
              :label="$t('Contract')"
            />
          </v-col>

          <!-- Années-->
          <v-col
            cols="3"
          >
            <v-select
              v-model="selectedClientFacture.annee"
              :items="selectClientFacture.annees"
              :label="$t('Year')"
            />
          </v-col>

          <v-col
            cols="3"
          >
            <v-btn
              :disabled="!selectedClientFacture.annee || !selectedClientFacture.contrat"
              class="ml-2 mt-2"
              color="success"
              :title="$t('search')"
              @click="searchClientFacture"
            >
              {{ $t('Show_report') }}
            </v-btn>
          </v-col>

          <!-- Table-->
          <v-col
            cols="12"
          >
            <!-- Table-->
            <rapport-table-component
              :table="tableClientFacture"
              :table-headers="tableHeadersFactureClient"
            />
          </v-col>
        </template>

        <!-- UBR Responsable-->
        <template v-if="toggleButton === 'responsableUBR'">
          <!-- Periode-->
          <v-col
            cols="3"
          >
            <v-select
              v-model="selectedResponsableUbr.periode"
              :items="selectResponsableUbr.periode"
              item-text="periode"
              item-value="periode"
              :label="$t('tools.billing.Period')"
            />
          </v-col>

          <!-- UBR-->
          <v-col
            v-if="radioUBROrGroup === 'ubr'"
            cols="3"
          >
            <v-select
              v-model="selectedResponsableUbr.ubr"
              :items="selectResponsableUbr.ubr"
              item-text="ubr"
              item-value="ubr"
              label="UBR"
            />
          </v-col>

          <!-- Groupe-->
          <v-col
            v-if="radioUBROrGroup === 'group'"
            cols="3"
          >
            <v-select
              v-model="selectedResponsableUbr.group"
              :items="selectResponsableUbr.group"
              item-text="Groupe.description"
              item-value="GroupeId"
              persistent-hint
              single-line
              :label="$t('Group')"
            />
          </v-col>

          <v-col
            cols="3"
          >
            <v-btn
              :disabled="disabledAdminSearch"
              class="ml-2 mt-2"
              color="success"
              :title="$t('search')"
              @click="searchResponsableUbr"
            >
              {{ $t('Show_report') }}
            </v-btn>
          </v-col>

          <!-- Table-->
          <v-col
            cols="12"
          >
            <!-- Table-->
            <rapport-table-component
              :table="tableClientResponsableUbr"
              :table-headers="tableHeadersResponsableUbr"
            />
          </v-col>
        </template>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
  // Component
  import RapportTableComponent from '@/components/Rapport/RapportTable'
  // Services
  import Report from '@/services/01Cell/Report'
  // Other
  import { mapState } from 'vuex'

  export default {
    name: 'Rapport',
    components: {
      RapportTableComponent,
    },
    data () {
      return {
        toggleButton: 'rapport',
        radioUBROrGroup: 'group',
        selectResponsableUbr: {
          ubr: [],
          group: [],
          periode: [],
        },
        selectedResponsableUbr: {
          ubr: null,
          group: null,
          periode: null,
        },
        tableClientFacture: [],
        // Factures du client
        selectClientFacture: {
          annees: [],
          contrats: [],
        },
        selectedClientFacture: {
          annee: null,
          contrat: null,
        },
        tableHeadersFactureClient: [
          { text: '', value: 'edit', sortable: false, class: 'text-h4 primary--text', width: '120px' },
          { text: this.$i18n.t('Name'), value: 'clientPrenomNom', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.no'), value: 'noContrat', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Category'), value: 'categorie', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('tools.billing.Period'), value: 'periode', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.bill.Total_before_tax'), value: 'montant_at', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.bill.Credit_applied'), value: 'credit_applique', sortable: true, class: 'text-h4 primary--text' },
          { text: 'TPS', value: 'tps', sortable: true, class: 'text-h4 primary--text' },
          { text: 'TVQ', value: 'tvq', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Total', value: 'montant_total', sortable: true, class: 'text-h4 primary--text' },
        ],
        tableClientResponsableUbr: [],
        tableHeadersResponsableUbr: [
          { text: '', value: 'edit', sortable: false, class: 'text-h4 primary--text', width: '120px' },
          { text: this.$i18n.t('Name'), value: 'clientPrenomNom', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.no'), value: 'noContrat', sortable: true, class: 'text-h4 primary--text' },
          { text: 'UBR', value: 'code_facturation', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Category'), value: 'categorie', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('tools.billing.Period'), value: 'periode', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.bill.Total_before_tax'), value: 'montant_at', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.bill.Credit_applied'), value: 'credit_applique', sortable: true, class: 'text-h4 primary--text' },
          { text: 'TPS', value: 'tps', sortable: true, class: 'text-h4 primary--text' },
          { text: 'TVQ', value: 'tvq', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Total', value: 'montant_total', sortable: true, class: 'text-h4 primary--text' },
        ],
      }
    },

    computed: {
      ...mapState(['userInfo']),
      disabledAdminSearch () {
        if (!this.selectedResponsableUbr.periode) return true
        if (!this.selectedResponsableUbr.ubr && !this.selectedResponsableUbr.group) return true
        return false
      },
    },

    async mounted () {
      // Start Spinner
      this.$loading.show()
      try {
        // Voir si l'utilisateur peut voir un UBR (Requête dans Gestion réseau)
        // CodeMs ou matricule numérique
        const responsableUbrPromise = await Report.viewResponsable()
        // Rapport du client
        const selectClientAnneesContratsPromise = await Report.view()
        const [responsableUbr, selectClientAnneesContrats] = await Promise.all([responsableUbrPromise, selectClientAnneesContratsPromise])
        this.selectResponsableUbr = responsableUbr

        // Pour les groupes, ont mets le code et la description
        this.selectResponsableUbr.group = this.selectResponsableUbr.group.map(item => {
          // On mets le code et la description
          if (item.Groupe) item.Groupe.description = `${item.Groupe.code} (${item.Groupe.description})`
          return item
        })
        this.selectClientFacture = selectClientAnneesContrats
        // Si il y a des UBR, on mets la recherche UBR
        if (this.selectResponsableUbr.ubr.length) this.radioUBROrGroup = 'ubr'
        // Stop Spinner
        this.$loading.hide()
      } catch (error) {
        console.log(error)
        // Stop Spinner
        this.$loading.hide()
      }
    },

    methods: {
      async searchClientFacture () {
        // Start Spinner
        this.$loading.show()
        try {
          this.tableClientFacture = await Report.clientFacture(this.selectedClientFacture.annee, this.selectedClientFacture.contrat)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async searchResponsableUbr () {
        // Start Spinner
        this.$loading.show()
        try {
          // Envoie du group ou du UBR
          const value = this.radioUBROrGroup === 'group' ? this.selectedResponsableUbr.group : this.selectedResponsableUbr.ubr
          this.tableClientResponsableUbr = await Report.responsableUbrFacture(this.selectedResponsableUbr.periode, value, this.radioUBROrGroup)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
