<template>
  <v-row>
    <v-col
      cols="10"
      offset-md="1"
    >
      <v-text-field
        v-model="submitForm"
        placeholder="Recherche dans les champs matricule, code ms et courriel"
      >
        <v-btn
          slot="append"
          :disabled="submitForm.length < 5"
          @click="getinfo"
        >
          {{ $t('Search') }}
        </v-btn>
      </v-text-field>
    </v-col>

    <v-col
      cols="12"
    >
      <v-data-table
        :headers="tableHeaders"
        :items="table"
      />
    </v-col>
  </v-row>
</template>
<script>
  // Service
  import ToolsService from '@/services/01Cell/ToolsService'
  // Other
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'ToolsInfoUtilisateur',
    data () {
      return {
        submitForm: '',
        table: [],
        tableHeaders: [
          { text: 'Matricule', value: 'MATRICULE', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Statut', value: 'URGCOD', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Nom', value: 'NOM', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Prénom', value: 'PRENOM', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Code usager', value: 'CODE_USAGER', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Courriel', value: 'COURRIEL', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Titre', value: 'TITRE', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Unité', value: 'UNODSC', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Affectation', value: 'TAFDSC', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Date de début', value: 'DATE_DEB', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Date de fin', value: 'DATE_FIN', sortable: true, class: 'text-h4 primary--text' },
        ],
      }
    },

    methods: {
      async getinfo () {
        // Stop Spinner
        this.$loading.show()
        try {
          const table = await ToolsService.infoUser(this.submitForm)
          this.table = table.map(item => {
            item.DATE_DEB = item.DATE_DEB ? format(parseISO(item.DATE_DEB), 'yyyy-MM-dd') : null
            item.DATE_FIN = item.DATE_FIN ? format(parseISO(item.DATE_FIN), 'yyyy-MM-dd') : null
            return item
          })
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
