import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import ParametersService from '@/services/01Cell/ParametersService'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/my_portal',
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard (Contrat)
        {
          name: 'Dashboard',
          path: 'my_portal',
          meta: {
            translate: 'sidebar.my_portal', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            isPublic: true,
            roles: ['*'],
          },
          component: () => import('@/views/dashboard/Dashboard'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        // Dashboard (Responsable)
        {
          name: 'DashboardResponsable',
          path: 'my_portal_responsable',
          meta: {
            translate: 'sidebar.my_portal', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['user'],
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/Dashboard'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        // Rapport
        {
          name: 'rapport',
          path: 'rapport',
          meta: {
            translate: 'sidebar.report', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['user'],
            requiresAuth: true,
          },
          component: () => import('@/views/rapport/Rapport'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        // Chart
        {
          name: 'DashboardChart',
          path: 'dashboard',
          meta: {
            translate: 'sidebar.dashboard', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
          },
          component: () => import('@/views/chart/Chart'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        // Profile
        {
          name: 'profile',
          path: 'profile',
          meta: {
            translate: 'sidebar.profile', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['user'],
            requiresAuth: true,
          },
          component: () => import('@/views/profile/globalPayments/Profile'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        // Opération
        {
          name: 'operation-client',
          path: 'operation/client',
          meta: {
            translate: 'sidebar.operation', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
          },
          component: () => import('@/views/operation/OperationClient.vue'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        {
          name: 'operation-contrat',
          path: 'operation/contrat',
          meta: {
            translate: 'sidebar.operation', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
          },
          component: () => import('@/views/operation/OperationContrat'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        {
          name: 'operation-commande',
          path: 'operation/commande',
          meta: {
            translate: 'sidebar.operation', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
          },
          component: () => import('@/views/operation/OperationCommande'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        {
          name: 'operation-facture',
          path: 'operation/facture',
          meta: {
            translate: 'sidebar.operation', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
            requiresAuth: true,
          },
          component: () => import('@/views/operation/OperationFacture'),
        },
        {
          name: 'operation-tools',
          path: 'operation/tools',
          meta: {
            translate: 'sidebar.operation', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
          },
          component: () => import('@/views/operation/OperationTools'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        // Autre
        {
          name: 'other-parameters',
          path: 'other/parameters',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
          },
          component: () => import('@/views/operation/OperationParameters'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        {
          name: 'other-messages',
          path: 'other/messages',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
          },
          component: () => import('@/views/operation/OperationMessages'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        {
          name: 'other-list',
          path: 'other/list',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin'],
          },
          component: () => import('@/views/operation/OperationList'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        {
          name: 'other-toggles',
          path: 'other/toggles',
          meta: {
            translate: 'sidebar.other', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin', 'niveau0'],
          },
          component: () => import('@/views/operation/OperationToggles'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        // Users Management
        {
          name: 'usersmanagement-roles',
          path: 'usersmanagement/roles',
          meta: {
            translate: 'sidebar.usermanagement', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin'],
          },
          component: () => import('@/views/userManagement/uqam/UsersManagementRoles.vue'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        {
          name: 'usersmanagement-roles-users',
          path: 'usersmanagement/roles/users/:roleId',
          meta: {
            translate: 'sidebar.usermanagement', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin'],
          },
          component: () => import('@/views/userManagement/uqam/UsersManagementRolesUsers.vue'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
        {
          name: 'usersmanagement-users',
          path: 'usersmanagement/users',
          meta: {
            translate: 'sidebar.usermanagement', // Si il n'y a pas de d'objet translate, prends l'object nom. Voir le composant 'AppBar.vue'
            roles: ['admin'],
          },
          component: () => import('@/views/userManagement/uqam/UsersManagementUsers.vue'),
          beforeEnter: (to, from, next) => {
            checkBeforeEnter(to, from, next)
          },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/pages/PagesHome'),
      children: [
        {
          name: 'Login',
          meta: {
            isPublic: true,
            roles: ['*'],
          },
          path: 'login',
          component: () => import('@/views/pages/Login'),
          beforeEnter: (to, from, next) => {
            // Si le l'utilisateur est déjà authentifié, on le redirige vers la page principal
            if (store.getters['oidcStore/oidcIsAuthenticated']) {
              next('/')
            } else {
              next()
            }
          },
        },
        {
          name: 'unauthorized',
          meta: {
            isPublic: true,
            roles: ['*'],
          },
          path: 'unauthorized',
          component: () => import('@/views/pages/Unauthorized'),
        },
        {
          name: 'error500',
          meta: {
              isPublic: true,
              roles: ['*'],
          },
          path: '/error500',
          component: () => import('@/views/pages/Error500'),
        },
        {
          name: 'oidcCallback',
          meta: {
              isOidcCallback: true,
              isPublic: true,
              roles: ['*'],
          },
          path: '/oidc-callback', // Needs to match redirect_uri in you oidcSettings
          component: () => import('@/views/OIDC/OidcCallback.vue'),
      },
      {
          name: 'oidcCallbackError',
          meta: {
              isPublic: true,
              roles: ['*'],
          },
          path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
          component: () => import('@/views/OIDC/OidcCallbackError.vue'),
      },
      {
          name: 'protected',
          meta: {
              roles: ['admin'],
          },
          path: '/protected',
          component: () => import('@/views/OIDC/Protected.vue'),
      },
      ],
    },
    // Not found route
    {
      path: '*',
      redirect: '/',
    },
  ],
  scrollBehavior () {
      return { x: 0, y: 0 }
  },
})

// Check for login
router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))

const checkBeforeEnter = async (to, from, next) => {
  // Si l'utilisateur n'est pas loggé on l'envoi vers la page d'authentification
  if (!store.getters['oidcStore/oidcIsAuthenticated']) {
    next('/login')
    return
  }

  // Load parameters
  await loadParameters(next)

  // If roles of routes is *, client can go in route
  if (to.meta.roles.find(x => x === '*')) {
      next()
      return
  }

  // If User is not logged
  if (store.state.oidcStore.user) {
      // Check roles of the user and compare to roles in route
      const found = store.state.userInfo.info.roles.some(r => to.meta.roles.indexOf(r) >= 0)
      // Role not find
      if (!found) {
          // Go to unauthorized page. If if to not loop redirecting
          if (to.path !== '/unauthorized') {
              next('/unauthorized')
          }
      } else {
          next()
      }
  }
}

async function loadParameters (next) {
  try {
    // On regarde si les paramètres sont déjà là. Si il n'y sont pas ont l'ai récupère
    if (!store.state.parameters.parameters.length) {
        // Liste des paramètres que l'on mets dans le vuex pour récupéré partout dans le code
        const parameters = await ParametersService.listAll()
        store.commit('parameters/setParameters', parameters)
    }
  } catch (error) {
    console.log(error)
    next('/error500')
  }
}

export default router
