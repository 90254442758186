const dialog = {
  strict: true,
  namespaced: true,
  state: {
    info: {
      show: false,
      message: '',
      print: false,
      html: false,
      iconState: false,
      iconColor: null,
      iconName: null,
    },
  },
  getters: {
    getDialogInfo (state) {
      return state.info
    },
  },
  mutations: {
    showDialog (state, payload) {
      state.info = payload
      state.info.show = true
    },
    hideDialog (state) {
      state.info.show = false
    },
  },
  actions: {

  },
}

export default dialog
