<template>
  <v-row>
    <v-col cols="12">
      <validation-observer
        ref="obs"
        v-slot="{ handleSubmit }"
      >
        <form>
          <base-material-card
            inline
            :icon="createEditInfo.icon"
            :title="`${createEditInfo.title}: [${clientId}] Client`"
            color="primary"
            class="px-5 py-3"
          >
            <v-subheader class="text-h4 mt-3">
              Information
            </v-subheader>

            <!--  Username-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('client.Username') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Username')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.username"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Type-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                Type*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Type"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.type"
                    :items="types"
                    item-text="item"
                    item-value="valeur"
                    persistent-hint
                    single-line
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Sexe-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('client.Sex') }}*
              </v-col>

              <!-- <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Sex')"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.sexe"
                    :items="sexes"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col> -->
            </v-row>

            <!--  Nom-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('Name') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('Name')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.nom"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Prénom-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('FirstName') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('FirstName')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.prenom"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Téléphone principal-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('client.Primary_phone') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Primary_phone')"
                  :rules="{ required: true, regex: regexPhoneNumber }"
                >
                  <v-text-field
                    v-model="submitForm.telephone1"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Téléphone secondaire-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('profile.Extension') }}
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('profile.Extension')"
                  :rules="{ required: false, numeric:true }"
                >
                  <v-text-field
                    v-model="submitForm.telephone2"
                    :error-messages="errors"
                    :success="valid"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!--  Courriel-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('client.Email') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Email')"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="submitForm.email"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!-- <v-subheader class="display-1 mt-3">
              {{ $t('client.Address') }}
            </v-subheader> -->

            <!--  Adresse-->
            <!-- <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('client.Address') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.Address')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.adresse"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row> -->

            <!--  Ville-->
            <!-- <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('client.City') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.City')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.ville"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row> -->

            <!--  Province-->
            <!-- <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                Province*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Province"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.province"
                    :items="provinces"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row> -->

            <!--  Code Postale-->
            <!-- <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('client.ZIP_code') }}*
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('client.ZIP_code')"
                  :rules="{ required: true, regex: regexZipCode }"
                >
                  <v-text-field
                    v-model="submitForm.code_postal"
                    :error-messages="errors"
                    :success="valid"
                    placeholder="H0H 0H0"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row> -->

            <!--  Administration comptable-->
            <!-- <v-subheader class="display-1 mt-3">
              {{ $t('client.Accounting_administration') }}
            </v-subheader>

            <template v-if="$company === 'uqam'">
              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  {{ $t('Administrator') }}
                </v-col>

                <v-col cols="8">
                  <v-checkbox
                    v-model="submitForm.admin"
                  />
                </v-col>
              </v-row>

              <v-row
                align="center"
                dense
              >
                <v-col
                  class="text-right body-1 grey--text mr-4"
                  cols="2"
                >
                  {{ $t('client.UBR_List') }}
                </v-col>

                <v-col cols="8">
                  <validation-provider
                    v-slot="{ errors }"
                    name="UBR"
                    :rules="{ min:longueurCodeFacturation, max: longueurCodeFacturation }"
                  >
                    <v-combobox
                      v-model="submitForm.admin_code_facturation_liste"
                      color="secondary"
                      multiple
                    >
                      <template #selection="{ attrs, item, select, selected }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          color="secondary"
                          close
                          small
                          @click="select"
                          @click:close="removeUBR(item)"
                        >
                          {{ item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                    <span class="error--text">{{ errors[0] }}</span>
                  </validation-provider>
                </v-col>
              </v-row>
            </template> -->

            <v-subheader class="text-h4 mt-3">
              {{ $t('Other') }}
            </v-subheader>

            <!--  Commander pour une autre personne (On affiche si parèmetre 0)-->
            <v-row
              v-if="parameterCommanderPour === '0'"
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('client.Order_another_person') }}
              </v-col>

              <v-col cols="8">
                <v-checkbox
                  v-model="submitForm.admin"
                />
              </v-col>
            </v-row>

            <!--  BlackList-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                Blacklist
              </v-col>

              <v-col cols="8">
                <v-checkbox
                  v-model="submitForm.blacklist"
                />
              </v-col>
            </v-row>

            <!--  Date Ex employé-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('client.Date_ex_employee') }}
              </v-col>

              <v-col cols="8">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedDatefns"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="submitForm.date_ex_employe = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="submitForm.date_ex_employe"
                    :locale="language"
                    :min="nowDate"
                    @change="menu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <!--  Si Date Ex employé n'est pas null, on afffiche le temps qu'il reste avant que l'utilisateur choissise un autre fournisseur-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              />

              <v-col
                v-if="submitForm.date_ex_employe"
                cols="8"
              >
                <label class="red--text text--darken-2">{{ getDaysExEmploye }}</label>
              </v-col>
            </v-row>

            <!--  Mot (Champs requis)-->
            <v-col
              cols="2"
            >
              <div class="text-body-2 font-weight-light">
                *{{ $t('Required_Fields') }}
              </div>
            </v-col>

            <div class="pa-3 text-center">
              <!--  Bouton annuler-->
              <v-btn
                color="error"
                @click="cancel"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <!--  Bouton submit-->
              <v-btn
                color="success"
                class="ml-3"
                @click="handleSubmit(submit)"
              >
                {{ $t('Validate') }}
              </v-btn>
            </div>
          </base-material-card>
        </form>
      </validation-observer>
    </v-col>
  </v-row>
</template>
<script>
  // Service
  import ClientsService from '@/services/01Cell/ClientsService'
  // Mixins
  import { ClientEdit } from '@/mixins/Client/ClientEdit'
  // Other
  import { mapGetters } from 'vuex'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { format, parseISO, differenceInCalendarDays } from 'date-fns'
  import { fr, enUS } from 'date-fns/esm/locale'
  window.dateFnsLocales = {
    fr,
    en: enUS,
  }
  export default {
    name: 'ClientCreateEdit',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [ClientEdit],
    props: {
      createEditInfo: Object,
      clientId: Number,
    },
    data () {
      return {
        language: localStorage.getItem('APP_LANG_KEY') || 'fr',
        name: '',
        submitForm: {},
        resetsubmitForm: {},
        longueurCodeFacturation: 6,
        menu: false,
        nowDate: new Date().toISOString().slice(0, 10),
      }
    },

    computed: {
      ...mapGetters('parameters', {
        jourMaxExEmployee: 'getJourMaxExEmployee',
        parameterCommanderPour: 'getCommanderPour',
      }),
      computedDateFormattedDatefns () {
        return this.submitForm.date_ex_employe ? format(parseISO(this.submitForm.date_ex_employe), 'EEEE, d MMMM yyyy', { locale: window.dateFnsLocales[this.language] }) : ''
      },
      getDaysExEmploye () {
        const dateExEmployee = this.submitForm.date_ex_employe ? parseISO(this.submitForm.date_ex_employe) : ''
        let day = this.$i18n.t('time.day')
        let remaining = this.$i18n.t('Remaining').toLowerCase()
        const dateRemaining = Math.abs(differenceInCalendarDays(new Date(), dateExEmployee) - this.jourMaxExEmployee)
        if (dateRemaining > 1) {
          day = `${this.$i18n.t('time.day')}s`
          remaining = `${this.$i18n.t('Remaining').toLowerCase()}s`
        }
        return `${dateRemaining} ${day} ${remaining}`
      },
    },

    async mounted () {
      // Stop Spinner
      this.$loading.show()
      try {
        // Mettre les provinces en ordre
        this.provinces.sort()
        // Récupération du client
        this.submitForm = await ClientsService.view(this.clientId)
        // Stop Spinner
        this.$loading.hide()
      } catch (error) {
        console.log(error)
        // Stop Spinner
        this.$loading.hide()
      }
    },

    methods: {
      submit () {
        // On enlève des champs qui pourrais être trop gros pour envoyer vers le BackEnd
        delete this.submitForm.Commandes
        delete this.submitForm.Contrats
        delete this.submitForm.Inscription_paiements

        this.$emit('create-update-client', this.submitForm)
      },
      cancel () {
        // On ferme le child row
        this.$emit('cancel-edit-client', this.submitForm.id)
      },
      removeUBR (item) {
        this.submitForm.admin_code_facturation_liste.splice(this.submitForm.admin_code_facturation_liste.indexOf(item), 1)
        this.submitForm.admin_code_facturation_liste = [...this.submitForm.admin_code_facturation_liste]
      },
    },
  }
</script>
