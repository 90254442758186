<template>
  <div>
    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template #activator="{ on }">
        <span
          class="pointer"
          v-on="on"
        >{{ userInfo.info.firstName }} {{ userInfo.info.name }}</span>
        <!-- Avec Image-->
        <v-btn
          v-if="getImageProfil"
          icon
          x-large
          v-on="on"
        >
          <v-avatar size="40">
            <img
              :src="getImageProfil"
            >
          </v-avatar>
        </v-btn>

        <!-- Sans Image-->
        <v-btn
          v-else
          icon
          x-large
          v-on="on"
        >
          <v-avatar
            :color="getProfileColorButton"
            size="40"
          >
            <span class="white--text text-h5">{{ getProfileNickName }}</span>
          </v-avatar>
        </v-btn>

        <!-- <v-btn
          v-else
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn> -->
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <v-list-item three-line>
          <!-- Avec Image-->
          <v-avatar
            v-if="getImageProfil"
            size="60"
          >
            <img
              :src="getImageProfil"
            >
          </v-avatar>

          <!-- Sans Image-->
          <v-avatar
            v-else
            :color="getProfileColorButton"
            size="60"
          >
            <span class="white--text text-h5">{{ getProfileNickName }}</span>
          </v-avatar>
          <v-list-item-content class="ml-2">
            <v-list-item-title class="text-h5 mb-1">
              {{ userInfo.info.displayName }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ userInfo.info.mail }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list>
          <v-list-item
            @click="accountButton('profile')"
          >
            <v-list-item-title
              class="pointer"
            >
              {{ $t('my-profile') }}
            </v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-title
              class="pointer"
              @click="accountButton('logout')"
            >
              {{ $t('Logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list>
    </v-menu>

    <!-- <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template #activator="{ attrs, on }">
        <v-btn
          min-width="0"
          text
          v-bind="attrs"
          :title="$t('Logout')"
          v-on="on"
          @click="logoutRedirect"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
    </v-menu> -->
  </div>
</template>

<script>
  // Utilities
  import { mapMutations, mapGetters, mapState } from 'vuex'
  import Vue from 'vue'
  // Mixins
  // import { useIsAuthenticated } from '@/msal/useIsAuthenticated'

  import { useMsal } from '@/msal/useMsal'
  import { loginRequest, msalInstance } from '@/config/msal'
  import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser'
  import { callMsGraph, callMsGraphPhoto } from '@/utils/MsGraphApiCall'

  // import { useMsalAuthentication } from '@/msal/useMsalAuthentication'
  const { instance, inProgress } = useMsal()

  // const { result, acquireToken } = useMsalAuthentication(InteractionType.Redirect, loginRequest)

  const state = Vue.observable({
    resolved: false,
    data: {},
  })
  export default {
    name: 'LogoutMsal',
    // mixins: [useIsAuthenticated],
    data () {
      return {
        isAuthenticated: false,
        timeout: null,
      }
    },

    computed: {
      ...mapGetters(['getImageProfil']),
      ...mapState(['userInfo']),
      getProfileNickName () {
        if (this.userInfo.info.firstName) {
          const firstName = this.userInfo.info.firstName.slice(0, 1).toUpperCase()
          const name = this.userInfo.info.name.slice(0, 1).toUpperCase()
          return `${firstName}${name}`
        }
        return ''
      },
      getProfileColorButton () {
        const re = new RegExp('([a-dA-D])|([e-gE-G])|([h-kH-K])|([l-nL-N])|([o-qO-Q])|([r-sR-S])|([t-vT-V])|([w-zW-Z])')
        if (this.userInfo.info.name) {
          const found = this.userInfo.info.name.slice(0, 1).match(re)
          if (found[1]) {
            return 'green darken-3'
          } else if (found[2]) {
            return 'yellow darken-3'
          } else if (found[3]) {
            return 'brown darken-2'
          } else if (found[4]) {
            return 'blue-grey darken-2'
          } else if (found[5]) {
            return 'teal darken-2'
          } else if (found[6]) {
            return 'black'
          } else if (found[7]) {
            return 'red darken-2'
          } else if (found[8]) {
            return 'blue darken-2'
          }
        }
        return 'green darken-3'
        // Get first letter of initial
        // const str = this.initial.substring(0, 1);
        // const re = new RegExp('([a-dA-D])|([e-gE-G])|([h-kH-K])|([l-nL-N])|([o-qO-Q])|([r-sR-S])|([t-vT-V])|([w-zW-Z])');
        // let button;
        // const found = str.match(re);
        // if (found[1]) {
        //     button = `${align} btn btn-dark btn-circle ${size} ${isDisabled}`;
        // } else if (found[2]) {
        //     button = `${align} btn btn-success btn-circle ${size} ${isDisabled}`;
        // } else if (found[3]) {
        //     button = `${align} btn btn-info btn-circle ${size} ${isDisabled}`;
        // } else if (found[4]) {
        //     button = `${align} btn btn-warning btn-circle ${size} ${isDisabled}`;
        // } else if (found[5]) {
        //     button = `${align} btn btn-inverse btn-circle ${size} ${isDisabled}`;
        // } else if (found[6]) {
        //     button = `${align} btn btn-green btn-circle ${size} ${isDisabled}`;
        // } else if (found[7]) {
        //     button = `${align} btn btn-purple btn-circle ${size} ${isDisabled}`;
        // } else if (found[8]) {
        //     button = `${align} btn btn-pink btn-circle ${size} ${isDisabled}`;
        // }
        // return button;
        // },
      },
    },

    watch: {
      '$msal.accounts': {
        handler (value) {
          console.log('le watch')
          this.isAuthenticated = value.length > 0
        },
      },
      result: function () {
        this.getGraphData()
      },
    },

    mounted () {
      this.isAuthenticated = this.$msal.accounts.length > 0
      // Photo
      callMsGraphPhoto()
      // this.getGraphData()
      // console.log(this.$msal.accounts[0].idTokenClaims.exp)

      // Logout silently exemple
      // setTimeout(async () => {
      //   const accounts = msalInstance.getAllAccounts()
      //   const logoutHint = accounts[0].idTokenClaims.login_hint
      //   await instance.logoutRedirect({ logoutHint: logoutHint })
      // }, 10000)

      // On commence à mettre un temps pour la déconnection automatique
      this.startTime()
    },

    methods: {
      ...mapMutations([
        'resetUser',
      ]),
      accountButton (event) {
        if (event === 'profile') {
          if (this.$route.name !== 'profile') this.$router.push({ name: 'profile' })
        }
        if (event === 'logout') this.logoutRedirect()
      },
      startTime () {
        console.log('Startime')
        // Après 15 minutes, on commence à écouter pour un mousemove.
        setTimeout(() => {
          // Si après 10 minutes l'utilsateur n'a pas fait de 'mousemove' on déconnecte
          this.startCountDeconnect()
          // Si il y a un mousemove, on recommence le 15 minutes
          document.addEventListener('mousemove', this.authenticateSilent)
        }, 2 * 60 * 7500)
      },
      authenticateSilent () {
        // L'utilisateur à bougé la souris, on ne déconnecte pas
        document.removeEventListener('mousemove', this.authenticateSilent)
        clearTimeout(this.timeout)
        this.startTime()
      },
      startCountDeconnect () {
        console.log('StartCountDisconnect')
        // On attends 10 minutes avant de déconnecté
        this.timeout = setTimeout(async () => {
          const accounts = msalInstance.getAllAccounts()
          const logoutHint = accounts[0].idTokenClaims.login_hint
          await instance.logoutRedirect({ logoutHint: logoutHint })
        }, 2 * 60 * 5000)
      },
      logout () {
        this.resetUser()
        this.$router.push('/login')
      },
      loginPopup () {
        instance.loginPopup(loginRequest)
      },
      loginRedirect () {
        instance.loginRedirect(loginRequest)
      },
      logoutPopup () {
        instance.logoutPopup()
      },
      async logoutRedirect () {
        // Start Spinner
        this.$loading.show()
        // instance.logoutRedirect()
        const accounts = msalInstance.getAllAccounts()
        await instance.logoutRedirect({
          account: accounts[0],
          postLogoutRedirectUri: '/login',
        })
      },
      // Fonction appeler du composant 'src/views/dashboard/components/core/AppBar.vue'
      logoutButton () {
        // Start Spinner
        this.$loading.show()
        this.logoutRedirect()
      },
      // async getGraphData () {
      //   console.log(result)
      //   if (result) {
      //     const graphData = await callMsGraph(result.accessToken).catch(() => acquireToken())
      //     console.log(graphData)
      //     state.data = graphData
      //     state.resolved = true
      //   }
      // },
      async getGraphData () {
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
        }).catch(async (e) => {
          if (e instanceof InteractionRequiredAuthError) {
            await instance.Redirect(loginRequest)
          }
          throw e
        })
        if (inProgress === InteractionStatus.None) {
          const graphData = await callMsGraph(response.accessToken)
          // console.log(this.$msal.accounts[0])
          // console.log(graphData)
          state.data = graphData
          state.resolved = true
          // Photo
          callMsGraphPhoto()
        }
      },
    },
  }
</script>

<style scoped>
  .pointer {
    cursor: pointer;
  }
</style>
