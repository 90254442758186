const loading = {
  strict: true,
  namespaced: true,
  state: {
    show: false,
  },
  getters: {
    getLoading (state) {
      return state.show
    },
  },
  mutations: {
    show (state) {
      state.show = true
    },
    hide (state) {
      state.show = false
    },
  },
  actions: {

  },
}

export default loading
