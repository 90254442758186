import api from '@/services/Api'

export default {
    getApiPublic (session) {
        return api().get('/stripe/card/apiSecret')
    },
    createSession (session) {
        return api().post('/stripe/card/createSession', session)
    },
    retriveSessionNewCard (sessionId) {
        return api().get(`/stripe/card/retriveSessionNewCard/${sessionId}`)
    },
    retriveSessionPaiement (sessionId) {
        return api().get(`/stripe/card/retriveSessionPaiement/${sessionId}`)
    },
    remove (cardId, clientId) { // Suppression d'une entrée spécifique
        return api().delete(`/stripe/card/${cardId}/${clientId}`)
    },
}
