<template>
  <div>
    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template #activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <template v-for="(p, i) in profile">
          <v-divider
            v-if="p.divider"
            :key="`divider-${i}`"
            class="mb-2 mt-2"
          />

          <app-bar-item
            v-else
            :key="`item-${i}`"
          >
            <v-list-item-title
              @click="accountButton(p.value)"
              v-text="p.title"
            />
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template #activator="{ attrs, on }">
        <v-btn
          min-width="0"
          text
          v-bind="attrs"
          :title="$t('Logout')"
          v-on="on"
          @click="signOutOidc"
        >
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
    </v-menu>
  </div>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'
  // Utilities
  import { mapActions } from 'vuex'

  export default {
    name: 'LogoutOidc',
    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },
    data () {
      return {
        profile: [],
      }
    },

    async mounted () {
      this.profile = [
        { title: this.$t('my-profile'), value: 'profile' },
        { divider: true },
        { title: this.$t('Logout'), value: 'logout' },
      ]
    },

    methods: {
      ...mapActions('oidcStore', ['authenticateOidc', 'authenticateOidcSilent', 'signOutOidc', 'getOidcUser']),
      // Fonction appeler du composant 'src/views/dashboard/components/core/AppBar.vue'
      logoutButton () {
        this.signOutOidc()
      },
      accountButton (event) {
        if (event === 'profile') this.$router.push({ name: 'profile' })
        if (event === 'logout') this.$refs.logout.logoutButton()
      },
    },
  }
</script>
