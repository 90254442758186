import api from '@/services/Api'

export default {
    sommaire () {
        return api().get('/facturation/sommaire')
    },
    integrite () {
        return api().get('/facturation/integrite')
    },
    creerfactures (periode) {
        return api().get(`/facturation/creerfactures/${periode}`)
    },
    facturationMensuelle () {
        return api().get('/facturation/facturationMensuelle')
    },
    payerFactureManuelleToken (id) {
        return api().get(`/facturation/payerFactureManuelleToken/${id}`)
    },
    payerFactureManuelleCard (card, factureId) {
        return api().post(`/facturation/payerFactureManuelleCard/${factureId}`, card)
    },
    creerlot (periode) {
        return api().get(`/facturation/creerlot/${periode}`)
    },
    updateFacture (facture, factureId) { // Mise à jour d'une entrée spécifique
        return api().put(`/facturation/updateFacture/${factureId}`, facture)
    },
    getFactureHtml (factureId) {
        return api().get(`/facturation/getFactureHtml/${factureId}`)
    },
    mauvaisPayeur () {
        return api().get('/facturation/mauvaisPayeur')
    },
    sendEmailmauvaisPayeur () {
        return api().get('/facturation/sendEmailmauvaisPayeur')
    },
    updateLotErrorUbr (oldUbr, newUbr) {
        return api().put(`/facturation/updateLotErrorUbr/${oldUbr}/${newUbr}`)
    },
    getErreurUbr () {
        return api().get('/facturation/getErreurUbr')
    },
    listTransactions (id) {
        return api().get(`/facturation/listTransactions/${id}`)
    },
}
