import api from '@/services/Api'

export default {
    // Generate a Pre-Signed URL to Download a Private File
    getSignedUrl (params) {
        return api().post('/aws-s3/getSignedUrl', params)
    },
    // Delete Object
    deleteObject (bucket, key) {
        return api().delete(`/aws-s3/deleteObject/${bucket}?key=${key}`)
    },
}
