const snackbar = {
  strict: true,
  namespaced: true,
  state: {
    info: {
      content: '',
      color: '',
      icon: '',
      timeout: null,
      show: false,
      position: {
        top: false,
        center: false,
        left: false,
        right: false,
      },
    },
  },
  getters: {
    getSnackBarInfo (state) {
      return state.info
    },
  },
  mutations: {
    showMessage (state, payload) {
      state.info = payload
      state.info.show = true
    },
    hideMessage (state) {
      state.info.show = false
    },
  },
  actions: {

  },
}

export default snackbar
