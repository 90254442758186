<template>
  <v-overlay
    :value="getLoading"
    z-index="1000"
  >
    <v-progress-circular
      indeterminate
      size="64"
    />
  </v-overlay>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data () {
      return {
      }
    },

    computed: {
      ...mapGetters('loading', [
        'getLoading',
      ]),
    },
  }
</script>
