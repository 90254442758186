import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/messages')
    },
    save (message) { // Création d'une nouvelle entrée
        return api().post('/messages', message)
    },
    view (messageId) { // Voir une entrée spécifique
        return api().get(`/messages/${messageId}`)
    },
    update (message, messageId) { // Mise à jour d'une entrée spécifique
        return api().put(`/messages/${messageId}`, message)
    },
    remove (messageId) { // Suppression d'une entrée spécifique
        return api().delete(`/messages/${messageId}`)
    },
    search (item) { // Recherche
        return api().get(`/messages/search/${item}`)
    },
    multiple (messages) { // Liste plusieurs messages
        return api().post('/messages/multiple', messages)
    },
    replace (noMessages, langue) { // Remplacement de message
        return api().get(`/messages/replace/${noMessages}/${langue}`)
    },
}
