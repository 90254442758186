import api from '@/services/Api'

export default {
    list (startingAfter) {
        return api().get(`/stripe/customers/list/${startingAfter}`)
    },
    save (customer) {
        return api().post('stripe/customers', customer)
    },
    view (customerId) {
        return api().get(`/stripe/customers/${customerId}`)
    },
    update (customerId, customer) {
        return api().put(`/stripe/customers/${customerId}`, customer)
    },
    card (customerId) {
        return api().get(`/stripe/customers/cards/${customerId}`)
    },
    search (clientId) {
        return api().get(`/stripe/customers/search/${clientId}`)
    },
    searchByCustomer (data, nextPage) {
        return api().get(`/stripe/customers/searchByCustomer/${encodeURIComponent(data)}/${nextPage}`)
    },
}
