import api from '@/services/Api'

export default {
    searchByFactureId (factureId) {
        return api().get(`/stripe/payments/searchByFactureId/${factureId}`)
    },
    queryByFactureId (factureId) {
        return api().get(`/stripe/payments/queryByFactureId/${factureId}`)
    },
    view (paimentIntentId) {
        return api().get(`/stripe/payments/${paimentIntentId}`)
    },
}
