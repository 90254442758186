import api from '@/services/Api'

export default {
    commande () { // Liste tout
        return api().get('/commandes')
    },
    save (commande) { // Création d'une nouvelle entrée
        return api().post('/commandes', commande)
    },
    view (commandeId) { // Voir une entrée spécifique
        return api().get(`/commandes/${commandeId}`)
    },
    update (commande, commandeId) { // Mise à jour d'une entrée spécifique
        return api().put(`/commandes/${commandeId}`, commande)
    },
    remove (commandeId) { // Suppression d'une entrée spécifique
        return api().delete(`/commandes/${commandeId}`)
    },
    search (item) { // Recherche d'entrée
        return api().get(`/commandes/search/${item}`)
    },
    responsableCodeFacturation (item) { // Recherche le responsable du code de facturation (UBR)
        return api().get(`/commandes/responsable_code_facturation/${item}`)
    },
    codeFacturationValidation (codeFacturation, compte) { // Validation du ubr et du compte
        return api().get(`/commandes/validation_code_facturation/${codeFacturation}/${compte}`)
    },
    confirmOrder (id, commande) { // Recherche le responsable du code de facturation (UBR)
        return api().put(`/commandes/confirmOrder/${id}`, commande)
    },
    openOrder () { // Recherche les commandes ouverte
        return api().get('/commandes/openOrder')
    },
    confirmationRequest (id, commande) { // On mets le statut 2 pour que le client confirme son achat
        return api().put(`/commandes/confirmationRequest/${id}`, commande)
    },
    migrateLine (id, commande) { // Migration de la ligne
        return api().put(`/commandes/migrateLine/${id}`, commande)
    },
    ubrResponsableUqam () { // Recherche des commandes associé au responsable du UBR
        return api().get('/commandes/ubrResponsableUqam')
    },
    ubrResponsable () { // Recherche des commandes en approbation UBR
        return api().get('/commandes/ubrResponsable')
    },
    timeUsed (comptoirId, date) { // Recherche spécifique
        return api().get(`/commandes/timeUsed/${comptoirId}/${date}`)
    },
}
