<template>
  <div>
    <v-row
      dense
    >
      <v-col
        cols="12"
        class="px-0"
      >
        <div>
          <v-btn
            class="mt-5"
            color="blue"
            @click="sendEmail"
          >
            {{ $t('order.operation.Send_email') }}
            <v-icon
              right
              dark
            >
              mdi-email-arrow-right-outline
            </v-icon>
          </v-btn>
        </div>

        <div class="mt-5">
          <span class="text-h4">{{ $t('tools.French_message') }}</span>
          <v-card>
            <v-card-text>
              <div v-html="email.fr" />
            </v-card-text>
          </v-card>

          <span class="text-h4">{{ $t('tools.English_message') }}</span>
          <v-card>
            <v-card-text>
              <div v-html="email.en" />
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <!--  Loading-->
    <v-overlay
      absolute
      :value="overlay"
    >
      <v-progress-circular
        :width="5"
        color="purple"
        indeterminate
      />
    </v-overlay>
  </div>
</template>
<script>
  // Service
  import MailerService from '@/services/MailerService'
  import MessagesService from '@/services/01Cell/MessagesService'

  export default {
    name: 'ToolsSendEmail',
    data () {
      return {
        overlay: false,
        email: {
          fr: null,
          en: null,
        },
      }
    },

    computed: {
    },

    async mounted () {
      this.getData()
    },

    methods: {
      async getData () {
        try {
          this.overlay = true
          const messageFrPromise = MessagesService.replace(33, 'fr')
          const messageEnPromise = MessagesService.replace(33, 'en')
          const [messageFr, messageEn] = await Promise.all([messageFrPromise, messageEnPromise])
          this.email.fr = messageFr.body
          this.email.en = messageEn.body
          this.overlay = false
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.overlay = false
        }
      },
      submit () {
        if (!this.submitForm.body) {
          this.errorsMessage = this.$i18n.t('messages.Message_required')
          return
        }
        if (!this.submitForm.body.replace(/<[^>]*>?/gm, '')) {
          this.errorsMessage = this.$i18n.t('messages.Message_required')
          return
        }

        this.dialog = true
      },
      async sendEmail () {
        try {
          // Start Spinner
          this.$loading.show()
          await MailerService.sendEmailToCustomerWithMessageNo(33)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
