<template>
  <!-- Attention changement dans le mobile-breakpoint. Ancienne valeur 960-->
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="theme.barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="logoSrc"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template #img="props">
      <v-img
        :gradient="`to bottom, ${theme.barColor}`"
        v-bind="props"
      />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular text-h3">
          <span class="logo-normal">01Cell</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <base-item-group :item="profile" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      dense
      nav
    >
      <base-item
        :item="myPortal"
      />

      <base-item
        :item="report"
      />
    </v-list>

    <v-list-item
      v-if="computedItems.length"
      two-line
    >
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular text-h3">
          <span class="logo-normal">{{ $t('Management') }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import { mapState, mapGetters } from 'vuex'
  import { EventBus } from '@/utils/event-bus'
  const Menu = require(`@/menu/${process.env.VUE_APP_COMPANY}`).default

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      menu: Menu,
    }),

    computed: {
      ...mapState(['theme', 'userInfo']),
      ...mapGetters('parameters', {
        parameterFournisseurPaiement: 'getFournisseurPaiement',
      }),
      logoSrc () {
        if (this.theme.barImageToggle) { return `img/${this.theme.barImage}` }
        return ''
      },
      drawer: {
        get () {
          return this.$store.state.theme.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        const roles = this.userInfo.info.roles
        // On mets le rôle 'user' pour tous
        roles.push('user')

        const menu = this.menu.filter(item => {
          const access = roles.some(r => item.roles.indexOf(r) >= 0)
          // Si l'utilisateur a accès au rôle ou si le rôle est 'user'
          if (access) {
            if (item.children) {
              item.children = item.children.filter(item => {
                const found = roles.some(r => item.roles.indexOf(r) >= 0)
                if (found) {
                  return item
                }
              })
            }
            return item
          }
        })
        return menu.map(this.mapItem)
      },
      profile () {
        const profile = {
          avatar: false, // Image de la personne
          group: '',
          title: this.userInfo.info.displayName,
          children: [
            {
              to: 'profile',
              title: this.$t('my-profile'),
            },
            // {
            //   to: '',
            //   title: this.$t('edit-profile'),
            // },
            // {
            //   to: '',
            //   title: this.$t('settings.name'),
            // },
          ],
        }
        // Si Stripe on affiche 'Mes paiements'
        if (this.parameterFournisseurPaiement === 'stripe') {
          profile.children.push({
            to: 'payments-profile',
            title: this.$t('Payment.My_payments'),
          })
        }

        return profile
      },
      myPortal () {
        return {
          icon: 'mdi-account-box-outline',
          title: this.$t('sidebar.my_portal'),
          roles: ['user'],
          to: '/my_portal',
        }
      },
      report () {
        return {
          icon: 'mdi-sticker-text-outline',
          title: this.$t('sidebar.report'),
          roles: ['user'],
          to: '/rapport',
        }
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$store.commit('SET_DRAWER', !val)
        // this.$emit('update:expandOnHover', !val)
        this.$emit('update:expandOnHover', false)
      },
    },

    mounted () {
      if (!this.$vuetify.breakpoint.smAndDown) this.drawer = true

      // Viens du composant 'components/core/AppBar.vue'
      EventBus.$on('display-sidebar', () => {
        this.drawer = true
      })
    },

    destroyed () {
      // Viens du composant 'components/core/AppBar.vue'
      EventBus.$off('display-sidebar')
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(`sidebar.${item.title}`),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
