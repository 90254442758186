import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/contrats')
    },
    save (client) { // Création d'une nouvelle entrée
        return api().post('/contrats', client)
    },
    view (clientId) { // Voir une entrée spécifique
        return api().get(`/contrats/${clientId}`)
    },
    update (client, clientId) { // Mise à jour d'une entrée spécifique
        return api().put(`/contrats/${clientId}`, client)
    },
    remove (clientId) { // Suppression d'une entrée spécifique
        return api().delete(`/contrats/${clientId}`)
    },
    search (item) { // Recherche
        return api().get(`/contrats/search/${item}`)
    },
    validUnique (item) { // Recherche
        return api().get(`/contrats/validUnique/${item}`)
    },
}
