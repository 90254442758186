<template>
  <span class="VueTables__sort">
    <v-icon v-if="props.sortable">
      {{ icon }}
    </v-icon>
  </span>
</template>

<script>
  export default {
    name: 'VtSortControl',
    // eslint-disable-next-line vue/require-prop-types
    props: ['props'],
    computed: {
      icon () {
        // if not sorted return base icon
        if (!this.props.sortStatus.sorted) return 'mdi-sort ml-2'

        // return sort direction icon
        return this.props.sortStatus.asc ? 'mdi-sort-ascending ml-2' : 'mdi-sort-descending ml-2'
      },
    },
  }
</script>
