import { LogLevel, PublicClientApplication } from '@azure/msal-browser'
// https://docs.microsoft.com/fr-fr/azure/active-directory/develop/tutorial-v2-javascript-spa#register-your-application
// https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-client-application-configuration#authority
// https://devblogs.microsoft.com/azure-sdk/vue-js-user-authentication/

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENTID,
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_MSAL_TENANTID}`,
    redirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: '/login', // Must be registered as a SPA redirectURI on your app registration
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message)
                      // eslint-disable-next-line no-useless-return
                      return
                  // case LogLevel.Info:
                  //     console.info(message)
                  //     return
                  // case LogLevel.Verbose:
                  //     console.debug(message)
                  //     return
                  // case LogLevel.Warning:
                  //     console.warn(message)
                  //     // eslint-disable-next-line no-useless-return
                  //     return
                  default:
                      // eslint-disable-next-line no-useless-return
                      return
              }
          },
          logLevel: LogLevel.Verbose,
      },
  },
}

export const msalInstance = new PublicClientApplication(msalConfig)

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
