<template>
  <validation-observer
    ref="obs"
    v-slot="{ invalid, handleSubmit }"
  >
    <form>
      <v-row>
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors, valid }"
            :name="$t('order.operation.Order_number')"
            rules="required"
          >
            <!-- Numéro de la commande chez le fournisseur-->
            <v-text-field
              v-model="submitForm.fournisseur_no_commande"
              :label="$t('order.operation.Order_number')"
              :error-messages="errors"
              :success="valid"
              required
            />
          </validation-provider>
        </v-col>
      </v-row>
    </form>
    <!-- Submit-->
    <v-btn
      :disabled="invalid"
      class="mt-3"
      color="success"
      @click="handleSubmit(submit)"
    >
      {{ $t('Validate') }}
    </v-btn>
  </validation-observer>
</template>
<script>
  // Services
  import CommandesService from '@/services/01Cell/CommandesService'
  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import { EventBus } from '@/utils/event-bus'

  export default {
    name: 'ClientCommandeDemoUqamDialogMissingNoOrder',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        submitForm: {},
      }
    },

    computed: {
    },

    methods: {
      getData (data) {
        this.submitForm = data
      },
      async submit () {
        // Close Dialog
        this.$emit('close-dialog')

        // Start Spinner
        this.$loading.show()
        try {
          let statut
          let etape
          if (this.submitForm.type === 1) {
            statut = 3
            etape = 4
          } else if (this.submitForm.type === 2) {
            statut = 3
            etape = 5
            // statut = this.submitForm.livraison ? 3 : 3
            // etape = this.submitForm.livraison ? 8 : 5
          } else if (this.submitForm.type === 3) {
            statut = this.submitForm.livraison ? 3 : 3
            etape = this.submitForm.livraison ? 2 : 3
          }
          const commande = {
            fournisseur_no_commande: this.submitForm.fournisseur_no_commande,
            statut,
            etape,
          }

          const response = await CommandesService.update(commande, this.submitForm.id)
          // On update la liste dans le composant 'OperationCommande.vue'
          EventBus.$emit('update-commande', response)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
