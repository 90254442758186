import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/clients')
    },
    save (client) { // Création d'une nouvelle entrée
        return api().post('/clients', client)
    },
    view (clientId) { // Voir une entrée spécifique
        return api().get(`/clients/${clientId}`)
    },
    update (client, clientId) { // Mise à jour d'une entrée spécifique
        return api().put(`/clients/${clientId}`, client)
    },
    remove (clientId) { // Suppression d'une entrée spécifique
        return api().delete(`/clients/${clientId}`)
    },
    search (item, ifGetContrat) { // Recherche
        return api().get(`/clients/search/${item}?ifGetContrat=${ifGetContrat}`)
    },
    query (item, filter) { // Recherche spécifique
        return api().get(`/clients/query/${encodeURIComponent(item)}?champ=${filter}`)
    },
    queryTrue (filter) { // Recherche spécifique
        return api().get(`/clients/queryTrue?champ=${filter}`)
    },
    uqamRetraite () {
        return api().get('/clients/uqamRetraite')
    },
}
