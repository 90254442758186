// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import store from '@/store/index'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import filters from '@/utils/filters/index'
// Vut Tables 2
import { ClientTable } from 'vue-tables-2-premium'
import VtDataTable from '@/components/VueTables2/VtDataTable'
import VtTable from '@/components/VueTables2/VtTable'
import VtGenericFilter from '@/components/VueTables2/VtGenericFilter'
import VtSortControl from '@/components/VueTables2/VtSortControl'
import VtPagination from '@/components/VueTables2/VtPagination'
import VtPerPageSelector from '@/components/VueTables2/VtPerPageSelector'
import '@/styles/style.scss'
// Plugins
import plugins from '@/utils/plugins/index'
// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// i18n
import i18n from './i18n'
// MSAL
import { EventType } from '@azure/msal-browser'
import msalPlugin from './plugins/msalPlugin'
import { msalInstance } from './config/msal'

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

// Authentification est 'msal'
Vue.use(msalPlugin, msalInstance)

// Router
const router = require(`@/router/${process.env.VUE_APP_COMPANY}`).default

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
})

Vue.config.productionTip = false
// Nom de la compagnie ou on installe l'application (uqam, demo, ets etc...). Sert pour les composantes
Vue.prototype.$company = process.env.VUE_APP_COMPANY
// Nom du fournisseur (telus, bell)
Vue.prototype.$fournisseur = process.env.VUE_APP_FOURNISSEUR

// Filters
Vue.use(filters)

// Vue.use(ClientTable, {}, false, 'bootstrap4')
Vue.use(ClientTable, {}, false, 'bootstrap4', {
        table: VtTable,
        genericFilter: VtGenericFilter,
        dataTable: VtDataTable,
        sortControl: VtSortControl,
        pagination: VtPagination,
        perPageSelector: VtPerPageSelector,
    })

// Plugins
Vue.use(plugins)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
