<template>
  <div>
    <validation-provider
      v-slot="{ errors, valid }"
      :name="$t('Fund')"
      :rules="{ required: true, min: 6, max: 6 }"
    >
      <v-text-field
        v-model="submitForm.fund"
        :disabled="state === 'view'"
        class="mt-3"
        :label="$t('Fund')"
        :error-messages="errors"
        :success="valid"
        required
      />
    </validation-provider>

    <validation-provider
      v-slot="{ errors, valid }"
      name="Organisation"
      :rules="{ required: true, min: 5, max: 5 }"
    >
      <v-text-field
        v-model="submitForm.organisation"
        :disabled="state === 'view'"
        class="mt-3"
        label="Organisation"
        :error-messages="errors"
        :success="valid"
        required
      />
    </validation-provider>

    <validation-provider
      v-slot="{ errors, valid }"
      :name="$t('sidebar.payments_account')"
      :rules="{ required: true, min: 6, max: 6 }"
    >
      <v-text-field
        v-model="submitForm.account"
        :disabled="state === 'view'"
        class="mt-3"
        :label="$t('sidebar.payments_account')"
        :error-messages="errors"
        :success="valid"
        required
      />
    </validation-provider>

    <validation-provider
      v-slot="{ errors, valid }"
      :name="$t('Program')"
      :rules="{ required: true, min: 4, max: 4 }"
    >
      <v-text-field
        v-model="submitForm.program"
        :disabled="state === 'view'"
        class="mt-3"
        :label="$t('Program')"
        :error-messages="errors"
        :success="valid"
        required
      />
    </validation-provider>

    <validation-provider
      v-slot="{ errors, valid }"
      :name="$t('Activity')"
      :rules="{ required: true, min: 6, max: 6 }"
    >
      <v-text-field
        v-model="submitForm.activity"
        :disabled="state === 'view'"
        class="mt-3"
        :label="$t('Activity')"
        :error-messages="errors"
        :success="valid"
        required
      />
    </validation-provider>

    <validation-provider
      v-slot="{ errors, valid }"
      :name="$t('Location')"
      :rules="{ required: true, min: 6, max: 6 }"
    >
      <v-text-field
        v-model="submitForm.location"
        :disabled="state === 'view'"
        class="mt-3"
        :label="$t('Location')"
        :error-messages="errors"
        :success="valid"
        required
      />
    </validation-provider>
  </div>
</template>
<script>

  import {
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'McGillFoapal',
    components: {
      ValidationProvider,
    },
    props: {
      state: String,
      codeFacturation: String,
    },
    data () {
      return {
        submitForm: {
          fund: null,
          organisation: null,
          account: null,
          program: null,
          activity: null,
          location: null,
        },
      }
    },

    computed: {
    },

    watch: {
      submitForm: {
        // This will let Vue know to look inside the array
        deep: true,
        async handler () {
          const newCodeFacturation = `${this.submitForm.fund}${this.submitForm.organisation} ${this.submitForm.account}${this.submitForm.program} ${this.submitForm.activity}${this.submitForm.location}`
          this.$emit('change-foapal', newCodeFacturation)
        },
      },
      // 'codeFacturation' (value) {
      //   console.log(value)
      //   if (value) {
      //     this.submitForm.fund = value.slice(0, 6)
      //     this.submitForm.organisation = value.slice(6, 11)
      //     this.submitForm.account = value.slice(12, 18)
      //     this.submitForm.program = value.slice(18, 22)
      //     this.submitForm.activity = value.slice(23, 29)
      //     this.submitForm.location = value.slice(29)
      //   }
      // },
    },

    mounted () {
      // Ajouter un watcher manuel pour "codeFacturation"
      this.unwatchCodeFacturation = this.$watch(
        'codeFacturation',
        (value) => {
          if (!value.includes('null')) {
            this.submitForm.fund = value.slice(0, 6)
            this.submitForm.organisation = value.slice(6, 11)
            this.submitForm.account = value.slice(12, 18)
            this.submitForm.program = value.slice(18, 22)
            this.submitForm.activity = value.slice(23, 29)
            this.submitForm.location = value.slice(29)

            // Désactiver le watcher après avoir traité la donnée
            this.unwatchCodeFacturation()
            console.log('Watcher désactivé.')
          }
        },
      )
    },

    methods: {
    },
  }
  </script>
