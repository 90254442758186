import upperCaseFirstCharacter from './upperCaseFirstCharacter'
import date from './date'
import phoneNumberFormat from './phoneNumberFormat'

export default {
    install (Vue) {
        Vue.filter('upperCaseFirstCharacter', upperCaseFirstCharacter)
        Vue.filter('date', date)
        Vue.filter('phoneNumberFormat', phoneNumberFormat)
    },
}
