export const oidcSettingsCodeUQAM = {
  // authority: `${process.env.VUE_APP_OIDC_SERVER}/oauth2/auth`,
  authority: 'https://code.uqam.ca/simplesaml/module.php/oidc/authorize.php',
  client_id: `${process.env.VUE_APP_OIDC_CLIENT_ID}`,
  redirect_uri: `${window.location.origin}/oidc-callback/`,
  response_type: 'id_token token',
  scope: 'openid profile email',
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 600, // (10 mins) The number of seconds before an access token is to expire to raise the accessTokenExpiring event.
  silent_redirect_uri: `${window.location.origin}/silent-renew-oidc.html`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  loadUserInfo: true,
  filterProtocolClaims: true,
  metadata: {
      issuer: 'https://code.uqam.ca',
      jwks_uri: 'https://code.uqam.ca/simplesaml/module.php/oidc/jwks.php',
      end_session_endpoint: 'https://code.uqam.ca/simplesaml/module.php/oidc/logout.php',
      authorization_endpoint: 'https://code.uqam.ca/simplesaml/module.php/oidc/authorize.php',
      // revocation_endpoint: 'https://code.uqam.ca//oauth2/token/revocation',
      userinfo_endpoint: 'https://code.uqam.ca/simplesaml/module.php/oidc/userinfo.php',
  },
}

export const oidcSettingsNodeOIDC = {
  authority: 'https://gia-api.dev.uqam.ca/oauth2/auth',
  client_id: 'gestionreseau',
  redirect_uri: `${window.location.origin}/oidc-callback/`,
  response_type: 'id_token token',
  scope: 'openid profile email roles',
  // automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 600, // (10 mins) The number of seconds before an access token is to expire to raise the accessTokenExpiring event.
  silent_redirect_uri: `${window.location.origin}/silent-renew-oidc.html`,
  post_logout_redirect_uri: `${window.location.origin}/`,
  loadUserInfo: true,
  filterProtocolClaims: true,
  metadata: {
      issuer: 'https://gia-api.dev.uqam.ca/oauth2',
      jwks_uri: 'https://gia-api.dev.uqam.ca/oauth2/certs',
      end_session_endpoint: 'https://gia-api.dev.uqam.ca/oauth2/session/end',
      authorization_endpoint: 'https://gia-api.dev.uqam.ca/oauth2/auth',
      revocation_endpoint: 'https://gia-api.dev.uqam.ca/oauth2/token/revocation',
      userinfo_endpoint: 'https://gia-api.dev.uqam.ca/oauth2/userinfo',
  },
}
/*
export const withouCorsProblem = {
authority: 'https://gia-api.dev.uqam.ca/oauth2/auth',
client_id: 'gestionreseau',
redirect_uri: 'https://gestionreseau.localhost:8080/oidc-callback/',
response_type: 'id_token token',
scope: 'openid profile email roles',
automaticSilentRenew: true,
silent_redirect_uri: 'https://gestionreseau.localhost:8080/silent-renew-oidc.html',
post_logout_redirect_uri: 'https://gestionreseau.localhost:8080/',
loadUserInfo: false,
}
*/
