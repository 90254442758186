import api from '@/services/Api'

export default {
    header () { // En-tête
        return api().get('/dashboard/header')
    },
    graph () { // Graphique 1
        return api().get('/dashboard/graph')
    },
    graphBar () { // Graphique 1
        return api().get('/dashboard/graphBar')
    },
    graphLine () { // Graphique 1
        return api().get('/dashboard/graphLine')
    },
    detailsFactures (type) { // Détails
        return api().get(`/dashboard/detailsFactures/${type}`)
    },
    detailsContrats (type) { // Détails
        return api().get(`/dashboard/detailsContrats/${type}`)
    },
    mauvaisPayeur () { // Détails
        return api().get('/dashboard/mauvaisPayeur')
    },
    exEmployeC2 () { // Détails
        return api().get('/dashboard/exEmployeC2')
    },
    fraisAjustement () { // Détails
        return api().get('/dashboard/fraisAjustement')
    },
}
