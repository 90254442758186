import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/groupes')
    },
    save (groupe) { // Création d'une nouvelle entrée
        return api().post('/groupes', groupe)
    },
    view (groupeId) { // Voir une entrée spécifique
        return api().get(`/groupes/${groupeId}`)
    },
    update (groupe, groupeId) { // Mise à jour d'une entrée spécifique
        return api().put(`/groupes/${groupeId}`, groupe)
    },
    remove (groupeId) { // Suppression d'une entrée spécifique
        return api().delete(`/groupes/${groupeId}`)
    },
}
