  // Utilities
  import { mapState } from 'vuex'
  // Other
  import { format } from 'date-fns'
  import { fr, enUS } from 'date-fns/esm/locale'
  window.dateFnsLocales = {
    fr,
    en: enUS,
  }

export const Payment = {
    data () {
    },

    computed: {
      ...mapState(['userInfo']),
      regexMontant () {
        return /^-?\d+(\.\d{1,2})?$/
      },
      regexMontantPositif () {
        return /^\d+(\.\d{1,2})?$/
      },
    },

    methods: {
      checkPaymentReceive (payment) {
        if (payment) {
          // Pour afficher le reçu, il faut que la facture du client soit à lui ou si le rôle est 'admin'
          if (this.client.id === payment.Facture.ClientId || this.userInfo.info.roles.some(item => item === 'admin')) {
            const recu = this.translatePayment(payment.recu)
            // On montre le paiement au client
            this.$dialog.show({ message: recu, print: true, html: true, iconState: true, iconColor: 'success', iconName: 'mdi-check-circle' })
          }
        }
      },
      checkPaymentReceiveError (payment) {
        if (payment.paymentId === '-1') {
          this.$dialog.show({ message: payment.message, print: true, html: false, iconState: true, iconColor: 'error', iconName: 'mdi-alert-circle' })
        } else if (payment.paymentId === '-2') {
          this.$dialog.show({ message: this.$i18n.t('contract.bill.error.You_cannot_use_this_card_pay_your_bill'), print: true, html: false, iconState: true, iconColor: 'error', iconName: 'mdi-alert-circle' })
        }
      },
      translatePayment (recu) {
        // Si en français, on remplace des mots
        if (this.$i18n.t('Language') === 'fr') {
          recu = recu.replace(/TRANSACTION RECORD/, 'RÉSUMÉ DE TRANSACTION')
          recu = recu.replace(/Montreal/, 'Montréal')
          recu = recu.replace(/Purchase/, 'Achat')
          recu = recu.replace(/ACCT: /, '')
          recu = recu.replace(/CARD NUMBER/, 'NUMÉRO DE LA CARTE')
          recu = recu.replace(/DATE\/TIME/, 'DATE/HEURE')
          recu = recu.replace(/REFERENCE/, 'RÉFERENCE')
          recu = recu.replace(/AUTHOR./, 'AUTEUR.')
          recu = recu.replace(/TRANS. REF./, 'NUMÉRO DE RÉFÉRENCE')
          recu = recu.replace(/AUTHOR./, 'AUTEUR.')
          recu = recu.replace(/Approved - Thank You/, 'Approuvé - Merci')
          recu = recu.replace(/Please retain this copy for your records/, 'Veuillez conserver cette copie pour vos dossiers')
          recu = recu.replace(/Cardholder will pay above amount to card/, 'Le titulaire de la carte paiera le montant supérieur à la carte')
          recu = recu.replace(/issuer pursuant to cardholder agreement/, 'émetteur conformément à l\'accord du titulaire de la carte.')
        }
        return recu
      },
      getTransactionColor (statut) {
        if (statut === 'failed') return 'primary'
        if (statut === 'succeeded') return 'green'
        return 'grey'
      },
      getTransactionIcon (statut) {
        if (!statut) return 'mdi-close-box'
        // Failed
        if (statut === 'failed') return 'mdi-close-box'
        // Success
        if (statut === 'succeeded') return 'mdi-check'
        // Complètement remboursé
        if (statut === 'refunded') return 'mdi-arrow-u-left-top'
        // Rembousé partiel
        return 'mdi-information-box-outline'
      },
      getDateWithHour (timestamp) {
        const date = new Date(timestamp * 1000)
        const dateFormat = format(date, `EEEE, d MMMM yyyy ${this.$i18n.t('time.at')} H:mm`, { locale: window.dateFnsLocales[this.language] })
        return dateFormat.charAt(0).toUpperCase() + dateFormat.slice(1)
      },
      getDateWithoutHour (timestamp) {
        const date = new Date(timestamp * 1000)
        const dateFormat = format(date, 'EEEE, d MMMM yyyy', { locale: window.dateFnsLocales[this.language] })
        return dateFormat.charAt(0).toUpperCase() + dateFormat.slice(1)
      },
      disabledPaymentStatus (paiementStatut) {
        if (paiementStatut === 'succeeded' || paiementStatut === 'partialRefund') return false
        return true
      },
    },
}
